<template>
  <div
    class="back-to-top__wrapper"
    :class="{ show: show }"
    ref="button"
    @click="onClick"
    @mousedown.capture.prevent="startDrag"
    :style="{
      right: buttonPosition.right + 'px',
      bottom: buttonPosition.bottom + 'px',
    }"
  >
    <Badge :text="moveTopBadge" class-name="back-to-top__badge" :offset="[0, 60]">
      <button class="back-to-top__button">
        <svg-sprite :width="24" :height="24" :name="icon" />
      </button>
      <div class="remove-icon" @click.prevent.stop="onCloseButton">
        <Icon type="md-close" />
      </div>
    </Badge>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    draggable: { type: Boolean, default: false },
    icon: { type: String, default: "mt_move_up" },
    initialRight: { type: Number, default: 50 },
    initialBottom: { type: Number, default: 50 },
    parentRef: Object,
    moveTopBadge: String,
  },
  data() {
    return {
      isDragging: false,
      hasDragged: false,
      buttonPosition: { right: 50, bottom: 50 }, // Initial position
      dragOffset: { x: 0, y: 0 }, // Mouse offset relative to button
    };
  },
  mounted() {
    this.buttonPosition.right = this.initialRight;
    this.buttonPosition.bottom = this.initialBottom;
  },
  methods: {
    onCloseButton() {
      this.$emit("close");
    },
    onClick(event) {
      if (!this.hasDragged) {
        this.$emit("click", event);
      }
      this.hasDragged = false;
    },
    startDrag(event) {
      if (!this.draggable) return;
      this.isDragging = true;
      this.hasDragged = false;

      const buttonRect = this.$refs.button.getBoundingClientRect();
      const parentRect = this.parentRef?.$el?.getBoundingClientRect(); // Get parent container's position

      // Calculate drag offsets based on the cursor's position relative to the button's center
      this.dragOffset.x = event.clientX - (parentRect.right - buttonRect.right + buttonRect.width);
      this.dragOffset.y = event.clientY - (parentRect.bottom - buttonRect.bottom + buttonRect.height);

      document.addEventListener("mousemove", this.dragButton);
      document.addEventListener("mouseup", this.stopDrag);
    },
    dragButton(event) {
      if (!this.isDragging) return;
      this.hasDragged = true;

      const parentRect = this.parentRef?.$el?.getBoundingClientRect(); // Confine within parent
      const buttonRect = this.$refs.button.getBoundingClientRect(); // Get the current button position

      // Calculate new position using the mouse position relative to the parent
      let newRight = parentRect.right - event.clientX - this.initialRight / 2; // Calculate right position
      let newBottom = parentRect.bottom - event.clientY - this.initialBottom / 2; // Calculate bottom position

      // Ensure button does not exceed parent bounds
      if (newRight < 0) newRight = 0; // Prevent moving beyond the left edge
      if (newBottom < 0) newBottom = 0; // Prevent moving beyond the top edge

      // Ensure button does not exceed parent width/height
      if (newRight + buttonRect.width > parentRect.width) {
        newRight = parentRect.width - buttonRect.width; // Adjust to stay within right boundary
      }
      if (newBottom + buttonRect.height > parentRect.height) {
        newBottom = parentRect.height - buttonRect.height; // Adjust to stay within bottom boundary
      }

      // Update button's position
      this.buttonPosition.right = newRight;
      this.buttonPosition.bottom = newBottom;
    },
    stopDrag() {
      this.isDragging = false;

      // Clean up the event listeners to prevent memory leaks
      document.removeEventListener("mousemove", this.dragButton);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    resetPosition() {
      this.isDragging = false;
      this.hasDragged = false;
      this.buttonPosition = { right: 50, bottom: 50 };
      this.dragOffset = { x: 0, y: 0 };
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) return;
        this.resetPosition();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top__wrapper {
  background: #fff;
  margin: 0 !important;
  padding: 0 !important;
  color: transparent;
  clear: both;
  visibility: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0 !important;
  z-index: 99;
  background: transparent;
  user-select: none;
  .remove-icon {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    background-color: var(--alert-error);
    width: 14px;
    height: 14px;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    border-radius: 50%;
  }
  &:hover {
    .remove-icon {
      display: flex;
    }
  }
  &.show {
    visibility: visible;
    right: 50px;
    bottom: 50px;
    button.back-to-top__button {
      background: #fff;
      font-size: 25px;
      height: 50px;
      width: 50px;
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
      &::after,
      &::before {
        visibility: visible;
      }
      &:active {
        box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
  & button.back-to-top__button {
    height: 0px;
    width: 0px;
    overflow: hidden;
    position: relative;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    cursor: pointer;
    clear: both;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0 !important;
    border: none;
    font-size: 0px;
  }
  & button.back-to-top__button:hover,
  & button.back-to-top__button:active,
  & button.back-to-top__button:focus {
    outline: 0 !important;
  }
  &:deep(.back-to-top__badge) {
    background-color: var(--alert-info);
    font-family: "Roboto", sans-serif;
  }
}
</style>
