import { TOGGLE_EMBEDED, HAS_EMBEDED_FILE, SET_MAIL_DETAIL, SET_IS_PREVIEWING_ATTACHMENT } from "../../mutation-type";

const state = {
  showEmbeded: false,
  hasEmbeded: false,
  content: {},
  isPreviewingAttachment: false,
};
const getters = {
  mailDetailState: (state) => state,
};
const mutations = {
  [SET_MAIL_DETAIL]: (state, param) => {
    state.content = param;
  },
  [TOGGLE_EMBEDED]: (state) => {
    state.showEmbeded = !state.showEmbeded;
  },
  [HAS_EMBEDED_FILE]: (state, param) => {
    state.hasEmbeded = param;
  },
  [SET_IS_PREVIEWING_ATTACHMENT]: (state, param) => {
    state.content = {};
    state.isPreviewingAttachment = param;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
