import api from "@/fetch/api";

export const actionTypes = {
  FILE_EMAIL: "fileEmail",
};

const state = {
  action: undefined,
};

const getters = {};

const mutations = {
  addAction(state, data) {
    state.action = data;
  },
  removeAction(state, type) {
    if (state.action.type !== type) return;
    state.action = undefined;
  },
};

const actions = {
  addAction({ commit }, data) {
    commit("addAction", data);
  },
  undo({ state, commit }, type) {
    if (!state.action || state.action.type != type) return;
    switch (type) {
      case actionTypes.FILE_EMAIL:
        api.delete_email_from_folder(state.action.params);
        break;
    }
    commit("removeAction", type);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
