<template>
  <InappSendMail v-if="checkType(appConstant.inappType.COMPOSE)" :inAppState="inAppState" />
  <InappPreviewMail v-else :inAppState="inAppState" />
</template>

<script>
import InappSendMail from "./InappSendMail.vue";
import InappPreviewMail from "./InappPreviewMail.vue";
import appConstant from "@/common/constants/app.constant";

export default {
  data() {
    return {
      appConstant,
    };
  },
  props: ["inAppState"],
  components: {
    InappSendMail,
    InappPreviewMail,
  },
  methods: {
    checkType(type) {
      return this.inAppState.type === type;
    },
  },
  watch: {
    "$route.query"() {
      this.$store.commit("INAPP_WINDOW_MINIMIZE_ALL");
    },
  },
};
</script>
