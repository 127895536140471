<template>
  <Poptip
    v-model="assignmentPopupShown"
    :placement="popupPlacement"
    transfer
    padding="0"
    class="action-item handle-icon"
  >
    <Tooltip :content="tooltipContent" :disabled="!showTooltip" :transfer="true">
      <slot>
        <span v-if="showDescText && closed" class="desc-text" :class="{ clickable: !readOnly }"> Closed </span>
        <div
          v-if="assignments && assignments.length > 0"
          class="assign-button assigned"
          :class="[{ 'no-wrap': noWrap, 'read-only': readOnly }]"
        >
          <avatar
            v-for="(assignment, index) in assignments.slice(0, assignedDisplayLimit)"
            :key="index"
            :style="{ marginBottom: `${limitWidth ? 0 : 2}px` }"
            :fullname="getAssignmentDetail(assignment).name"
            :avatarURL="getAssignmentDetail(assignment).avatar"
            :width="avatarWidth"
            :show-tooltip="false"
            :disabled="closed || !getAssignmentDetail(assignment).active"
          />
          <avatar
            v-if="assignments.length > assignedDisplayLimit"
            :fullname="'...'"
            :width="avatarWidth"
            :character-limit="3"
            :show-tooltip="false"
            :disabled="closed"
          />
        </div>
        <svg-sprite v-else-if="!readOnly && !subjects[0].closed" name="mt_assignment" :width="20" :height="20" />
      </slot>
    </Tooltip>
    <div slot="content">
      <div
        v-if="assignmentPopupShown"
        class="assignment-wrapper keyactable"
        ref="assignmentRel"
        tabindex="0"
        @keydown.self.down.prevent="onKeyDown"
        @keydown.self.up.prevent="onKeyUp"
        @keydown.self.enter.prevent="onEnter"
      >
        <div
          class="members-container"
          ref="memberPopup"
          :style="{
            left: position.left + 'px',
            right: position.right + 'px',
            top: position.top + 'px',
            bottom: position.bottom + 'px',
          }"
        >
          <div class="header">
            <Input
              v-model="searchText"
              class="input-no-boder"
              ref="searchBox"
              placeholder="Search"
              :maxlength="254"
              @on-keydown="onSearchKeydown"
              @on-enter="onEnter"
            />
          </div>
          <ul class="list">
            <!--Team members-->
            <li
              class="item"
              :class="[{ hovering: currentIndex == index }, { assigned: isAssigned(user) }, { disabled: anyClosed }]"
              v-for="(user, index) in filteredUsers"
              :key="index"
              :ref="`item-${index}`"
              @click="onItemClick(user)"
            >
              <div class="user-info">
                <avatar class="avatar" :fullname="user.name" :avatarURL="user.avatar" :width="27" />
                <div class="name">{{ user.name }}{{ !user.active ? ` &lt;deactivated&gt;` : "" }}</div>
              </div>
              <Icon class="checkmark" type="md-checkmark" v-if="isAssigned(user)" />
            </li>
            <li v-if="!filteredUsers || filteredUsers.length == 0" class="not-found">No member found</li>
          </ul>
          <div v-if="!footerHide" class="footer">
            <span v-if="anyClosed" class="footer-item" @click="reopen()">Reopen</span>
            <span v-if="anyOpen" class="footer-item" @click="markAsClosed()">Mark as closed</span>
          </div>
        </div>
      </div>
    </div>
  </Poptip>
</template>

<script>
import avatar from "@/pages/components/avatar.vue";
import { mapGetters } from "vuex";
import api from "api";
import appConstant from "@/common/constants/app.constant";
import { popupPosition } from "@/mixins";

const TYPES = { EMAIL: "email", TASK: "task" };
export default {
  props: {
    value: {
      type: Array,
      required: false,
    },
    subjects: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: TYPES.EMAIL,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    popupPlacement: {
      type: String,
      required: false,
      default: "top",
    },
    members: Array,
    membersPlacement: {
      type: String,
      required: false,
      default: "bottom",
    },
    limitWidth: {
      type: Number,
      required: false,
    },
    clickOutside: {
      type: Boolean,
      required: false,
      default: true,
    },
    noWrap: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDescText: {
      type: Boolean,
      default: true,
    },
    avatarWidth: {
      type: Number,
      required: false,
      default: 30,
    },
    tooltipPrefix: {
      type: String,
      required: false,
      default: "",
    },
    footerHide: Boolean,
  },

  mixins: [popupPosition],

  data() {
    return {
      searchText: "",
      currentIndex: -1,
      assignments: [],
      position: {},
      assignmentPopupShown: false,
    };
  },

  computed: {
    ...mapGetters(["teamMembers", "deactivatedTeamMembers", "currentUser"]),
    filteredUsers() {
      let members = [...this.teamMembers];
      for (const member of this.deactivatedTeamMembers) {
        if (this.assignments.some((a) => a.assignable_id === member.id)) {
          if (!member.name) return;
          members.push(member);
        }
      }
      members = members.filter((m) => {
        const name = m.name || m.email || m.user_name;
        if (!name) return false;
        m.sortName = name.toLowerCase().trim();

        const nameLower = m?.name?.toLowerCase().trim() || "";
        const userNameLower = m.user_name?.toLowerCase().trim() || "";
        const emailLower = m.email?.toLowerCase().trim() || "";
        const searchText = this.searchText.toLowerCase().trim();
        return nameLower.includes(searchText) || userNameLower.includes(searchText) || emailLower.includes(searchText);
      });

      const loggedInUser = members.find((m) => m.id === this.currentUser.id);
      let selectedMembers = members.filter(
        (m) => this.assignments.some((a) => a.assignable_id === m.id) && m.id !== this.currentUser.id,
      );
      let unSelectedMembers = members.filter(
        (m) => !this.assignments.some((a) => a.assignable_id === m.id) && m.id !== this.currentUser.id,
      );
      unSelectedMembers = unSelectedMembers.sort((a, b) => (a.sortName < b.sortName ? -1 : 1));

      const filteredMembers = [...selectedMembers, ...unSelectedMembers];
      loggedInUser && filteredMembers.unshift(loggedInUser);
      return filteredMembers;
    },
    assignedDisplayLimit() {
      if (!this.limitWidth) return this.assignments.length;
      const marginBetweenAvatar = 2;
      let limitCount = parseInt(this.limitWidth / (this.avatarWidth + marginBetweenAvatar));
      return limitCount < this.assignments.length
        ? limitCount >= 1
          ? limitCount - 1
          : 0 //substract 1 for (...) display.
        : this.assignments.length;
    },
    tooltipContent() {
      if (!this.assignments || this.assignments.length == 0) return "Assign to";
      return `${this.tooltipPrefix} ${
        this.assignments && this.assignments.map((a) => this.getAssignmentDetail(a).name).join(", ")
      }`;
    },
    isBatch() {
      return this.subjects.length > 1;
    },
    closed() {
      return this.subjects.every((s) => s.closed);
    },
    anyClosed() {
      return this.subjects.findIndex((s) => !!s.closed) > -1;
    },
    anyOpen() {
      return this.subjects.findIndex((s) => !s.closed) > -1;
    },
    subjectIds() {
      const ids = this.subjects.map((s) => s.id);
      return this.isBatch ? ids : ids[0];
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.assignments = val || [];
      },
    },
    assignmentPopupShown(val) {
      if (!this.readOnly && val) {
        this.onAssign();
      }
    },
  },

  methods: {
    getAssignmentDetail(assignment) {
      if (!assignment) return {};
      const user = [...this.teamMembers, ...this.deactivatedTeamMembers].find((m) => m.id == assignment.assignable_id);
      return user ? { name: user.name, avatar: user.avatar, active: user.active } : {};
    },
    onAssign() {
      this.currentIndex = -1;
      this.searchText = "";
      this.$nextTick(() => {
        this.position = this.computePosition(this.$refs.assignmentRel, this.$refs.memberPopup, this.membersPlacement);
        this.$refs.searchBox && this.$refs.searchBox.focus();
      });
    },
    onItemClick(user) {
      if (this.anyClosed) return;
      !this.isAssigned(user) ? this.onSelected(user) : this.onRemoved(user);
    },
    async onSelected(user) {
      let self = this;
      const newAssignment = {
        id: -1,
        assignable_id: user.id,
        assignable_type: appConstant.assignableType.USER,
        user_ids: [],
      };
      try {
        this.assignments.push(newAssignment);
        this.isBatch ? this.assignDone(newAssignment) : this.assignDone();
        const apiRequest =
          this.type == TYPES.EMAIL ? (this.isBatch ? api.assign_to_batch : api.assign_to) : api.task_assign_to;
        const res = await apiRequest(this.subjectIds, appConstant.assignableType.USER, user.id);
        !this.isBatch && res && (newAssignment.id = res.assignment_id);
        this.isBatch ? this.assignDone(newAssignment, res && res.assignment_ids) : this.assignDone();
      } catch (e) {
        _removeAssignment(user);
        this.isBatch ? this.assignDone(newAssignment, undefined, true) : this.assignDone();
      }

      function _removeAssignment(user) {
        const index = self.assignments.findIndex((a) => a.assignable_id == user.id);
        index > -1 && self.assignments.splice(index, 1);
        return index;
      }
    },

    async onRemoved(user) {
      let self = this;
      let removeId;
      try {
        const index = this.assignments.findIndex((a) => a.assignable_id == user.id);
        removeId = this.assignments[index].id;
        if (index > -1) {
          this.assignments.splice(index, 1);
          this.assignDone();
          const apiRequest = this.type == TYPES.EMAIL ? api.unassign : api.task_unassign;
          await apiRequest(removeId);
        }
      } catch (e) {
        _addAssignment(user, removeId);
        this.assignDone();
      }

      function _addAssignment(user, assignmentId) {
        const index = self.assignments.findIndex((a) => a.assignable_id == user.id);
        const assignment = {
          id: assignmentId,
          assignable_id: user.id,
          assignable_type: appConstant.assignableType.USER,
          user_ids: [],
        };
        index == -1 && self.assignments.push(assignment);
      }
    },

    onSearchKeydown(e) {
      (e.key == "ArrowUp" || e.keyCode == 38) && this.onKeyUp();
      (e.key == "ArrowDown" || e.keyCode == 40) && this.onKeyDown();
      (e.key == "Escape" || e.keyCode == 27) && (this.assignmentPopupShown = false);
    },

    onKeyDown() {
      this.currentIndex < this.filteredUsers.length - 1 && this.currentIndex++;
      this.scrollIntoItem(this.currentIndex);
    },

    onKeyUp() {
      this.currentIndex > 0 && this.currentIndex--;
      this.scrollIntoItem(this.currentIndex);
    },

    onEnter() {
      if (this.anyClosed) return;
      const user = this.filteredUsers[this.currentIndex];
      if (user) !this.isAssigned(user) ? this.onSelected(user) : this.onRemoved(user);
    },

    scrollIntoItem(index) {
      const el = this.$refs[`item-${index}`];
      el && el[0] && el[0].scrollIntoView({ behavior: "smooth", block: "center" });
    },

    isAssigned(user) {
      return this.assignments.findIndex((a) => a.assignable_id == user.id) > -1;
    },

    assignDone(assignment, ids, remove = false) {
      this.closePopup();
      this.$emit("done");
      if (!assignment || !assignment.assignable_id) return;

      this.type == TYPES.EMAIL && assignment && this.mailAssignDone(assignment, ids, remove);
    },

    mailAssignDone(assignment, ids, remove) {
      let emails = this.subjects;
      emails = emails.map((mail, i) => {
        const index = mail.assignment_ids.findIndex((a) => a.assignable_id == assignment.assignable_id);
        const isFailedAssign = index > -1 && remove && mail.assignment_ids[index].id == -1;
        const isRealId = index > -1 && ids && ids[i];
        isFailedAssign && mail.assignment_ids.splice(index, 1);
        isRealId && (mail.assignment_ids[i].id = ids[i]);
        index == -1 && mail.assignment_ids.push(assignment);
        return mail;
      });
    },

    closePopup() {
      this.assignmentPopupShown = false;
    },

    markAsClosed() {
      this.subjects.forEach((s) => (s.closed = true));
      this.assignDone();
      const apiRequest =
        this.type == TYPES.EMAIL
          ? this.subjects.length > 1
            ? api.markAsClosedBatch
            : api.markAsClosed
          : api.task_mark_as_closed;
      apiRequest(this.subjectIds).catch((_) => {
        this.subjects.forEach((s) => (s.closed = false));
      });
    },

    reopen() {
      this.subjects.forEach((s) => (s.closed = false));
      this.assignDone();
      const apiRequest =
        this.type == TYPES.EMAIL ? (this.subjects.length > 1 ? api.reopenBatch : api.reopen) : api.task_reopen;
      apiRequest(this.subjectIds).catch((_) => {
        this.subjects.forEach((s) => (s.closed = true));
      });
    },
  },

  components: {
    avatar,
  },
};
</script>

<style lang="scss" scoped>
.tooltip-assignment {
  display: flex;
  width: inherit;
  height: 100%;
}

.assign-button {
  border-radius: 20px;
  padding: 4px 12px;
  cursor: pointer;

  &.assigned {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;

    .avatar-container:not(:first-child) {
      margin-left: -10px;
      z-index: 1;
    }
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.read-only {
    cursor: default;
  }
}
.assignment-wrapper {
  display: flex;
  height: 100%;
  position: relative;
  font-size: 13px;
  font-weight: normal;

  > .header {
    display: flex;
    flex: 1;
    align-items: center;

    svg {
      fill: var(--text-color);
      cursor: pointer;
    }

    .desc-text {
      border: 1px solid var(--border-color);
      border-radius: 20px;
      padding: 2px 6px;
      margin-right: 4px;
      background: var(--component-color);
      color: var(--on-component-color);
      white-space: nowrap;

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .members-container {
    z-index: 1000;
    background: var(--component-color);
    color: var(--on-component-color);
    border-radius: 3px;
    min-width: 250px;
    border: 1px solid var(--border-color);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);

    &.hidden {
      visibility: hidden;
    }

    &.bottom-end {
      right: 0;
    }

    &.right {
      transform: translateX(100%);
    }

    &:focus {
      outline: none;
    }

    .header {
      text-align: center;
      font-size: 14px;
      padding: 2px;
      border-bottom: 1px solid var(--border-color);
      .title {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
    ul.list {
      max-height: 200px;
      overflow: auto;
      overflow: overlay;

      li.item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 16px;
        list-style: none;

        .user-info {
          display: flex;
          align-items: center;
          .avatar {
            margin-right: 8px;
          }
          .name {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .checkmark {
          font-weight: bold;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--hover-color);
        }

        &.hovering {
          background-color: var(--hover-color);
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
      .not-found {
        padding: 8px;
        text-align: center;
        color: var(--label-color);
      }
    }
    .footer {
      display: flex;
      align-items: center;
      color: var(--primary-color);
      font-weight: bold;
      text-align: center;
      border-top: 1px solid var(--border-color);
      .footer-item {
        flex: 1;
        padding: 10px;
        cursor: pointer;
        &:last-child {
          border-left: 1px solid var(--border-color);
        }
        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }
  .assignment-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
  }
}
</style>
