<template>
  <div :style="sizeStyle">
    <div
      v-if="resizeWidth"
      @mousedown="handleMouseDown($event)"
      class="resizable-width"
      :class="{ 'resize-nav': type === 'sidebarWidth' }"
    />
    <div v-if="resizeHeight" @mousedown="handleMouseDown($event)" class="resizable-height" />
    <slot />
  </div>
</template>
<script>
import util from "util";

export default {
  data() {
    return {
      startX: 0,
      resizableWidth: null,
      resizableHeight: null,
    };
  },
  props: {
    type: {
      type: String,
    },
    resizeHeight: {
      type: Boolean,
    },
    resizeWidth: {
      type: Boolean,
    },
    isExpanded: {
      type: Boolean,
    },
    defaultWidth: {
      type: Number,
    },
    defaultHeight: {
      type: Number,
      default: 300,
    },
  },
  mounted() {
    this.setSize();
  },
  computed: {
    sizeStyle() {
      if (this.resizeHeight) {
        return { height: this.resizableHeight };
      }
      if (this.resizeWidth) {
        return { width: this.resizableWidth, minWidth: this.type === "contactWidth" ? "700px" : undefined };
      }
      return null;
    },
    wrapperWidth() {
      const wrapper = document.querySelector(".main-content");
      if (wrapper) {
        return wrapper.offsetWidth;
      }
    },
  },
  methods: {
    setSize() {
      const customSize = localStorage.getItem(this.type);
      if (this.resizeWidth) {
        const size = `${this.defaultWidth}px` || `${this.wrapperWidth * 0.4}px`;
        this.resizableWidth = customSize || size;
      } else {
        this.resizableHeight = customSize || `${this.defaultHeight}px`;
      }
      this.emitSize();
    },
    handleMouseDown(ev) {
      ev.preventDefault();
      let preCoor = null;
      let timer = null;
      document.onmousemove = (ev) => {
        if (timer) {
          return;
        }
        timer = setTimeout(() => {
          timer = null;
        }, 10);
        if (!preCoor) {
          preCoor = this.resizeWidth ? ev.pageX : ev.pageY;
          return;
        }

        let result;
        if (this.resizeWidth) {
          if (this.type === "sidebarWidth") {
            const verticalMenu = document.querySelector(".vertial-menu");
            if (!verticalMenu) return;

            result = ev.pageX - verticalMenu.offsetWidth;
          } else {
            result = window.innerWidth - ev.pageX;
            const minWidth = this.defaultWidth || this.wrapperWidth * 0.4;
            if (result < minWidth) return;
          }
          preCoor = ev.pageX;
          this.resizableWidth = `${result}px`;
        } else {
          result = window.innerHeight - ev.pageY;
          preCoor = ev.pageY;
          this.resizableHeight = `${result}px`;
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
        if (this.resizeWidth) {
          localStorage.setItem(this.type, this.resizableWidth);
          this.type === "sidebarWidth" && util.functionBus.handleColumnSize && util.functionBus.handleColumnSize();
        } else localStorage.setItem(this.type, this.resizableHeight);
        this.emitSize();
      };
    },
    emitSize() {
      this.$emit("onResizeDone", this.resizeWidth ? this.resizableWidth : this.resizableHeight);
    },
  },
  watch: {
    type() {
      this.setSize();
    },
    defaultHeight() {
      this.setSize();
    },
    defaultWidth() {
      this.setSize();
    },
  },
};
</script>
<style lang="scss" scoped>
.resizable-width {
  position: absolute;
  height: 100%;
  bottom: 0;
  width: 7px;
  z-index: 101;
  cursor: col-resize;
}
.resizable-height {
  position: absolute;
  width: 100%;
  top: 0;
  height: 7px;
  z-index: 101;
  cursor: row-resize;
}
.resize-nav {
  right: 0;
}
</style>
