<template>
  <div class="folder-search-input-container" :class="type">
    <Select
      ref="folderSearch"
      element-id="folder-search"
      filterable
      clearable
      remote
      placeholder="Type folder name"
      not-found-text="No matching result"
      :loading="searching"
      :remote-method="emitSearchQuery"
      @on-change="onSelectedChange($event)"
    >
      <template v-for="(name, i) in rootFolderNames">
        <OptionGroup
          v-if="filteredFolders[name].length > 0"
          :label="rootFolderLabels[i]"
          :key="i"
          :class="{ 'hide-optgroup-label': type !== folderSearchTypes.ALL }"
        >
          <Option
            v-for="(option, j) in filteredFolders[name]"
            :title="option.fullpath"
            :value="option.id"
            :label="option.name"
            :key="j"
            :class="{ 'show-expand-action': showExpandAction }"
          >
            <span class="name">{{ option.name }}</span>
            <template>
              <span v-if="showExpandAction" class="right-container" @click.stop="onHighlightFolder(option.id)">
                <Icon type="ios-arrow-forward" />
              </span>
            </template>
          </Option>
        </OptionGroup>
      </template>
    </Select>
  </div>
</template>

<script>
import { debounceTime, switchMap, distinctUntilChanged, tap, share, filter } from "rxjs/operators";
import utils from "util";
import api from "api";
import appConstant from "@/common/constants/app.constant.js";

export default {
  domStreams: ["folderSearch$"],
  subscriptions() {
    return {
      searchObservable: this.setupFolderSearch(),
    };
  },
  props: {
    type: {
      type: String,
      required: false,
    },
    parentId: {
      type: [Number, String],
      required: false,
    },
    folderPersonal: {
      type: Boolean,
      default: true,
    },
    showExpandAction: {
      type: [Boolean, String],
      default: false,
    },
    minimumSearchCharacters: {
      type: Number,
      require: false,
      default: 2,
    },
  },

  data() {
    return {
      folderSearchTypes: appConstant.folderSearchTypes,
      searching: false,
      hasMoreItems: true,
      loadingMore: false,
      searchQuery: {},
      filteredFolders: { stars: [], personals: [], shares: [] },
      rootFolderNames: [],
      rootFolderLabels: [],
    };
  },

  created() {
    this.subscribeSearchObservable();
  },

  mounted() {
    this.$nextTick((_) => {
      const searchboxEl = document.querySelector(`.${this.type} #folder-search`);
      searchboxEl && searchboxEl.focus();
      utils.deleteCharater(searchboxEl, (newText) => this.$refs.folderSearch.setQuery(newText)); // support delete by DELETE keyboard

      const dropdownEl = document.querySelector(`.${this.type}.folder-search-input-container .ivu-select-dropdown`);
      utils.scrollToLoadMore(dropdownEl, {
        bottomDistance: 0,
        scrollToBottomCallback: (_) => {
          !this.loadingMore && this.hasMoreItems && this.loadMore();
        },
      });
    });
  },

  methods: {
    setupFolderSearch() {
      return this.folderSearch$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((_) => {
          this.searching = !this.loadingMore;
          !this.loadingMore && (this.filteredFolders = { stars: [], personals: [], shares: [] });
        }),
        switchMap(async (query) => {
          try {
            return await api.search_folders(
              query.searchText,
              query.parentId,
              query.page,
              query.pageSize,
              query.searchType,
            );
          } catch (e) {
            console.log(e);
            return null;
          }
        }),
        tap((_) => (this.searching = this.loadingMore = false)),
        filter((data) => !!data),
        share(),
      );
    },

    emitSearchQuery(searchText = "", page, pageSize) {
      if (searchText.trim().length < this.minimumSearchCharacters) return;
      this.searchQuery = {
        searchText: searchText.trim(),
        parentId: !searchText ? this.parentId : undefined,
        searchType: this.type == this.folderSearchTypes.ALL ? undefined : this.type,
        page: page ? page : 1,
        pageSize: pageSize ? pageSize : 20,
      };
      this.folderSearch$.next(this.searchQuery);
    },

    subscribeSearchObservable() {
      this.$observables.searchObservable.subscribe((res) => {
        const data = { ...res };
        this.hasMoreItems = false;
        let hasResult = false;

        this.rootFolderNames.forEach((name) => {
          this.filteredFolders[name] = this.filteredFolders[name].concat(this.buildFoldersFullpath(data[name]));
          data[name].length >= this.searchQuery.pageSize &&
            this.type !== this.folderSearchTypes.ALL &&
            (this.hasMoreItems = true);
          data[name].length > 0 && (hasResult = true);
        });
        this.toggleLoadMoreIndicator();
        this.$emit("onSearchDone", this.searchQuery.searchText, this.searchQuery.page, hasResult);
      });
    },

    loadMore() {
      this.loadingMore = true;
      this.emitSearchQuery(this.searchQuery.searchText, this.searchQuery.page + 1);
      this.toggleLoadMoreIndicator();
    },

    toggleLoadMoreIndicator() {
      const loadingEl = document.querySelector(`.${this.type}.folder-search-input-container .ivu-select-loading`);
      loadingEl && this.loadingMore && (loadingEl.style.display = "block");
      loadingEl && !this.loadingMore && (loadingEl.style.display = "none");
    },

    buildFoldersFullpath(folders) {
      if (!folders || folders.length == 0) return [];

      return folders.map((folder) => {
        if (!folder) return folder;
        if (!folder.parent_structure) return { ...folder, fullpath: folder.name };

        let fullpath = "";
        for (let i = 0; i < folder.parent_structure.length; i++) {
          fullpath += folder.parent_structure[i].name + " > ";
        }
        fullpath += folder.name;
        return { ...folder, fullpath: fullpath };
      });
    },

    onSelectedChange(folderId) {
      for (let index = 0; index < this.rootFolderNames.length; index++) {
        const name = this.rootFolderNames[index];
        const selectedFolder = this.filteredFolders[name].find((f) => folderId && f.id == folderId);
        if (selectedFolder) {
          this.$emit("onSearchSelected", selectedFolder);
          return;
        }
      }
    },

    onHighlightFolder(folderId) {
      for (let index = 0; index < this.rootFolderNames.length; index++) {
        const name = this.rootFolderNames[index];
        const selectedFolder = this.filteredFolders[name].find((f) => folderId && f.id == folderId);
        if (selectedFolder) {
          this.$refs.folderSearch.hideMenu();
          this.$emit("onHighlightFolder", selectedFolder);
          return;
        }
      }
    },

    focusOnTextbox() {
      const searchboxEl = document.querySelector(`.${this.type} #folder-search`);
      searchboxEl && searchboxEl.click();
    },

    clearTextSearch() {
      this.$refs.folderSearch && this.$refs.folderSearch.clearSingleSelect();
    },
  },
  watch: {
    folderPersonal: {
      immediate: true,
      handler(val) {
        if (val) {
          this.rootFolderNames = ["personals", "shares"];
          this.rootFolderLabels = ["MY WORKSPACE", "SHARED"];
        } else {
          this.rootFolderNames = ["shares"];
          this.rootFolderLabels = ["SHARED"];
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-search-input-container {
  position: relative;
  margin: 8px 0;
}
.right-container {
  float: right;
  padding: 8px;

  &:hover {
    background-color: var(--primary-color-hover);
    color: var(--on-primary-color);
  }

  i {
    font-size: 18px;

    &:hover {
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss">
.folder-search-input-container {
  .ivu-select-dropdown,
  ul {
    width: 100% !important;
    .name {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      display: block;
      font-weight: normal;
    }
  }
  .ivu-select-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.show-expand-action {
      padding: 0 16px;
    }
  }
  .ivu-select-not-found,
  .ivu-select-loading {
    padding: 6px;
    font-size: 12px;
  }
  .hide-optgroup-label {
    .ivu-select-group-title {
      display: none;
    }
  }
}
</style>
