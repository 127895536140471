<template>
  <Row>
    <Col class="row-name group-border">
      <svg-sprite :width="24" :height="16" viewBox="0 0 24 16" name="group_contact" />
      &nbsp;Group/s
    </Col>
    <Form-item
      v-if="selectedSharedGroups && selectedSharedGroups.length > 0"
      label="Shared Group/s"
      prop="sharedGroups"
    >
      <Row class="row">
        <Col class="row-data group-row">
          <Tag
            closable
            v-for="address in selectedSharedGroups"
            :key="address.id"
            @on-close="handleRemoveSharedGroup(address)"
            class="group-item"
          >
            {{ address.name }}
          </Tag>
        </Col>
      </Row>
    </Form-item>
    <Form-item v-if="selectedMyGroups && selectedMyGroups.length > 0" label="My Group/s" prop="myGroups">
      <Row class="row">
        <Col class="row-data group-row">
          <Tag
            closable
            v-for="address in selectedMyGroups"
            :key="address.id"
            @on-close="handleRemoveMyGroup(address)"
            class="group-item"
          >
            {{ address.name }}
          </Tag>
        </Col>
      </Row>
    </Form-item>
    <Form-item label="Add To Groups">
      <Select
        style="width: 200px; margin-right: 8px"
        placeholder="Select shared group"
        :clearable="true"
        ref="addToShared"
        @on-change="handleSelectSharedGroup"
      >
        <Option v-for="item in sharedGroups" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
      <Select
        style="width: 200px"
        placeholder="Select my group"
        :clearable="true"
        ref="addToMy"
        @on-change="handleSelectMyGroup"
      >
        <Option v-for="item in myGroups" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </Form-item>
    <Modal
      v-model="confirmShow"
      title="Remove"
      width="380"
      :styles="{ top: '238px' }"
      class="dialog"
      @on-ok="confirmCallback"
    >
      Are you sure you want to remove {{ confirmationInfo.contact_name }} from {{ confirmationInfo.group_type }}:
      {{ confirmationInfo.group_name }} ?
    </Modal>
  </Row>
</template>
<script>
import { mapState } from "vuex";
import api from "api";

export default {
  data() {
    return {
      sharedGroups: [],
      myGroups: [],
      selectedSharedGroups: [],
      selectedMyGroups: [],
      confirmationInfo: {},
      confirmShow: false,
      confirmCallback: () => {},
    };
  },
  props: {
    contactName: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["contactNav"]),
    contactId() {
      return ["editCompany", "editDepartment", "editContact"].some((n) => n === this.$route.name)
        ? this.$route.params.id
        : undefined;
    },
  },
  methods: {
    handleRemoveGroup(group) {
      this.confirmShow = true;
      this.confirmationInfo = {
        contact_name: this.contactName || "this contact",
        group_type: group.personal ? "My Groups" : "Shared Groups",
        group_name: group.name,
      };
    },
    handleRemoveSharedGroup(group) {
      this.handleRemoveGroup(group);
      this.confirmCallback = () => {
        this.sharedGroups = [group, ...this.sharedGroups];
        this.selectedSharedGroups = this.selectedSharedGroups.filter((g) => g.id !== group.id);
      };
    },
    handleRemoveMyGroup(group) {
      this.handleRemoveGroup(group);
      this.confirmCallback = () => {
        this.myGroups = [group, ...this.myGroups];
        this.selectedMyGroups = this.selectedMyGroups.filter((g) => g.id !== group.id);
      };
    },
    handleSelectSharedGroup(id) {
      this.$refs.addToShared.clearSingleSelect();
      if (!id) return;

      this.scrollToBottom(this.$refs.addToShared.$el);

      if (id === "all") {
        const groups = this.sharedGroups.filter((g) => g.id !== "all");
        this.sharedGroups = this.sharedGroups.filter((g) => g.id === "all");
        this.selectedSharedGroups = [...this.selectedSharedGroups, ...groups];
        return;
      }
      const selectedGroup = this.sharedGroups.find((g) => g.id === id);
      selectedGroup && this.selectedSharedGroups.push(selectedGroup);
      this.sharedGroups = this.sharedGroups.filter((g) => g.id !== id);
    },
    handleSelectMyGroup(id) {
      this.$refs.addToMy.clearSingleSelect();
      if (!id) return;

      this.scrollToBottom(this.$refs.addToMy.$el);

      if (id === "all") {
        const groups = this.myGroups.filter((g) => g.id !== "all");
        this.myGroups = this.myGroups.filter((g) => g.id === "all");
        this.selectedMyGroups = [...this.selectedMyGroups, ...groups];
        return;
      }
      const selectedGroup = this.myGroups.find((g) => g.id === id);
      selectedGroup && this.selectedMyGroups.push(selectedGroup);
      this.myGroups = this.myGroups.filter((g) => g.id !== id);
    },
    scrollToBottom(element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 200);
    },
  },
  watch: {
    "contactNav.contactNavGroups": {
      immediate: true,
      handler(val) {
        this.sharedGroups = [...val.shares, { id: "all", name: "Add to all" }];
        this.myGroups = [...val.personals, { id: "all", name: "Add to all" }];

        if (this.contactId) {
          api.get_address_groups(this.contactId).then((res) => {
            this.selectedSharedGroups = res.filter((g) => !g.personal);
            this.selectedMyGroups = res.filter((g) => g.personal);

            const shareIds = this.selectedSharedGroups.map((g) => g.id);
            this.sharedGroups = this.sharedGroups.filter((g) => !shareIds.includes(g.id));
            const myIds = this.selectedMyGroups.map((g) => g.id);
            this.myGroups = this.myGroups.filter((g) => !myIds.includes(g.id));
          });
        }
      },
    },
  },
};
</script>
