import { render, staticRenderFns } from "./shipDetail.vue?vue&type=template&id=01b45335&scoped=true&"
import script from "./shipDetail.vue?vue&type=script&lang=js&"
export * from "./shipDetail.vue?vue&type=script&lang=js&"
import style0 from "./shipDetail.vue?vue&type=style&index=0&id=01b45335&prod&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b45335",
  null
  
)

export default component.exports