<template>
  <th class="resizable-column" ref="column" @click="onClick">
    <div @mousedown.prevent.stop="handleMouseDown($event)" @click.stop class="resizable-area" />
    <slot />
  </th>
</template>
<script>
let pageX, curCol, nxtCol, curColWidth, nxtColWidth;
const MIN_WIDTH = 30;

export default {
  data() {
    return {
      startX: 0,
      resizableWidth: null,
      allColumns: {
        name: { property: "--all-name-td-width", defaultWidth: "unset" },
        first_name: { property: "--all-first-name-td-width", defaultWidth: "unset" },
        last_name: { property: "--all-last-name-td-width", defaultWidth: "unset" },
        email: { property: "--all-email-td-width", defaultWidth: "unset" },
        company: { property: "--all-company-td-width", defaultWidth: "unset" },
        country: { property: "--all-country-td-width", defaultWidth: "unset" },
        salutation: { property: "--all-salutation-td-width", defaultWidth: "unset" },
        tag: { property: "--all-tag-td-width", defaultWidth: "unset" },
      },
      mailColumns: {
        "table-time": { property: "--time-td-width", defaultWidth: "140px" },
        "table-to": { property: "--to-td-width", defaultWidth: "150px" },
        "table-from": { property: "--from-td-width", defaultWidth: "150px" },
        "table-subject": { property: "--subject-td-width", defaultWidth: "350px" },
        "table-mailbox": { property: "--mailbox-td-width", defaultWidth: "100px" },
        "table-tag": { property: "--tag-td-width", defaultWidth: "150px" },
        "table-assignment": { property: "--assignment-td-width", defaultWidth: "100px" },
      },
      companyColumns: {
        name: { property: "--company-name-td-width", defaultWidth: "unset" },
        email: { property: "--company-email-td-width", defaultWidth: "unset" },
        company: { property: "--company-company-td-width", defaultWidth: "unset" },
        country: { property: "--company-country-td-width", defaultWidth: "12%" },
        departments: { property: "--company-departments-td-width", defaultWidth: "13%" },
        persons: { property: "--company-persons-td-width", defaultWidth: "13%" },
        salutation: { property: "--company-salutation-td-width", defaultWidth: "unset" },
        tag: { property: "--company-tag-td-width", defaultWidth: "unset" },
      },
      contactPopupColumns: {
        name: { property: "--contact-popup-name-td-width", defaultWidth: "unset" },
        email: { property: "--contact-popup-email-td-width", defaultWidth: "unset" },
        company: { property: "--contact-popup-company-td-width", defaultWidth: "unset" },
        country: { property: "--contact-popup-country-td-width", defaultWidth: "unset" },
      },
      departmentColumns: {
        name: { property: "--department-name-td-width", defaultWidth: "unset" },
        email: { property: "--department-email-td-width", defaultWidth: "unset" },
        company: { property: "--department-company-td-width", defaultWidth: "unset" },
        country: { property: "--department-country-td-width", defaultWidth: "unset" },
        phone: { property: "--department-phone-td-width", defaultWidth: "10%" },
        persons: { property: "--department-persons-td-width", defaultWidth: "13%" },
        salutation: { property: "--department-salutation-td-width", defaultWidth: "unset" },
        tag: { property: "--department-tag-td-width", defaultWidth: "unset" },
      },
      contactColumns: {
        name: { property: "--contact-name-td-width", defaultWidth: "unset" },
        first_name: { property: "--contact-first-name-td-width", defaultWidth: "unset" },
        last_name: { property: "--contact-last-name-td-width", defaultWidth: "unset" },
        email: { property: "--contact-email-td-width", defaultWidth: "unset" },
        company: { property: "--contact-company-td-width", defaultWidth: "unset" },
        country: { property: "--contact-country-td-width", defaultWidth: "unset" },
        department: { property: "--contact-department-td-width", defaultWidth: "unset" },
        job: { property: "--contact-job-td-width", defaultWidth: "12%" },
        phone: { property: "--contact-phone-td-width", defaultWidth: "10%" },
        salutation: { property: "--contact-salutation-td-width", defaultWidth: "unset" },
        tag: { property: "--contact-tag-td-width", defaultWidth: "unset" },
      },
    };
  },
  props: {
    category: {
      type: String,
      default: "mail",
    },
    columnName: String,
  },
  computed: {
    columns() {
      return this[`${this.category}Columns`];
    },
  },
  methods: {
    handleMouseDown(e) {
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;
      curColWidth = curCol.offsetWidth;
      if (nxtCol) nxtColWidth = nxtCol.offsetWidth;

      document.onmousemove = this.handleMouseMove;
      document.onmouseup = this.handleMouseUp;
    },
    handleMouseMove(e) {
      if (!curCol) return;

      const diffX = e.pageX - pageX;
      const nxtWidth = nxtColWidth - diffX;
      const curWidth = curColWidth + diffX;

      if (nxtWidth < MIN_WIDTH || curWidth < MIN_WIDTH) return;

      if (nxtCol) nxtCol.style.width = nxtWidth + "px";
      curCol.style.width = curWidth + "px";
    },
    handleMouseUp(e) {
      this.setWidthAllColumns();
      document.onmousemove = null;
      document.onmouseup = null;
    },
    setWidthAllColumns() {
      const cols = [
        { id: curCol.id, width: curCol.style.width },
        { id: nxtCol.id, width: nxtCol.style.width },
      ];
      cols.forEach((col) => {
        const { property } = this.columns[col.id];
        if (col.width) {
          document.documentElement.style.setProperty(property, col.width);
          localStorage.setItem(property, col.width);
        }
      });
      curCol.style.width = null;
      nxtCol && (nxtCol.style.width = null);
    },
    onClick() {
      this.$emit("click");
    },
    initWidth() {
      if (!this.columns || !this.columns[this.columnName]) return;

      const { property, defaultWidth } = this.columns[this.columnName];
      const savedWidth = localStorage.getItem(property);

      document.documentElement.style.setProperty(this.columns[this.columnName].property, savedWidth || defaultWidth);
    },
  },
  watch: {
    category: {
      immediate: true,
      handler: function () {
        this.initWidth();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.resizable-column {
  position: relative;
  &:last-child {
    .resizable-area {
      display: none;
    }
  }
}
th:not(.table-tag) {
  .resizable-area {
    position: absolute;
    height: 60%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 5px;
    z-index: 0;
    cursor: col-resize;
    border-right: 1px solid var(--border-color);
    user-select: none;
    &:hover {
      border-right-width: 4px;
    }
  }
}
</style>
