<template>
  <div class="dynamic_wrapper history" v-clickoutside="handleClose">
    <Input
      v-model.trim="searchKey"
      :class="{ error: error }"
      class="search-input"
      icon="ios-search-strong"
      :placeholder="placeholder"
      @on-keydown="pointer($event)"
      @on-keyup="onShowSuggestion($event)"
      @on-enter="addPointerElement"
      @click.stop.native="showDropDown"
      style="width: 100%"
      ref="search_input"
    />
    <Dropdown trigger="click" :visible="visible" placement="bottom-start" :style="width" class="search-dropdown">
      <DropdownMenu slot="list">
        <ul class="down_wrapper" v-show="searchList.length > 0" :style="maxHeight">
          <div class="search_list">
            <li
              v-for="(item, index) in searchList"
              class="row_item"
              :class="[{ 'row-highlight': listSelect === index }, { deletable: item.type !== 'suggestion' }]"
              :key="index"
            >
              <div class="normal-item">
                <Icon v-if="item.type === 'suggestion'" type="md-search" />
                <i v-else class="iconfont-clock"></i>
                <span class="text-ellipsis" v-html="item.key" @mousedown.prevent.stop="selectList(index, item)" />
                <Icon
                  v-if="item.type !== 'suggestion'"
                  class="actionable-icon"
                  :class="{ disabled: deleting }"
                  type="md-close"
                  @click.stop="!deleting ? onDeleteItemClick(item) : null"
                />
              </div>
            </li>
          </div>
        </ul>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../fetch/api.js';
import clickoutside from '../../../utils/clickoutside';
import { hotkeyHandler, mailSearchMixin } from '@/mixins';
import debounce from "lodash/debounce";
import utils from '@/utils'

const MAPPING_LABEL_TYPE = {
  plus: 'contains',
  none: 'OR',
  plusA: 'AND',
  dblQuote: 'match exactly',
};
const MAPPING_SYMBOL_TYPE = {
  contains: '//',
  'match exactly': '',
  AND: '+',
  OR: '//',
};

export default {
  name: 'autoInput',
  directives: {clickoutside},
  props: {
    width: {
      type: String,
    },
    height: {
      type: Number,
    },
    itemHeight: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    category: {
      type: String,
    },
  },
  data() {
    return {
      dataList: [],
      visible: false,
      searchKey: '',
      listSelect: -1,
      error: false,
      searchHistory: [],

      symbolIncluded: false,
      searchPrefixes: ['from:', 'to:', 'tag:', 'id:'],

      deleting: false
    }
  },
  created () { },
  mixins: [hotkeyHandler, mailSearchMixin],
  methods: {
    mappingConditionSymbol() {
      this.symbolIncluded = true
      this.searchKey = this.searchKey.replaceAll('<b>', '"');
      this.searchKey = this.searchKey.replaceAll('</b>', '"');
      this.searchKey = this.searchKey.replaceAll('""', '"');
      this.searchKey = this.searchKey.replaceAll(/\s+(?=(?:(?:[^"]*"){2})*[^"]*"[^"]*$)/g,'**');

      let tmp = this.searchKey.split(' ');
      if (tmp.length > 2 && tmp[0] === 'contains') {
        tmp[0] = tmp[2];
      }
      this.searchKey = tmp.join(' ');
      for (const key in MAPPING_SYMBOL_TYPE) {
        if (Object.hasOwnProperty.call(MAPPING_SYMBOL_TYPE, key)) {
          const element = MAPPING_SYMBOL_TYPE[key];
          this.searchKey = this.searchKey.replaceAll(`${key} `, element);
        }
      }
      tmp = this.searchKey.split(' ');
      let i = 0;
      while (i < tmp.length) {
        const words = tmp[i].split('');
        if (words[0] === '"' && words[words.length - 1] !== '"') {
          tmp[i] += '"';
        }
        if (words[0] !== '"') {
          tmp[i] =  tmp[i].replaceAll('"', '');
        }
        i++;
      }
      this.searchKey = tmp.join(' ');
      this.searchKey = this.searchKey.replaceAll('**', ' ');
      this.searchKey = this.searchKey.replaceAll('//', '');
    },
    onShowSuggestion() {
      if (!!this.searchKey.trim()) {
        let tmp = this.searchKey.trim();
        tmp = tmp.replaceAll(/\s+(?=(?:(?:[^"]*"){2})*[^"]*"[^"]*$)/g,'**');
        const suggestionList = [];
        let splitKey = tmp.split(' ');

        // handle email format
        let index = 0;
        while (index < splitKey.length) {
          let keyword = splitKey[index];
          keyword = keyword.replaceAll('from:','');
          keyword = keyword.replaceAll('to:','');
          if (utils.filter.validators.email.test(keyword)) {
            suggestionList.push({
              key: `from:<b>${keyword}</b>`,
              type: 'suggestion',
            }, {
              key: `to:<b>${keyword}</b>`,
              type: 'suggestion',
            });
            splitKey.splice(index, 1);
          } else {
            index++;
          }
        }
        let isGenerateSingleWord = true;
        // generate single
        if (splitKey.length === 1) {
          for (const key of splitKey) {
            const firstChar = key.split('')[0];
            if (!firstChar.includes('"') && !firstChar.includes('+')) {
              ['match exactly', 'contains'].forEach((el) => {
                suggestionList.push({
                  key: `${el} ${el === 'match exactly' ? '"' : ''}<b>${key}</b>${el === 'match exactly' ? '"' : ''}`,
                  type: 'suggestion',
                });
              })
            } else {
              isGenerateSingleWord = false;
            }
          }
        }

        // reverse suggestion list by symbol
        let isAllPlus = splitKey.every(el => el.split('')[0].includes('+'));
        if (isAllPlus && splitKey.length > 0) {
          splitKey = splitKey.map(el => `<b>${el.replaceAll('+', '')}</b>`)
          suggestionList.push({
            key: `contains ${splitKey.join(' AND ')}`,
            type: 'suggestion',
          });
        }

        // generate double | triple
        if (splitKey.length > 1 && !isAllPlus) {
          let multipleKey = splitKey.join(' ');
          suggestionList.push({
            key: `match exactly "<b>${multipleKey}</b>"`,
            type: 'suggestion',
          });
        }

        // generate suggestion
        const generateSuggestion = (currentCombination = []) => {
          if (suggestionList.length >= 30) {
            return;
          }
          const prevIndex = currentCombination.length - 2;
          currentCombination[prevIndex] = MAPPING_LABEL_TYPE[currentCombination[prevIndex]];
          currentCombination[prevIndex + 1] = `<b>${currentCombination[prevIndex + 1]}</b>`
          if (!!currentCombination[prevIndex-2] && (currentCombination[prevIndex] !== currentCombination[prevIndex-2])) {
            return;
          }

          if (currentCombination.length === splitKey.length * 2) {
            const [first, ...joinArray] = currentCombination;
            suggestionList.push({
              key: splitKey.length > 1 ? `contains ${joinArray.join(' ')}` : currentCombination.join(' '),
              type: 'suggestion',
            });
            return;
          }

          let currentKeyword = splitKey[currentCombination.length / 2];
          let typeList = [];

            switch (currentKeyword.split('')[0]) {
              case '+':
                typeList = ['plus'];
                break;

              case '"':
                typeList = ['dblQuote'];
                break;

              default:
                typeList = ['none', 'plusA'];
                break;
            }
          for (const symbol of ['+', '"']) {
            currentKeyword = currentKeyword.replaceAll(symbol, '');
          };
          currentKeyword = currentKeyword.replaceAll('**', ' ');
          for (const condition of typeList) {
            generateSuggestion([...currentCombination, condition, currentKeyword]);
          }
        };
        if (!isAllPlus && (splitKey.length > 1 || !isGenerateSingleWord)) {
          generateSuggestion();
        }
        this.dataList = [];
        this.dataList = suggestionList;
        this.visible = true;
      }
    },
    showDropDown() {
      if(this.searchKey) {
        this.onShowSuggestion();
        return;
      };

      api.get_search_histories().then((res) => {
        if (res.search_histories.length > 0) {
          this.dataList = res.search_histories;
          this.searchHistory = res.search_histories;
          this.visible = true;
        }
      }).catch((error) => {
        console.log(error)
      });
    },
    handleClose(){
      this.visible = false;
    },
    selectList(index, item){
      this.listSelect = index;
      this.searchKey = item.key;
      this.mappingConditionSymbol();
      this.visible = false;
      this.$nextTick(function () {
        this.$el.querySelector('.ivu-input').focus();
        this.addPointerElement();
      })
    },
    pointer(e){
      if (e.keyCode === 40) {//down
        this.pointerForward()
      }
      if (e.keyCode === 38) {//up
        this.pointerBackward()
      }
    },
    pointerForward(){
      let s = this.searchList.length;
      let obj = this.$el.querySelector('.down_wrapper');
      let visibleElements = this.height / this.itemHeight;
      if (s > 0) {
        if (this.listSelect < s - 1) {
          this.listSelect++;
        } else {
          this.listSelect = s - 1;
        }
      }
      if (obj.scrollTop <= this.pointerPosition - visibleElements * this.itemHeight) {
        obj.scrollTop = this.pointerPosition - this.itemHeight;
      }
    },
    pointerBackward() {
      let s = this.searchList.length;
      let obj = this.$el.querySelector('.down_wrapper');
      if (s > 0) {
        if (this.listSelect > 0) {
          this.listSelect--;
        } else {
          this.listSelect = 0;
        }
      }
      if (obj.scrollTop >= (this.pointerPosition - this.itemHeight)) {
        obj.scrollTop = this.pointerPosition - this.height;
      }
    },
    addPointerElement(){
      const convertedSearchKey = this.handleDefaultSearchMethod(this.searchKey)
      this.symbolIncluded = false
      if (this.searchKey.length > 255) {
        this.$Message.error("String length exceeds maximum length limit of 255 characters.")
        return;
      }
      const s = this.searchList.length;
      if (this.visible && this.listSelect < s && this.listSelect > -1) {
        this.searchKey = this.searchList[this.listSelect].key;
        this.mappingConditionSymbol();
        this.$nextTick(() => {
          this.visible = false;
        });
        return;
      }
      this.$emit('searchByKey', convertedSearchKey);
      this.$el.querySelector('.ivu-input').blur();
      this.visible = false;
      this.searchKey = '';
    },
    handleDefaultSearchMethod(searchKey) {
      if(!searchKey)
        return searchKey

      let includeSearchPattern
      this.searchPrefixes.forEach(prefix => {
        if(searchKey.startsWith(prefix))
          includeSearchPattern = true
      })

      if(includeSearchPattern)
        return searchKey

      if(!this.symbolIncluded)
        return this.applySearchMethods(searchKey)

      return searchKey
    },
    handleHotkey(e) {
      if(e.key == 'Escape') {
        e.preventDefault();
        this.searchKey = '';
        this.$refs.search_input && this.$refs.search_input.blur();
      }
    },
    async onDeleteItemClick(item) {
      if(!item) return
      try {
        this.deleting = true;
        await api.delete_search_histories(item.id)
        this.dataList = this.dataList.filter(i => i.id !== item.id)
      } catch(ex) {
      } finally {
        this.deleting = false;
      }
    }
  },
  computed: {
    searchList: function () {
      return this.dataList;
    },
    maxHeight: function () {
      return 'max-height:' + this.height + 'px';
    },
    pointerPosition(){
      let pointer = this.listSelect + 1;
      return pointer * this.itemHeight;
    },
  },
  filters: {},
  watch: {
    searchKey: debounce(
      function(val) {
        this.listSelect = -1;
        this.$el.querySelector('.down_wrapper').scrollTop = 0;
      }, 300
    ),
  },
  components: {}
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.dynamic_wrapper {
  height: 36px;
  .normal-item {
    display: flex;
    gap: 8px;
    align-items: center;

    .text-ellipsis {
      flex: 1;
    }
    .actionable-icon {
      &.disabled {
        color: var(--text-color-disable);
        cursor: not-allowed;
      }
    }
  }
  .search-input {
    position: absolute;
  }
  width: 100%;
  .search-dropdown {
    width: 100%;
    position: relative;
    top: 23px;
  }
  .down_wrapper {
    overflow: auto;
    overflow: overlay;
    text-align: left;
    .search_list {
      .row_item {
        cursor: pointer;
        color: var(--on-component-color);
        font-size: 14px;
        line-height: 1.26;
        padding: 6px 8px;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: var(--hover-color);
          color: var(--text-color);
        }
        &.row-highlight {
          background-color: var(--highlight-color);
          color: var(--text-color);
        }
        &.deletable {
          padding: 4px 8px;
        }
      }
    }
    .no_data {
      width: 100%;
      white-space: nowrap;
      padding: 0 6px;
      font-size: 12px;
      transform: scale(0.8);
      margin-left: -10%;
      color: #666;
      text-align: left;
    }
  }
}
</style>

<style lang="scss">
.dynamic_wrapper {
  .ivu-input {
    padding: 8px 16px;
    border-radius: 200px;
    font-size: 14px;
  }
}
</style>
