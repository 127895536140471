<template>
  <div class="mail-folder__content-menu" :class="{ hidden: !shown }">
    <div class="content" ref="contextMenuContent" :style="{ top: `${position.top}px`, left: `${position.left}px` }">
      <div class="content-menu__loading" v-if="loading">
        <Spin size="large"></Spin>
      </div>
      <slot name="default" v-else />
    </div>
  </div>
</template>

<script>
import { popupPosition } from "@/mixins";

export default {
  mixins: [popupPosition],
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      shown: false,
      position: {
        top: 0,
        left: 0,
      },
    };
  },
  mounted() {
    document.documentElement.addEventListener("click", this.clickOutside);
    this.calculatePosition();
  },
  beforeDestroy() {
    document.documentElement.removeEventListener("click", this.clickOutside);
  },
  methods: {
    closeMenu() {
      if (this.shown) {
        this.shown = this.emailFilingShown = false;
        this.$emit("onClose");
      }
    },
    clickOutside(e) {
      if (e.target && e.target.closest(".mail-folder__content-menu")) return;
      for (let i = 0; i < e.target.classList.length; i++) {
        if (e.target.classList[i] && e.target.classList[i].includes("ivu-modal")) return;
      }
      this.closeMenu();
    },
    calculatePosition() {
      let position = { top: this.position.top, left: this.position.left };
      const menu = this.$refs.contextMenuContent;
      menu &&
        (position = this.computePosition(
          {
            top: this.position.top,
            left: this.position.left,
            right: this.position.left,
            bottom: this.position.top,
            width: 0,
            height: 0,
          },
          menu,
          "right",
        ));
      this.$set(this.position, "top", position.top);
      this.$set(this.position, "left", position.left);
    },
  },
  components: {},
  watch: {
    loading: {
      handler(newValue) {
        this.$nextTick(() => {
          if (newValue) return;
          this.calculatePosition();
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-folder__content-menu {
  &.hidden {
    visibility: hidden;
  }
  .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }
  .content {
    position: fixed;
    z-index: 1000;
    width: 180px;
    left: -180px;
    font-size: 14px;
    border-radius: 3px;
    background-color: var(--component-color);
    color: var(--on-component-color);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid var(--border-color);

    ul {
      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-size: 12px;
        width: 100%;
        list-style-type: none;
        height: 35px;
        padding: 0 16px;

        &.assignment-menu-item {
          padding: 0;
          .item {
            width: 180px;
            display: block;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: var(--hover-color);
        }

        .assignment span {
          padding: 8px 16px;
          display: block;
          font-size: 14px;
        }
        .file-to-folder {
          position: fixed;
        }
      }
    }

    .context-menu-item {
      display: block;
      width: 100%;
    }
  }
  .content-menu__loading {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
