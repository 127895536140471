<template>
  <div class="draggable-modal">
    <Modal
      v-model="showModal"
      :width="width"
      :styles="styles"
      :mask-closable="false"
      :footer-hide="true"
      class="dialog draggable-modal"
    >
      <h2 class="draggable-title" slot="header" ref="title">{{ title }}</h2>
      <slot></slot>
    </Modal>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
    },
    top: {
      type: Number,
    },
  },
  data() {
    return {
      Width: 770,
      leftPosition: 0,
      topPosition: 0,
    };
  },

  computed: {
    styles() {
      return { left: `${this.leftPosition}px`, top: `${this.topPosition}px` };
    },
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.Width = (parseInt(this.width) / 100) * window.innerWidth;
        //          this.width为百分比
        //          this.width = ;
        this.leftPosition = (window.innerWidth - this.Width) / 2; //初始化，居中
        this.topPosition = this.top;
      }
    },
  },
  mounted() {
    this.$refs.title.onmousedown = (e) => {
      e.preventDefault();
      let lastX = e.pageX;
      let lastY = e.pageY;
      const modalHeight = document.querySelector(".draggable-modal .ivu-modal").clientHeight;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      document.onmousemove = (ev) => {
        let offsetX = ev.pageX - lastX;
        let offsetY = ev.pageY - lastY;
        let rightPosition = windowWidth - (this.leftPosition + offsetX + parseInt(this.Width));
        let bottomPosition = windowHeight - (this.topPosition + offsetY + modalHeight);

        //弹窗移动到边界返回
        if (
          this.leftPosition + offsetX < 0 ||
          this.topPosition + offsetY < 0 ||
          rightPosition < 0 ||
          bottomPosition < 0
        ) {
          if (!this.$refs.title.contains(ev.target)) return;
        }

        //超出左右边界处理
        if (this.leftPosition + offsetX < 0) {
          this.leftPosition = 0;
        } else if (rightPosition < 0) {
          this.leftPosition = windowWidth - parseInt(this.Width);
        } else {
          this.leftPosition += offsetX;
        }

        //超出上下边界处理
        if (this.topPosition + offsetY < 0) {
          this.topPosition = 0;
        } else if (bottomPosition < 0) {
          this.topPosition = windowHeight - modalHeight;
        } else {
          this.topPosition += offsetY;
        }

        lastX = ev.pageX;
        lastY = ev.pageY;
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
};
</script>

<style lang="scss" scoped>
.draggable-modal {
  .draggable-title {
    cursor: move;
  }
}
</style>

<style lang="scss">
.draggable-modal {
  .ivu-modal-mask {
    display: none;
  }
  .ivu-modal-wrap {
    position: static;
  }
  .ivu-modal {
    position: fixed;
    z-index: 110;

    .ivu-modal-content {
      background: var(--component-color);
      color: var(--on-component-color);
    }
  }
}
</style>
