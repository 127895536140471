import { LOADING_ROLE, SET_ROLE_LIST, SET_ROLE_DETAIL, SET_ROLE_DISABLE_MENTION } from "../../mutation-type";
import api from "api";

import { Message } from "iview";

const state = {
  loading: false,
  roleList: [],
  roleDetail: {},
};

const getters = {
  roles: (state) => state,
};
const mutations = {
  [LOADING_ROLE]: (state, param) => {
    state.loading = param;
  },
  [SET_ROLE_LIST]: (state, param) => {
    state.roleList = [...param];
    state.loading = false;
  },
  [SET_ROLE_DETAIL]: (state, param) => {
    state.roleDetail = Object.assign({}, param);
  },
  [SET_ROLE_DISABLE_MENTION]: (state, param) => {
    let role = state.roleList.find((r) => r.id == param.id);
    role.disable_mention = param.disable_mention;
  },
};
const actions = {
  async getRoles({ commit }) {
    try {
      commit("LOADING_ROLE", true);
      const response = await api.get_roles();

      if (response) {
        const listData = response.roles;
        commit("SET_ROLE_LIST", listData);
      }
    } catch (error) {
      console.log(error);
      commit("LOADING_ROLE", false);
    }
  },
  async getRoleDetail({ commit }, id) {
    try {
      commit("LOADING_ROLE", true);
      const response = await api.get_role(id);

      if (response) {
        const data = {
          name: response.role.name,
          description: response.role.description,
          disable_mention: response.role.disable_mention,
          folder_ids: response.folder_ids,
          line_ids: response.line_ids,
          users: response.users,
          role_permissions: response.role_permissions,
        };
        commit("SET_ROLE_DETAIL", data);
      }
    } catch (error) {
      console.log(error);
      commit("LOADING_ROLE", false);
    }
  },
  async createRole({ commit, dispatch }, data) {
    try {
      commit("LOADING_ROLE", true);
      const response = await api.add_role(data);

      if (response) {
        dispatch("getRoles");
        Message.success("Create new role successfully!");
      }
    } catch (error) {
      console.log(error);
      commit("LOADING_ROLE", false);
    }
  },
  async updateRole({ commit, dispatch }, params) {
    const { id, data } = params;
    try {
      commit("LOADING_ROLE", true);
      const response = await api.update_role(id, data);

      if (response) {
        Message.success("Update role successfully!");
        dispatch("getRoles");
      }
    } catch (error) {
      console.log(error);
      commit("LOADING_ROLE", false);
    }
  },
  async deleteRole({ commit, dispatch }, id) {
    try {
      commit("LOADING_ROLE", true);
      await api.delete_role(id);

      Message.success("Delete a role successfully!");
      dispatch("getRoles");
    } catch (error) {
      console.log(error);
      commit("LOADING_ROLE", false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
