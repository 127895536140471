import api from "api";
import { ADD_ATTACHMENT, ADD_MULTI_ATTACHMENT, REMOVE_ATTACHMENT, SET_ATTACHMENTS } from "../../mutation-type";
import { Modal } from "iview";
const UPLOAD_START_LOADING = "UPLOAD_START_LOADING";
const UPLOAD_FINISH_LOADING = "UPLOAD_FINISH_LOADING";

const state = {
  loading: false,
  personalTemplate: { attachments: [] },
  teamTemplate: { attachments: [] },
  signature: { attachments: [] },
  errorInlineAttachments: [],
  task: { attachments: [] },
};

const getters = {
  uploadLoading: (state) => state.loading,
  personalTemplateAttachments: (state) => state.personalTemplate.attachments,
  teamTemplateAttachments: (state) => state.teamTemplate.attachments,
  signatureAttachments: (state) => state.signature.attachments,
  errorInlineAttachments: (state) => state.errorInlineAttachments,
  taskAttachments: (state) => state.task.attachments,
};
const mutations = {
  [UPLOAD_START_LOADING]: (state) => {
    state.loading = true;
  },
  [UPLOAD_FINISH_LOADING]: (state) => {
    state.loading = false;
  },
  [SET_ATTACHMENTS]: (state, { source, attachments, isGraphLine }) => {
    if (!source || !attachments) return;

    if (source == "task") attachments.forEach((a) => (a.hardDeletable = true));

    state[source].attachments = attachments;
  },
  [ADD_ATTACHMENT]: (state, att) => {
    const isExist = state[att.source].attachments.some((item) => item.id === att.id);
    if (isExist) return;
    state[att.source].attachments = [...state[att.source].attachments, att];
  },
  [ADD_MULTI_ATTACHMENT]: (state, { attachments, source }) => {
    const validAttachments = attachments.filter(
      (item) => state[source].attachments.findIndex((a) => a.id === item.id) === -1,
    );
    if (!validAttachments || validAttachments.length === 0) return;
    state[source].attachments = [...state[source].attachments, ...validAttachments];
  },
  [REMOVE_ATTACHMENT]: (state, { id, source }) => {
    state[source].attachments = state[source].attachments.filter((item) => item.id !== id);
  },
};
const actions = {
  setAttachments({ commit }, params) {
    commit(SET_ATTACHMENTS, params);
  },
  addAttachment({ commit }, attachment) {
    commit(ADD_ATTACHMENT, attachment);
  },
  addMultiAttachments({ commit }, { attachments, source }) {
    commit(ADD_MULTI_ATTACHMENT, { attachments, source });
  },
  async removeAttachment({ commit }, { id, hardDeletable, source }) {
    if (!hardDeletable) return commit(REMOVE_ATTACHMENT, { id, source });

    commit(UPLOAD_START_LOADING);
    const request = source == "task" ? api.delete_task_file : api.delete_file;
    request(id)
      .then((res) => {
        return commit(REMOVE_ATTACHMENT, { id, source });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(commit(UPLOAD_FINISH_LOADING));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
