/**
 * Created by zxy on 2017/8/24.
 */
// import util from 'util'
// import api from 'api'
import { SET_FOLDERID } from "../../mutation-type";

const state = {
  selectFolderId: null,
  isStar: null,
};
const getters = {
  rightMenu: (state) => state,
};
const mutations = {
  [SET_FOLDERID]: (state, params) => {
    state.selectFolderId = params.selectFolderId;
    state.isStar = params.isStar;
  },
};

const actions = {
  FolderIdInit({ commit }) {
    let params = {
      selectFolderId: null,
      isStar: null,
    };
    commit(SET_FOLDERID, params);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
