<template>
  <Modal
    v-model="confirmShow"
    title="Report errors"
    width="770"
    :styles="{ top: '138px' }"
    class="dialog feedback"
    ok-text="Send Report"
    @on-ok="sendReport"
  >
    <div class="feedback-info">Help us understand what's happening.</div>
    <div class="feedback-content">
      <Input
        v-model.trim="text"
        type="textarea"
        :autosize="{ minRows: 5, maxRows: 6 }"
        placeholder="Enter your reason here"
      >
      </Input>
    </div>
  </Modal>
</template>

<script>
import api from "../../fetch/api.js";

export default {
  data() {
    return {
      confirmShow: false,
      text: "",
      createLoading: false,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
      default: "",
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    show() {
      (this.confirmShow = true), (this.text = "");
    },
    async sendReport() {
      if (this.text === "") {
        this.$Message.error("Please enter your reason!");
        return;
      }
      this.createLoading = true;
      try {
        await api.report_error({
          reportable_type: this.type,
          reportable_id: this.id,
          remark: this.text,
        });
        this.createLoading = false;
        this.confirmShow = false;
      } catch (err) {
        console.log(err);
        this.createLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  &-info {
    padding: 0 16px 16px 0;
    color: #414141;
  }

  &-content {
    background-color: var(--background-color);
    padding: 16px;
  }
}
</style>
