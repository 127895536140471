<template>
  <Modal v-model="confirmShow" :title="`Folder Details - ${folder.name}`" width="770" class="dialog dialog-no-cancel">
    <!--loading-->
    <div class="loading" v-show="detailLoading">
      <Spin size="large" fix></Spin>
    </div>
    <div class="folder">
      <div class="ch-flex folder-detail">
        <div class="folder-info">
          <div class="title">
            <i class="iconfont-folder"></i>
            <span>Type</span>
          </div>
          <div class="info">
            {{
              folder.folder_type === folderTypes.NORMAL
                ? "Normal"
                : folder.folder_type === folderTypes.DYNAMIC
                ? "Defined Search"
                : "View"
            }}
            Folder
          </div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont-user"></i>
            <span>Created by</span>
          </div>
          <div class="info">{{ folder.creater }}</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont ch-icon-calendar"></i>
            <span>Created on</span>
          </div>
          <div class="info">{{ folder.created_at | timeFormat }}</div>
        </div>
        <div class="folder-info" v-if="folder.folder_type === 'dynamic'">
          <div class="title">
            <i class="iconfont ch-icon-mail"></i>
            <span>Search within accounts</span>
          </div>
          <div class="info">{{ lines }}</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont iconfont-user"></i>
            <span>Last edited by</span>
          </div>
          <div class="info">{{ folder.updater }}</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont ch-icon-calendar"></i>
            <span>Last edited on</span>
          </div>
          <div class="info">{{ folder.updated_at | timeFormat }}</div>
        </div>
        <div class="folder-info folders">
          <div class="title">
            <i class="iconfont-folder"></i>
            <span>Folders</span>
          </div>
          <div class="info">
            <span v-for="(folder, index) in folder.folders" :key="index" class="folder-item">
              {{ folder.name }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="rules && rules.length > 0" class="rules">
        <div class="rules-title">
          File in folder if an <span class="highlight">{{ matchScope }}</span> meets
          <span class="highlight">{{ folder.match_type === "every" ? "all" : "at least one" }}</span> rule(s):
        </div>
        <div class="rules-container">
          <div class="rule" v-for="rule in rules" :key="rule.id">
            <div class="ch-flex justify-between">
              <div class="rule-left">
                <div class="highlight">{{ rule.name }}</div>
                <div class="rule-condition" v-if="rule.field !== 'received_at'">{{ rule.strategy | capitalize }}</div>
                <div class="highlight" v-if="rule.field == 'received_at' && rule.content_type == 'range'">
                  {{ rule.firstDate | timeFormat }}
                  <br />
                  {{ rule.lastDate | timeFormat }}
                </div>
                <div class="highlight" v-else>{{ rule.content }}</div>
              </div>
              <div class="rule-right">{{ rule.case_sensitive ? "Case Sensitive" : "" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import api from "api";
import { mapGetters } from "vuex";
import { normalRules, definedSearchRules } from "@/common/constants/rules.constant";
import { ruleEngineMixin } from "@/mixins/rule-engine.mixin";
import util from "util";
import appConstant from "@/common/constants/app.constant";

export default {
  data() {
    return {
      confirmShow: false,
      folder: { lines: [] },
      rules: [],
      detailLoading: false,
      lines: "",
      folderTypes: appConstant.folderType,
    };
  },
  created() {
    util.functionBus.showFolderDetail = this.show;
  },
  beforeDestroy() {
    delete util.functionBus.showFolderDetail;
  },
  computed: {
    ...mapGetters(["teamMembers"]),
    matchScope() {
      if (this.folder.mail_scope === "incoming") {
        return "incoming mail";
      } else if (this.folder.mail_scope === "outgoing") {
        return "outgoing mail";
      } else {
        return "incoming and outgoing mail";
      }
    },
    definedRules() {
      if (this.folder.folder_type == "normal") return normalRules;
      return definedSearchRules;
    },
  },
  mixins: [ruleEngineMixin],
  methods: {
    show(id) {
      this.confirmShow = true;
      this.getDetail(id);
    },
    async getDetail(id) {
      this.detailLoading = true;
      try {
        const res = await api.get_folder_detail(id);
        this.folder = res.folder;
        this.rules = this.parseRulesInfo(res.rules, this.definedRules);
        this.lines = res.folder.lines.length === 0 ? "-" : res.folder.lines.join(", ");
        this.detailLoading = false;
      } catch (err) {
        console.log(err);
        this.detailLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.folder {
  background-color: var(--component-color);
  border: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--on-component-color);

  &-detail {
    flex-wrap: wrap;
  }

  &-info {
    width: 33.33%;
    padding: 16px;
    .title {
      color: var(--label-color);
      i {
        margin-right: 5px;
      }
    }
    .info {
      margin-top: 8px;
    }
  }

  .folders {
    width: 100%;
    .info {
      display: flex;
      row-gap: 8;
    }
    .folder-item {
      padding: 4px 10px;
      background-color: var(--component-color);
      color: var(--text-color);
      border: 1px solid var(--border-color);
      border-radius: 20px;
      margin-right: 8px;
    }
  }

  .rules {
    &-title {
      padding: 16px;
    }

    &-container {
      max-height: 200px;
      overflow: auto;
      overflow: overlay;
    }

    .rule {
      border-top: 1px solid var(--border-color);
      padding: 8px 16px;

      &-left {
        flex: 2;
        display: flex;
        justify-content: space-between;
        div {
          width: 33.33%;
        }
      }

      &-right {
        flex: 1;
        text-align: right;
      }
    }
  }

  .highlight {
    color: var(--primary-color);
    font-weight: bold;
  }
}
</style>
