<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: "icon-svg",
  props: {
    iconClass: {
      required: true,
    },
  },
  computed: {
    iconName() {
      return `#ch-icon-${this.iconClass}`;
    },
  },
};
</script>
