<template>
  <div class="nav-tasks">
    <h4 class="title">My Task</h4>
    <div class="item" @click="onItemClick(status.OPEN)">
      Open
      <span class="count"> {{ task && task.openTaskCount }} </span>
    </div>
    <div class="item" @click="onItemClick(status.ASSIGNED_TO_ME)">
      Assigned to me
      <span class="count"> {{ task && task.assignedTaskCount }} </span>
    </div>
    <div class="item" @click="onItemClick(status.CLOSED)">
      Closed
      <span class="count"> {{ task && task.closedTaskCount }} </span>
    </div>
  </div>
</template>

<script>
import api from "@/fetch/api";
import { mapState, mapActions, mapMutations } from "vuex";
import appConstant from "@/common/constants/app.constant";
export default {
  data() {
    return {
      status: appConstant.taskStatus,
    };
  },
  computed: {
    ...mapState(["task"]),
  },
  methods: {
    ...mapActions(["getTasks"]),
    ...mapMutations(["SET_TASKLIST_STATUS"]),
    onItemClick(status) {
      this.SET_TASKLIST_STATUS(status);
      const params = {
        all: true,
        page: 1,
        per_page: 100,
        status: status,
      };
      this.getTasks(params);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tasks {
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .item {
    margin-left: 20px;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
    .count {
      font-weight: bold;
    }
  }
}
</style>
