import { render, staticRenderFns } from "./addCompany.vue?vue&type=template&id=bc356c3c&scoped=true&"
import script from "./addCompany.vue?vue&type=script&lang=js&"
export * from "./addCompany.vue?vue&type=script&lang=js&"
import style0 from "./addCompany.vue?vue&type=style&index=0&id=bc356c3c&prod&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc356c3c",
  null
  
)

export default component.exports