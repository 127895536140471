<template>
  <resizable-block
    class="detail-wrapper locked-reading-placeholder"
    :class="previewVertical ? 'half-width' : 'half-height'"
    :type="!previewVertical ? 'mailHeight' : 'mailWidth'"
    :resizeWidth="previewVertical"
    :resizeHeight="!previewVertical"
  >
    <div class="text-placeholder">Select an email to read</div>
  </resizable-block>
</template>
<script>
import ResizableBlock from "@/components/ResizableBlock.vue";
export default {
  props: {
    previewVertical: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ResizableBlock,
  },
};
</script>
<style lang="scss" scoped>
.detail-wrapper {
  text-align: left;
  height: 100%;
  width: 100%;
  padding: 0;
  background: var(--surface-color);
  overflow-y: hidden;
}
.locked-reading-placeholder {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  .text-placeholder {
    color: var(--text-color);
    font-size: 13px;
  }
}
.half-width {
  min-width: 400px !important;
  max-width: 100% !important;
  position: relative;
}
.half-height {
  min-height: 300px !important;
  max-height: 100% !important;
  position: relative;
  border-top: 1px solid var(--lighter-border-color);
}
</style>
