const fullStrategies = [
  { value: "contains", label: "Contains" },
  { value: "equal", label: "Equal" },
  { value: "not_contains", label: "Not contains" },
  { value: "not_equal", label: "Not equal" },
];
const tagStrategies = [
  { value: "contains", label: "Contains (OR)" },
  { value: "contains_and", label: "Contains (AND)" },
  { value: "equal", label: "Equal (OR)" },
  { value: "equal_and", label: "Equal (AND)" },
  { value: "not_contains", label: "Not contain" },
  { value: "not_equal", label: "Not equal" },
];
const strategyTextMapping = {
  contains: {
    prefix: "Includes",
    middle: "or",
  },
  contains_and: {
    prefix: "Includes",
    middle: "and",
  },
  equal: {
    prefix: "Equals",
    middle: "or",
  },
  equal_and: {
    prefix: "Equals",
    middle: "and",
  },
  not_contains: {
    prefix: "Excludes",
    middle: "or",
  },
  not_equal: {
    prefix: "Not Equal",
    middle: "or",
  },
};
const containStrategies = [{ value: "contains", label: "Contains" }];
const numberStrategies = [{ value: "equal", label: "Equal" }];
const types = {
  multiple_select: "multiple_select",
  single_select: "single_select",
  text: "text",
  bool: "bool",
  number: "number",
  custom_user_select: "custom_user_select",
};
const rules = {
  user: {
    name: "User",
    field: "user_id",
    type: types.multiple_select,
    content_type: "range",
    strategies: containStrategies,
    show: true,
  },
  username: {
    name: "Username",
    field: "username",
    strategies: fullStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  subject: {
    name: "Subject",
    field: "subject",
    strategies: fullStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  body: {
    name: "Body",
    field: "body",
    strategies: fullStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  subject_or_body: {
    name: "Subject or Body",
    field: "subject_or_body",
    strategies: fullStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  email: {
    name: "Email Address",
    field: "from",
    strategies: fullStrategies,
    type: types.text,
    content_type: "single",
    show: true,
    multipleShow: true,
  },
  tags: {
    name: "Tag",
    field: "tags",
    strategies: tagStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  hasAttachment: {
    name: "Has attachment",
    field: "has_attachment",
    type: types.single_select,
    content_type: "single",
    strategies: [{ value: "equal", label: "Equal" }],
    type_values: [
      { label: "Yes", value: "has" },
      { label: "No", value: "none" },
    ],
    show: true,
  },
  attachmentName: {
    name: "Attachment Name",
    field: "attachment_name",
    strategies: fullStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  attachmentCount: {
    name: "Attachment Count",
    field: "attachments_count",
    type: types.number,
    content_type: "single",
    strategies: numberStrategies,
    show: true,
  },
  hasComment: {
    name: "Has comment",
    field: "has_comment",
    type: types.single_select,
    content_type: "single",
    strategies: [{ value: "equal", label: "Equal" }],
    type_values: [
      { label: "Has comment", value: "has" },
      { label: "No comment", value: "none" },
    ],
    show: true,
  },
  status: {
    name: "Status",
    field: "closed",
    content_type: "single",
    type: types.single_select,
    strategies: [{ value: "equal", label: "Equal" }],
    type_values: [
      { label: "Any", value: "any" },
      { label: "Open", value: "open" },
      { label: "Closed", value: "closed" },
    ],
    show: true,
  },
  assignmentTo: {
    name: "Assigned to",
    field: "assignment",
    type: types.custom_user_select,
    content_type: "single",
    strategies: [{ value: "equal", label: "Equal" }],
    show: true,
  },
  mentionTo: {
    name: "Mention to",
    field: "mention_to",
    type: types.custom_user_select,
    content_type: "single",
    strategies: [{ value: "equal", label: "Equal" }],
    show: true,
  },
  dateRange: {
    name: "Date range",
    field: "received_at",
    type: types.single_select,
    content_type: "single",
    strategies: [{ value: "equal", label: "Equal" }],
    type_values: [
      { label: "None", value: "none" },
      { label: "Within the last month", value: "last_month" },
      { label: "Within the last 6 months", value: "last_six_month" },
      { label: "Within the last year", value: "last_year" },
      { label: "Custom…", value: "custom" },
    ],
    show: true,
  },
  highImportance: {
    name: "High Importance",
    field: "high_importance",
    type: types.bool,
    content_type: "single",
    strategies: [{ value: "equal", label: "Equal" }],
    show: true,
  },
  keyword: {
    name: "Keyword",
    field: "keyword",
    strategies: containStrategies,
    type: types.text,
    content_type: "single",
    show: true,
  },
  // openAICommand: {
  //   name: 'OpenAI',
  //   field: 'openai_rule',
  //   strategies: [{ value: 'equal', label: 'Equal' }],
  //   type: types.text,
  //   content_type: 'single',
  //   desc: 'OpenAI help to file suitable email to folder if email content satisfies condition. For example: "Includes any ship information"',
  //   show: true
  // },
};

const {
  // dateRange,
  hasComment,
  highImportance,
  keyword,
  mentionTo,
  ...normals
} = rules;
const normalRules = [...Object.values(normals)];

const {
  username,
  attachmentCount,
  hasComment: hcd,
  highImportance: hid,
  keyword: kwd,
  mentionTo: mtd,
  // openAICommand,
  ...defineds
} = rules;
const definedSearchRules = [...Object.values(defineds)];

const {
  dateRange: d,
  tags,
  hasComment: hc,
  highImportance: hi,
  keyword: kw,
  mentionTo: mt,
  // openAICommand: op,
  ...userDefineds
} = rules;
const userDefinedRules = [...Object.values(userDefineds)];

let {
  username: u,
  subject_or_body,
  attachmentCount: ac,
  // openAICommand: opa,
  ...advancedSearch
} = rules;
advancedSearch = {
  ...advancedSearch,
  hasAttachment: { ...advancedSearch.hasAttachment, type: types.bool },
  hasComment: { ...advancedSearch.hasComment, type: types.bool },
};
const advancedSearchRules = [...Object.values(advancedSearch)];

const allRules = [...Object.values(rules)];

const ruleActions = [
  {
    label: "Tag with",
    name: "add_tag",
    value: [],
  },
  {
    label: "Forward to",
    name: "forward",
    value: [],
  },
  {
    label: "Assign to",
    name: "assign",
    value: [],
  },
];

let ruleActionNames = {};
ruleActions.map((r) => {
  ruleActionNames[r.name.toUpperCase()] = r.name;
});

export {
  strategyTextMapping,
  allRules,
  normalRules,
  definedSearchRules,
  userDefinedRules,
  advancedSearchRules,
  types,
  ruleActions,
  ruleActionNames,
};
