const LocalStorageConstant = {
  UPDATE_STAR_MAIL: "updateStarMail",
  FAILURE_LOGIN_COUNT: "failureLoginCount",
  MFA_CODE: "mc",
  TWO_FA_ENABLED: "en",
  TOKEN: "token",
  USER: "user",
  SETTING_MAILS: "setting_mails",
  SETTING_COMPANY: "setting_company",
  SETTING_PERMISSIONS: "setting_permissions",
  REMEMBER: "remember",
  QUERY_HANDLING: "queryHandling",
  LOGEDIN_EMAIL: "logedInEmail",
  LINES: "lines",
  SENDABLE_LINE: "sendable_lines",
  BASIC: "basic",
  EXPAND_PREVIEW_VERTICAL: "expandPreviewVertical",
  EXPAND_PREVIEW_HORIZONTAL: "expandPreviewHorizontal",
  GMAIL_ACCOUNT: "gmailAccount",
  MICROSOFT_ACCOUNT: "microsoftAccount",
  WINDOW_SIZE: "windowSize",
  EMAIL_TO_BE_READ: "emailTobeRead",
  HIDE_FILED: "hide_filed",
  HIDE_ASSIGNED: "hide_assigned",
  OPEN_MAIL_TABS: "open_mail_tabs",
  OPEN_MODE: "open_mode",
  COPIED_ATTACHMENTS: "copy_attachments",
  EXPANDING_NAV_ITEMS: "expanding_nav_items",
  EXPANDING_FOLDERS: "expanding_folders",
  SEE_CONTACTS_QUERY: "seeContactsQuery",
  BASE_URL: "base_url",
  REGION: "region",
  SHOW_HIDDEN_EMAIL: "show_hidden_email",
  AUTO_BOOKMARK: "auto_bookmark",
  READ_OR_UNREAD: "read_or_unread",
  SSO_HISTORY: "sso_history",
  TRIGONAL_SELECTED_ACTION: "trigonal_selected_action",
  COUNTRY_LIST: "country_list",
  COMPANY_META: "company_meta",
  RECIPIENTS_SHOW: "recipients_show",
};
export default LocalStorageConstant;
