<template>
  <div>
    <Modal
      v-model="showSearchModal"
      class="dialog advance_dialog"
      :styles="{ top: 'calc(50vh - 280px)' }"
      :scrollable="false"
      width="780"
    >
      <!--:mask-closable="false"-->
      <!--loading-->
      <div class="loading" v-show="loading">
        <Spin size="large" fix></Spin>
      </div>
      <template slot="header">
        <h2>{{ modalTitle }}</h2>
        <!--tabs-->
        <div class="dialog-title-info">
          <p>Searches <strong>existing</strong> mails with criteria defined by you</p>
        </div>
      </template>
      <div class="dialog-contain">
        <Form ref="formValidate" :model="formValidate">
          <div class="contain-line">
            <!-- eslint-disable-next-line -->
            <div
              class="line-item"
              :class="{ top: index < 2 }"
              v-for="(item, index) in formValidate.items"
              v-if="item.show"
              :key="index"
            >
              <div class="formText">
                <div>
                  <!--{{index}}-->
                  <span v-show="!checkboxShow(item.label)">{{ item.label }}</span>
                </div>
                <div v-show="!item.fixed"><i class="iconfont ch-icon-cross_1" @click="deleteFilter(index)"></i></div>
              </div>
              <!--input-->
              <Form-item class="inputText" v-if="inputTextShow(item.label)" :prop="'items.' + index + '.field'">
                <Input v-model.trim="item.content" placeholder=""></Input>
              </Form-item>
              <!--Number-->
              <Form-item
                class="inputNumber"
                v-if="inputNumberShow(item.label)"
                :prop="'items.' + index + '.field'"
                :rules="{ validator: validate, trigger: 'blur' }"
              >
                <div class="multiple">
                  <div class="prepend">
                    <span v-if="item.label == 'Year of Build'">Fr</span>
                    <span v-else>Min</span>
                  </div>
                  <Input v-model.trim="item.minContent" number style="width: 75px; margin-left: -9px"></Input>
                  <div class="prepend">
                    <span v-if="item.label == 'Year of Build'">To</span>
                    <span v-else>Max</span>
                  </div>
                  <Input v-model.trim="item.maxContent" number style="width: 75px; margin-left: -9px"></Input>
                </div>
              </Form-item>
              <!--date-->
              <Form-item
                class="inputNumber"
                v-if="item.label === 'Last Updated'"
                :prop="'items.' + index + '.field'"
                :rules="{ validator: validateDate, trigger: 'change' }"
              >
                <div class="multiple">
                  <DatePicker
                    type="date"
                    :transfer="true"
                    class="ruleDatePicker"
                    placeholder="Date"
                    format="dd/MM/yyyy"
                    v-model="item.firstDate"
                    style="width: 110px"
                  ></DatePicker>
                  <span class="dateText">to</span>
                  <DatePicker
                    type="date"
                    :transfer="true"
                    class="ruleDatePicker"
                    placeholder="Date"
                    format="dd/MM/yyyy"
                    v-model="item.lastDate"
                    style="width: 110px"
                  ></DatePicker>
                </div>
              </Form-item>
              <!--textarea-->
              <Form-item class="textarea" v-if="textareaShow(item.label)">
                <Input
                  v-model.trim="item.content"
                  type="textarea"
                  placeholder=""
                  :autosize="{ minRows: 1.46, maxRows: 3 }"
                ></Input>
              </Form-item>
              <!--select-->
              <Form-item class="selectBox" v-if="item.label === 'Type'">
                <Select v-model="item.content" filterable :transfer="true" placeholder="Select">
                  <Option v-for="i in typeLevels" :value="i" :key="i" :label="i">{{ i }}</Option>
                </Select>
              </Form-item>
              <Form-item class="selectBox" v-if="item.label === 'Category'">
                <Select v-model="item.content" filterable clearable :transfer="true" placeholder="Select">
                  <Option v-for="i in basicData.type_level5" :value="i" :key="i">{{ i }}</Option>
                </Select>
              </Form-item>
              <Form-item class="selectBox" v-if="item.label === 'Status'">
                <Select v-model="item.content" filterable clearable :transfer="true" placeholder="Select">
                  <Option v-for="i in basicData.ship_status" :value="i" :key="i">{{ i }}</Option>
                </Select>
              </Form-item>
              <Form-item class="selectBox" v-if="item.label === 'Flag'">
                <autoSelect
                  :dataList="basicData.flag_names"
                  :label="'name'"
                  :pass="'name'"
                  :liStyle="'ivu-select-item'"
                  :transfer="true"
                  :speStyle="'width:235px;max-height: 100px'"
                  v-model="item.content"
                  :placeholder="'Select'"
                >
                </autoSelect>
                <!--<Select v-model="item.content" filterable clearable :transfer="true" placeholder="Select">-->
                <!--<Option v-for="i in basicData.flag_names" :value="i.name" :key="i.name">{{ i.name }}</Option>-->
                <!--</Select>-->
              </Form-item>
              <Form-item class="selectBox" v-if="item.label === 'Hull Type'">
                <Select v-model="item.content" filterable clearable :transfer="true" placeholder="Select">
                  <Option v-for="i in basicData.hull_types" :value="i" :key="i">{{ i }}</Option>
                </Select>
              </Form-item>
              <Form-item class="selectBox" v-if="item.label === 'Decks'">
                <Select v-model="item.content" filterable clearable :transfer="true" placeholder="Select">
                  <Option v-for="i in basicData.number_of_decks" :value="i" :key="i">{{ i }}</Option>
                </Select>
              </Form-item>
              <!--checkbox-->
              <Form-item class="checkbox" v-if="checkboxShow(item.label)">
                <!--{{item.content}}-->
                <Checkbox v-model="item.content">
                  <span class="checkboxText">{{ item.label }}</span>
                </Checkbox>
              </Form-item>
              <!--</Form-item>-->
            </div>
          </div>
        </Form>
      </div>
      <template slot="footer">
        <!--------------Search---------->
        <p @click="showSearchModal = false">Cancel</p>
        <div class="dropdown-button">
          <Button type="primary" class="primary-button" @click="beforeSubmit"> Search </Button>
          <Dropdown trigger="click" placement="bottom-end" class="options">
            <Icon type="ios-arrow-down" class="triangle" />
            <Dropdown-menu slot="list">
              <Dropdown-item @click.native="openFilters"> Select filters </Dropdown-item>
              <Dropdown-item @click.native="handleReset"> Reset filters </Dropdown-item>
            </Dropdown-menu>
          </Dropdown>
        </div>
      </template>
    </Modal>
    <selectFilter ref="selectFilter" @updateFilter="updateFilter"></selectFilter>
  </div>
</template>

<script type="text/ecmascript-6">
  import autoSelect from '../../../components/autoSelect.vue'
  import selectFilter from './selectFilter.vue';
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
  import api from 'api'

  export default{
    props: {},
    data() {
      return {
        showSearchModal: false,
        loading: false,
        butLoading: false,
        modalTitle: 'Advanced Search',
//        区别是否为edit(非edit时 mail_filter要清空)
        isEdit: false,
        tipVisible: false,
//        from
        typeLevels: ["Tankers", "Bulk Carriers", "Dry Cargo/Passenger", "Tug"],
        //        表单
        formValidate: {
//          if (label === 'Ex Name' || label === 'Tank Coated' || label === 'Slop Coated' || label === 'COW'
//        || label === 'IGS' || label === 'Gearless') {
          items: [
            {label: 'Type', field: 'type_level2', content: '', show: true, fixed: true},
            {label: 'Category', field: 'type_level5', content: '', show: true, fixed: true},
            {label: 'DWT', field: 'deadweight', content: '', minContent: '', maxContent: '', show: true, fixed: true},
            {label: 'Year of Build', field: 'year_of_build', content: '', minContent: '', maxContent: '', show: true, fixed: true},
            {label: 'Reg. Owner', field: 'group_beneficial_owner', content: '', show: true, fixed: true},
            {label: 'Status', field: 'ship_status', content: '', show: true, fixed: true},
            {label: 'Name', field: 'name', content: '', show: true, fixed: true},
            {label: 'Ex Name', field: 'ex_name', content: false, show: true, fixed: true},
            {label: 'IMO', field: 'lrimo', content: '', show: true, fixed: true},
            {label: 'Grain', field: 'grain_capacity', content: '', minContent: '', maxContent: '', show: true, fixed: true},
            {label: 'Bale', field: 'bale_capacity', content: '', minContent: '', maxContent: '', show: true, fixed: true},
//          Identification
            {label: 'Call Sign', field: 'call_sign', content: '', show: false, fixed: false},
            {label: 'Flag', field: 'flag_name', content: '', show: false, fixed: false},
            {label: 'Class', field: 'classification_society', content: '', show: false, fixed: false},
            {label: 'ICE', field: 'ice_capability_descriptive_narrative', content: '', show: false, fixed: false},
//          Tonnage
            {label: 'GT', field: 'gross_tonnage', content: '', show: false, fixed: false},
            {label: 'NT', field: 'net_tonnage', content: '', show: false, fixed: false},
            {label: 'PCNT', field: 'panama_cana_net_tonnage', content: '', show: false, fixed: false},
            {label: 'SCNT', field: 'suez_canal_net_tonnage', content: '', show: false, fixed: false},
//          Hull
            {label: 'Depth', field: 'depth', content: '', show: false, fixed: false},
            {label: 'Draught', field: 'draught', content: '', show: false, fixed: false},
            {label: 'LOA', field: 'length_overall_loa', content: '', show: false, fixed: false},
            {label: 'LBP', field: 'length_between_perpendiculars_lbp', content: '', show: false, fixed: false},
            {label: 'BEAM', field: 'breadth', content: '', show: false, fixed: false},
            {label: 'Speed', field: 'speed', content: '', show: false, fixed: false},
            {label: 'Hull Type', field: 'hull_type', content: '', show: false, fixed: false},
            {label: 'TPCI', field: 'tonnes_per_centimetre_immersion_tpci', content: '', minContent: '', maxContent: '', show: false, fixed: false},
            {label: 'Decks', field: 'number_of_decks', content: '', show: false, fixed: false},
//          General
            {label: 'Cargo Desc', field: 'cargo_capacities_narrative', content: '', show: false, fixed: false},
            {label: 'Gear Desc', field: 'gear_descriptive_narrative', content: '', show: false, fixed: false},
//          Ownership
            {label: 'Manager', field: 'ship_manager', content: '', show: false, fixed: false},
            {label: 'Operator', field: 'operator', content: '', show: false, fixed: false},
            {label: 'Last Updated', field: 'last_update_date', content: '', firstDate: '', lastDate: '', show: false, fixed: false},
//          Dry
            {label: 'Holds', field: 'number_of_holds', content: '', show: false, fixed: false},
            {label: 'Holds Desc', field: 'holds_descriptive_narrative', content: '', show: false, fixed: false},
            {label: 'Hatches', field: 'number_of_hatches', content: '', show: false, fixed: false},
            {label: 'Hatches Desc', field: 'hatches_descriptive_narrative', content: '', show: false, fixed: false},
            {label: 'Gearless', field: 'gearless', content: false, show: false, fixed: false},
            {label: 'TEU', field: 'teu', content: '', show: false, fixed: false},
//          Tankers
            {label: 'No. of Tanks', field: 'number_of_tanks', content: '', show: false, fixed: false},
            {label: 'Tanks Desc', field: 'tanks_descriptive_narrative', content: '', show: false, fixed: false},
            {label: 'No. of Pumps', field: 'number_of_pumps', content: '', show: false, fixed: false},
            {label: 'Tank (cbm)', field: 'liquid_capacity', content: '', show: false, fixed: false},
            {label: 'Tank Coated', field: 'cargo_tank_coatings', content: false, show: false, fixed: false},
            {label: 'Coat Desc', field: 'tank_coatings_narrative', content: '', show: false, fixed: false},
            {label: 'Slop (cbm)', field: 'slop_tank_capacity', content: '', show: false, fixed: false},
            {label: 'Slop Coated', field: 'slop_tank_coatings', content: false, show: false, fixed: false},
            {label: 'Special Tanks Desc', field: 'special_tanks_descriptive_narrative', content: '', show: false, fixed: false},
            {label: 'Gas (cbm)', field: 'gas_capacity', content: '', show: false, fixed: false},
            {label: 'SBT (cbm)', field: 'segregated_ballast_capacity', content: '', show: false, fixed: false},
            {label: 'CBT (cbm)', field: 'clean_ballast_capacity', content: '', show: false, fixed: false},
            {label: 'COW', field: 'crude_oil_washing_cow', content: false, show: false, fixed: false},
            {label: 'IGS', field: 'inert_gas_system_igs', content: false, show: false, fixed: false},
          ],
        },
      }
    },
    updated() {
    },
//    created () {},
//    mounted () {},
    methods: {
      ...mapActions([
        'saveShipAdvance'
      ]),
//---------------------------显示
      show(val){
        Object.assign(this.$data, this.$options.data());
        this.showSearchModal = true;
        if (val) {//id存在 则进入edit
          this.editInit();
          this.isEdit = true;
        }
      },
      editInit(){
        this.loading = true;
        this.modalTitle = 'Edit advanced search';
//        this.formValidate.items = JSON.parse(this.shipAdvance.rules);
        this.formValidate.items = this.shipAdvance.rules;
        this.loading = false;
      },
      inputTextShow(label){
        if (label === 'Reg. Owner' || label === 'Name' || label === 'IMO'
          || label === 'Call Sign' || label === 'Class' || label === 'ICE'
          || label === 'Manager' || label === 'Operator' || label === 'ICE') {
          return true
        }
      },
      inputNumberShow(label){
//        GT - NT - PCNT -  SCNT - Depth-  Draught - LOA - LBP - BEAM - Speed - TPCI - Holds -
//        Hatches - TEU - No. of Tanks - No. of Pumps - Tank (cbm) - Slop (cbm) - Gas (cbm) -
//        SBT (cbm) - CBT (cbm) - -
        if (label === 'DWT' || label === 'Year of Build' || label === 'Grain' || label === 'Bale'
          || label === 'GT' || label === 'NT' || label === 'PCNT'
          || label === 'SCNT' || label === 'Depth' || label === 'Draught' || label === 'LOA'
          || label === 'LBP' || label === 'BEAM' || label === 'Speed' || label === 'TPCI'
          || label === 'Holds' || label === 'Hatches' || label === 'TEU' || label === 'No. of Tanks'
          || label === 'No. of Pumps' || label === 'Tank (cbm)' || label === 'Slop (cbm)' || label === 'Gas (cbm)'
          || label === 'SBT (cbm)' || label === 'CBT (cbm)') {
          return true
        }
      },
      checkboxShow(label){
        if (label === 'Ex Name' || label === 'Tank Coated' || label === 'Slop Coated' || label === 'COW'
          || label === 'IGS' || label === 'Gearless') {
          return true
        }
      },
      textareaShow(label){
        if (label === 'Cargo Desc' || label === 'Gear Desc' || label === 'Holds Desc' || label === 'Hatches Desc'
          || label === 'Tanks Desc' || label === 'Coat Desc' || label === 'Special Tanks Desc') {
          return true
        }
      },
//---------------------------动态显示
      updateFilter(updateFrom){
        for (let item of this.formValidate.items) {
          for (let updateItem of updateFrom) {
            if (updateItem.value === item.field) {
              item.show = updateItem.show;
            }
          }
        }
      },
      deleteFilter(index){
        this.formValidate.items[index].show = false;
      },
//-----------------------------提交前验证
      beforeSubmit() {
        let filters = this.formValidate.items;
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.saveShipAdvance(filters);
            if (this.isEdit) {
              let advanceUrl = '/ships/shipping?search=advance&edit=true&random=' + Math.random();
              if (this.$route.query.name === 'MarineTraffic') {
                advanceUrl = '/ships/shipping?name=MarineTraffic&search=advance&edit=true&random=' + Math.random()
              }
              this.$router.push(advanceUrl);
            } else {
              let advanceUrl = '/ships/shipping?search=advance&edit=false&random=' + Math.random();
              if (this.$route.query.name === 'MarineTraffic') {
                advanceUrl = '/ships/shipping?name=MarineTraffic&search=advance&edit=false&random=' + Math.random()
              }
              this.$router.push(advanceUrl);
            }
            this.showSearchModal = false;
          } else {
            this.$Message.error("Please check your Form");
            return
          }
        });
      },
//-----------------------------重置表单
      handleReset () {
        Object.assign(this.$data.formValidate, this.$options.data().formValidate);
        this.tipVisible = false;
      },
//-----------------------------展开select modal
      openFilters(){
        this.$refs.selectFilter.show(this.formValidate.items);
        this.tipVisible = false;
      },
//---------表格验证（subject、body不能有@）
      validate(rule, e, callback){
        const arr = rule.field.split(".");
        const index = arr[1];
        const form = this.formValidate.items;
        const label = this.formValidate.items[index].label
//        console.log(this.formValidate.items[index])
        if (label === 'DWT' || label === 'Year of Build' || label === 'Grain' || label === 'Bale'
          || label === 'GT' || label === 'NT' || label === 'PCNT'
          || label === 'SCNT' || label === 'Depth' || label === 'Draught' || label === 'LOA'
          || label === 'LBP' || label === 'BEAM' || label === 'Speed' || label === 'TPCI'
          || label === 'Holds' || label === 'Hatches' || label === 'TEU' || label === 'No. of Tanks'
          || label === 'No. of Pumps' || label === 'Tank (cbm)' || label === 'Slop (cbm)' || label === 'Gas (cbm)'
          || label === 'SBT (cbm)' || label === 'CBT (cbm)') {
          const minValue = form[index].minContent;
          const maxValue = form[index].maxContent;
          if ((minValue !== '' && maxValue === '') || (minValue === '' && maxValue !== '')) {
            if (minValue !== '') {
              if (!Number.isInteger(minValue)) {
                callback(new Error('Please enter a numeric value'));
              }
            }
            if (maxValue !== '') {
              if (!Number.isInteger(maxValue)) {
                callback(new Error('Please enter a numeric value'));
              }
            }
            form[index].content = minValue + ',' + maxValue;
          }
          if (minValue !== '' && maxValue !== '') {//0==''
            if (!Number.isInteger(minValue) || !Number.isInteger(maxValue)) {
              callback(new Error('Please enter a numeric value'));
            } else if (minValue > maxValue) {
              callback(new Error('Please fill in the correct range'));
            }
            form[index].content = minValue + ',' + maxValue;
          }
        }
        callback();
      },
      validateDate(rule, e, callback){
        const arr = rule.field.split(".");
        const index = arr[1];
        const form = this.formValidate.items;
        const label = this.formValidate.items[index].label;
        if (label === 'Last Updated') {
          const minValue = form[index].firstDate;
          const maxValue = form[index].lastDate;
          if (minValue.getTime() > maxValue.getTime()) {
            callback(new Error('lease fill the effective time range'));
          }
          form[index].content = minValue + ',' + maxValue;
        }
        callback();
      }
    },
    computed: {
      ...mapGetters([
        'shipAdvance',
      ]),
      ...mapState([
        'basicData',//从vuex获取select数据 避免数据过多造成组件销毁缓慢
      ]),
    }
    ,
    watch: {}
    ,
    components: {
      autoSelect,
      selectFilter
    }
    ,
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.advance_dialog {
  .dialog-contain {
    margin-top: 0;
    padding: 14px 0 14px 16px;
    min-height: 120px;
    max-height: 380px;
    overflow: auto;
    overflow: overlay;
    .contain-line {
      display: flex;
      flex-wrap: wrap;
      .line-item {
        width: 240px;
        min-height: 96px;
        margin-right: 12px;
        border-top: 1px solid var(--border-color);
        padding-top: 4px;

        &.top {
          border-top: none;
          padding-top: 0;
        }
        .formText {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          height: 22px;
          color: #828d9d;
          text-align: left;
          margin: 4px 0;
          .ch-icon-cross_1 {
            cursor: pointer;
            color: #ddd;
            transition: all 0.2s ease-in-out;
            &:hover {
              color: #a3a3a3;
            }
          }
        }
        .multiple {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .prepend {
            width: 40px;
            color: #666;
            font-size: 14px;
            text-align: center;
            background: #e6e6e6;
            vertical-align: top;
          }
          .dateText {
            font-size: 14px;
            color: #828d9d;
          }
        }
        .checkbox {
          text-align: left;
          color: #828d9d;
          /*height: 40px;*/
          line-height: 40px;
          .checkboxText {
            font-size: 14px;
            margin-left: 4px;
            vertical-align: top;
          }
        }
      }
    }
  }
}
</style>
