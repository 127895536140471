<template>
  <div class="ships-wrapper content-wrapper-vertical-menu" :class="{ 'pinned-menu': basicData.pinnedMenu }">
    <VerticalMenu />
    <nav-wrapper class="contanct-nav" ref="navWrapper">
      <template v-slot:ctaButton>
        <div class="ctaButton">
          <button type="primary" class="long" @click="addNewCalendarEvent">
            <svg-sprite fill="var(--primary-contrast)" name="mt_add" :width="18" :height="18" />
            <span class="btn-text">Add event</span>
          </button>
        </div>
      </template>
      <template v-slot:main>
        <date-picker :inline="true" v-model="selectedDate" class="calendar-datepicker" :lang="lang"></date-picker>
        <div class="add-calendar-resource title">
          <h4>My Calendars</h4>
          <svg-sprite
            fill="var(--menu-color)"
            name="mt_add"
            :width="22"
            :height="22"
            @click.native="handleAddCalendar()"
          />
        </div>
        <div class="calendar-source">
          <div class="local-calendar">
            <div v-for="r in localCalendarResources" :key="r.id" class="calendar-source__item">
              <CheckboxGroup v-model="selectedResources">
                <Checkbox :style="{ color: r.converted_auto_color }" :label="r.id">
                  <span class="item-name" :title="r.name">{{ r.name }}</span>
                  <span class="default">{{ r.default ? "(default)" : "" }}</span>
                </Checkbox>
              </CheckboxGroup>
              <div class="calendar-option-icon">
                <Poptip :ref="`poptip-${r.name}`" placement="right" :transfer="true">
                  <i title="Options" class="iconfont-more-vertical" />
                  <div class="action-menu" slot="content">
                    <div class="menu-item" @click.prevent="handleEditCalendar(r)">Edit</div>
                    <div class="menu-item" @click.prevent="handleMarkAsDefault(r)">Set as default</div>
                  </div>
                </Poptip>
              </div>
            </div>
          </div>
          <Collapse simple v-for="line in linesWithCalendar" :key="line.id">
            <Panel class="outlook-calendar-item">
              <div class="add-calendar-resource" :title="line.email">
                <div @click.stop="() => {}">
                  <Checkbox
                    :value="selectedLines.includes(line.id)"
                    @on-change="(selected) => handleSelectOutlookLine(selected, line)"
                  >
                    <strong class="outlook-calendar-name">{{ line.username }}</strong>
                  </Checkbox>
                </div>
                <div class="outlook-calendar-name" :title="line.email">&nbsp;-&nbsp;{{ line.email }}</div>
              </div>
              <div slot="content" class="outlook-calendar">
                <div v-for="r in line.calendars" :key="r.cid" class="calendar-source__item">
                  <CheckboxGroup v-model="selectedResources" class="calendar-source">
                    <Checkbox :style="{ color: r.converted_auto_color }" :label="r.cid">
                      <span class="item-name" :title="r.name">{{ r.name }}</span>
                      <span class="default">{{ r.default ? "(default)" : "" }}</span>
                    </Checkbox>
                  </CheckboxGroup>
                  <div class="calendar-option-icon">
                    <Poptip :ref="`poptip-${r.name}`" placement="right" :transfer="true">
                      <i title="Options" class="iconfont-more-vertical" />
                      <div class="action-menu" slot="content">
                        <div class="menu-item" @click.prevent="handleEditCalendar(r)">Edit</div>
                        <div v-if="r.removable" class="menu-item" @click.prevent="handleDeleteCalendar(r)">Delete</div>
                        <div class="menu-item" @click.prevent="handleMarkAsDefault(r)">Set as default</div>
                      </div>
                    </Poptip>
                  </div>
                </div>
              </div>
              <div class="calendar-option-icon" @click.stop="onPoptipClick(line.id)">
                <Poptip :ref="`poptip-${line.id}`" placement="right" :transfer="true">
                  <i title="Options" class="iconfont-more-vertical" />
                  <div class="action-menu" slot="content">
                    <div class="menu-item" @click.stop.prevent="handleAddCalendar(line)">Add calendar</div>
                  </div>
                </Poptip>
              </div>
            </Panel>
          </Collapse>
        </div>
        <NavTasks class="nav-task" />
      </template>
    </nav-wrapper>
    <div class="header-main">
      <ch-header :showSearchBox="false">
        <template v-slot:title>
          <h2>Calendar</h2>
        </template>
        <router-view ref="calendarTable" :dateView="dateView" :selectedDate="selectedDate"></router-view>
      </ch-header>
    </div>
    <Modal v-model="showResourceForm" :title="calendarModalTitle" @on-ok="handleSubmit('formValidate')">
      <div class="add-calendar-form">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
          <FormItem label="Name" prop="name">
            <Input
              v-model="formValidate.name"
              @on-enter="handleSubmit('formValidate')"
              placeholder="Enter calendar name..."
              :maxlength="254"
              :readonly="(formValidate.id || formValidate.cid) && !formValidate.removable"
              ref="calendarResourceName"
              style="width: 300px"
            />
          </FormItem>
          <FormItem label prop="color" v-if="!['ews'].includes(calendar_type)">
            <div class="calendar-form-color">
              <div>
                <label v-for="{ value } in colors" :for="value" :key="value" class="color-label">
                  <input type="radio" :id="value" v-model="pickedColor" :value="`${value}`" />
                  <span class="ring" :style="{ backgroundColor: value }"></span>
                </label>
              </div>
            </div>
          </FormItem>
          <FormItem label="Default" prop="default">
            <i-switch size="small" v-model="formValidate.default" />
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapState, mapGetters } from "vuex";
import util from "util";

import Cheader from "../../header/Cheader";
import NavWrapper from "@/components/NavWrapper.vue";
import NavTasks from "./nav-tasks.vue";
import VerticalMenu from "components/VerticalMenu.vue";

import appConstant from "@/common/constants/app.constant";

export default {
  data() {
    return {
      selectedDate: new Date(),
      dateView: "dayGridMonth",
      calendar_type: "local",
      formValidate: {
        name: "",
        color: "-1",
        line_id: null,
        default: false,
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "The name cannot be empty",
            trigger: "blur",
          },
        ],
      },
      colors: [...appConstant.outlookCalendarColors],
      showResourceForm: false,
      pickedColor: "",
      selectedLines: JSON.parse(localStorage.getItem("selected-outlook-lines")) || [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 7,
        },
      },
    };
  },
  components: {
    "ch-header": Cheader,
    NavWrapper,
    DatePicker,
    NavTasks,
    VerticalMenu,
  },
  computed: {
    ...mapState(["lines"]),
    ...mapGetters(["calendarResources", "calendar", "basicData", "setting_mails"]),
    calendarModalTitle() {
      switch (this.calendar_type) {
        case "outlook":
          return "Outlook";
        case "gmail":
          return "Gmail";
        default:
          return "MarineTraffic Inbox";
      }
    },
    localCalendarResources() {
      return this.calendarResources.filter((r) => !r.cid);
    },
    externalCalendarResources() {
      return this.calendarResources.filter((r) => r.calendar_type !== "local");
    },
    selectedResources: {
      get() {
        return this.calendar.selectedResources;
      },
      set(val) {
        this.$store.commit("SET_SELECTED_CALENDAR_RESOURCES", val);
      },
    },
    linesWithCalendar() {
      this.lines.forEach((l) => {
        const calendars = [];
        this.externalCalendarResources.forEach((r) => {
          if (r.line_id === l.id) {
            calendars.push(r);
            l.calendar_type = r.calendar_type;
          }
        });
        calendars.length > 0 && (l.calendars = calendars);
      });
      return this.lines.filter((l) => !!l.calendars);
    },
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (!["ews"].includes(this.formValidate.calendar_type)) {
            const calendarColors =
              this.calendar_type === "gmail" ? appConstant.gmailCalendarColors : appConstant.outlookCalendarColors;
            const colorObj = calendarColors.find((cl) => cl.value == this.pickedColor) || {
              key: this.calendar_type === "gmail" ? "0" : "-1",
              value: "",
            };
            this.formValidate.color = colorObj.key;
          } else {
            delete this.formValidate.color;
          }

          if (!this.formValidate.id && !this.formValidate.cid) {
            this.$store.dispatch("createCalendarResource", {
              formData: this.formValidate,
              calendarType: this.calendar_type,
            });
            this.$Message.success("Create calendar successfully.");
          } else {
            this.$store.dispatch("updateCalendarResource", {
              formData: this.formValidate,
              calendarType: this.calendar_type,
            });
            this.$Message.success("Update calendar successfully.");
          }
        } else {
          this.$Message.error("Please check the form!");
        }
        this.showResourceForm = false;
      });
    },
    handleAddCalendar(line) {
      if (line && line.calendars.length > 9) {
        this.$refs[`poptip-${line.id}`][0].visible = false;
        this.$Message.info("The maximum number of calendars has been exceeded");
        return;
      }
      this.calendar_type = line ? line.calendar_type : "local";
      this.formValidate = {};
      this.formValidate.color = "";
      this.pickedColor = "";
      this.formValidate.line_id = line ? line.id : undefined;
      this.showResourceForm = !this.showResourceForm;
      this.focusInput();
    },
    handleEditCalendar(calendar) {
      this.$refs[`poptip-${calendar.name}`][0].visible = false;
      this.showResourceForm = !this.showResourceForm;
      let resource;
      resource = this.calendarResources.find((r) => r.id === calendar.id);
      if (!resource) return;

      this.formValidate = { ...resource };

      const calendarColors =
        calendar.calendar_type === "gmail" ? appConstant.gmailCalendarColors : appConstant.outlookCalendarColors;
      const colorObj = calendarColors.find((cl) => cl.value == this.formValidate.converted_auto_color) || {
        key: "-1",
        value: "",
      };
      this.pickedColor = colorObj.value;

      this.calendar_type = calendar.calendar_type;
      this.focusInput();
    },
    handleDeleteCalendar(calendar) {
      this.$Modal.confirm({
        title: `Delete calendar?`,
        content: `<p style="margin-left:-42px">You will no longer see events from this calendar.</p>`,
        onOk: async () => {
          this.$refs[`poptip-${calendar.name}`][0].visible = false;
          this.$store.dispatch("deleteCalendarResource", {
            id: calendar.id,
            localId: calendar.localId,
            line_id: calendar.line_id,
            calendar_type: calendar.calendar_type,
          });
        },
      });
    },
    handleMarkAsDefault(calendar) {
      this.$refs[`poptip-${calendar.name}`][0].visible = false;
      calendar.default = true;

      this.$store.dispatch("updateCalendarResource", {
        formData: calendar,
        calendarType: calendar.calendar_type,
      });
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs["calendarResourceName"].focus();
      });
    },
    handleSelectOutlookLine(selected, line) {
      const calendarIds = line.calendars.map((c) => c.cid);

      if (selected) {
        this.selectedLines.push(line.id);
        this.selectedResources = [...this.selectedResources, ...calendarIds];
        return;
      }

      this.selectedLines = this.selectedLines.filter((l) => l !== line.id);
      this.selectedResources = this.selectedResources.filter((r) => !calendarIds.includes(r));
    },
    addNewCalendarEvent() {
      util.functionBus.handleDateSelect &&
        util.functionBus.handleDateSelect({
          start: new Date(),
          end: new Date(),
        });
    },
    onPoptipClick(lineId) {
      let keys = [];
      for (let [key] of Object.entries(this.$refs)) {
        if (/^poptip-/.test(key)) {
          keys.push(key);
        }
      }
      keys.forEach((k) => {
        if (k != `poptip-${lineId}`) {
          let poptipRef = this.$refs[k];
          poptipRef && poptipRef.length > 0 && (poptipRef[0].visible = false);
        }
      });
    },
  },
  watch: {
    selectedLines(lineIds) {
      localStorage.setItem("selected-outlook-lines", JSON.stringify(lineIds));
    },
    calendar_type(type) {
      this.colors = type === "gmail" ? [...appConstant.gmailCalendarColors] : [...appConstant.outlookCalendarColors];
    },
    selectedResources(newVal, oldVal) {
      if (oldVal > newVal) return;

      const ids = newVal.filter((id) => !oldVal.includes(id));
      this.$store.dispatch("getCalendarEventsByIds", ids);
    },
  },
  mounted() {
    let settingFirstDayOfWeek =
      this.setting_mails.calendar_start_weekday && this.setting_mails.calendar_start_weekday !== "0"
        ? this.setting_mails.calendar_start_weekday
        : "7";
    this.lang.formatLocale.firstDayOfWeek = parseInt(settingFirstDayOfWeek);
  },
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.ships-wrapper {
  display: flex;
  height: 100%;
  flex: 1 0;
  font-size: 13px;
  .header-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
  }
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 250px;
    .nav {
      width: 100%;
      height: calc(100% - 45px);
      overflow: auto;
      overflow: overlay;
      padding: 16px;
      background-color: transparent;
      .menu-wrapper {
        width: 100%;
        .searchMe {
          font-size: 16px;
          padding-left: 1px;
          padding-right: 10px;
        }
      }
    }
  }
  .minIcon {
    font-size: 2px;
  }
  .mediumIcon {
    font-size: 12px;
    margin-left: 10px;
  }
  .addIcon {
    font-size: 16px;
    vertical-align: top;
    cursor: pointer;
  }
}
.calendar-source {
  flex: 1;
  min-height: 0;
  overflow: auto;
  overflow: overlay;
  display: flex;
  flex-direction: column;
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .item-name {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    label {
      font-weight: bold;
      flex: 1;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      .default {
        font-size: 11px;
        color: var(--on-side-menu-color);
        font-weight: normal;
      }
    }
    .action-menu {
      display: flex;
      flex-direction: column;
    }
    &:hover {
      .calendar-option-icon {
        visibility: visible;
      }
    }
  }
  label {
    padding: 4px 2px;
    margin: 0;
  }
  .calendar-option-icon {
    visibility: hidden;
    position: absolute;
    right: 0;
    background: var(--hover-color);
    i {
      vertical-align: middle;
      color: var(--on-side-menu-color);
    }
  }
}
.nav-task {
  margin-top: 32px;
}
.add-calendar-resource {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.title {
    margin-bottom: 8px;
  }
  svg {
    cursor: pointer;
  }
}
.local-calendar {
  margin-left: 24px;
}
.outlook-calendar-item {
  .ivu-collapse-header {
    &:hover {
      .calendar-option-icon {
        display: flex;
      }
    }
  }
}
.outlook-calendar-name {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--on-side-menu-color);
}
.outlook-calendar {
  margin-left: 27px;
}
h4 {
  font-size: 14px;
}
.add-calendar-form {
  display: flex;
  flex-direction: column;
}
i {
  padding: 7px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
}
.calendar-form-color {
  padding: 10px 10px 10px 0;
  display: flex;
  flex-direction: column;
  .color-label {
    cursor: pointer;
    input {
      position: absolute;
      opacity: 0;
      &:checked ~ .ring {
        outline: 2px solid var(--text-color);
        outline-offset: 2px;
      }
      &:checked ~ .tick {
        background-color: #d3d3d3;
        padding: 5px 10px 5px 0;
        i {
          display: inline;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    .ring {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 10%;
    }
    .tick {
      padding: 5px 10px;
      background-color: rgba(211, 211, 211, 0.315);
      i {
        display: none;
      }
    }
  }
}
.calendar-context-menu {
  border-radius: 3px;
  padding: 5px;
  color: var(--on-component-color);
  position: absolute;
  top: 50px;
  left: 200px;
  background-color: var(--component-color);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.menu-item {
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: var(--hover-color);
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.add-calendar-resource {
  .ivu-checkbox {
    margin-right: 5px;
  }
}
.calendar-source {
  .ivu-checkbox-group {
    flex: 1;
    min-width: 0;
    .ivu-checkbox-wrapper {
      width: 100%;
    }
  }
  .ivu-collapse {
    border: none;
    background-color: transparent !important;
    overflow: hidden;
    .ivu-collapse-item,
    .ivu-collapse-header {
      font-size: 12px !important;
      border: none !important;
    }
    .ivu-collapse-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 !important;
      height: unset !important;
      line-height: unset !important;
      background-color: transparent !important;
      .ivu-icon-ios-arrow-forward {
        margin: 0 !important;
        margin-right: 8px !important;
        color: var(--on-side-menu-color) !important;
      }
    }
    .ivu-collapse-content {
      background-color: transparent !important;
      .ivu-collapse-content-box {
        padding-bottom: 0 !important;
      }
    }
  }
}
.calendar-datepicker.mx-datepicker.mx-datepicker-inline {
  width: 210px;
  .mx-datepicker-main {
    background: transparent;
    color: var(--text-color);
    border: unset;
  }
  .mx-calendar {
    padding: 0;
    padding-bottom: 24px;
    width: 210px;
  }
}
</style>
