import axios from "axios";
import axiosRetry from "axios-retry";
import qs from "qs";
import util from "util";
import md5 from "md5";
import { Message } from "iview";
import storageConstant from "@/common/constants/local-storage.constant";
import store from "@/vuex";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/vue";
import { isEmpty } from "lodash";

let localToken = "";

let Interface, headUrl, externalInterface, externalHeadUrl;
handleMultiTenant();
handleExternalEndpoint();
export { Interface, headUrl, externalInterface, externalHeadUrl };

axiosRetry(axios, {
  retries: 1,
  retryCondition: (error) => /timeout/i.test(error.message),
  shouldResetTimeout: true,
  onRetry: (_count, _error, config) => {
    const totpTokenMD5 = util.getTotpToken();
    totpTokenMD5 && totpTokenMD5 != "" && (config.headers["Mfa-Token"] = totpTokenMD5);
  },
});

let sources = [];
let OutTimeInterval;
// axios config that will be applied to every request
axios.defaults.baseURL = Interface; // interface address
axios.defaults.timeout = 30000;
axios.defaults.timeoutErrorMessage = "Request Timeout";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.interceptors.response.use(
  (res) => {
    if (res.status !== 200 && res.status !== 201 && res.status !== 204 && res.status !== 403) {
      return Promise.reject(res);
    }
    return res;
  },
  async (error) => {
    const silientError = error.config.headers["Silent-Error"];
    if (!error.response) {
      if (/abort/gi.test(error.message)) return;
      if (store.state.online) {
        util.sendMessageToSw({
          message: "checkOnlineStatus",
        });
      }
      !/timeout/i.test(error.message) &&
        !/canceled/i.test(error.message) &&
        !/Network Error/i.test(error.message) &&
        showError(error.message);
      return Promise.reject(error);
    }
    // message
    const message = error.response.data.error || error.response.data.message || error.message;
    const status = error.response.status;
    const isLoginRequest = /^\/app_api\/session.json$/.test(error.response.config.url);

    if (error.config.url.includes("send_reset_password_mail")) {
      return Promise.reject(message);
    }

    if (status === 400 || (isLoginRequest && status === 401)) {
      if (!message) return Promise.reject(error);

      if (typeof message === "object" && message.length > 0) {
        message.forEach((m) => {
          showError({
            content: m,
            duration: 3,
          });
        });
        return Promise.reject(error);
      }

      if (typeof message === "string") {
        if (message.toLowerCase() === "wrong code") {
          localStorage.removeItem("timeGap");
          window.location.hash = "#/login";
        }
        if (message.toLowerCase() === "need_sso_login") {
          return Promise.reject(message);
        }
        showError(message);
      }
      return Promise.reject(error);
    }
    // redirect to login
    if (status === 401) {
      const storageToken = localStorage.getItem(storageConstant.TOKEN);
      const remember = localStorage.getItem(storageConstant.REMEMBER);
      const rawCookie = document.cookie;
      const cookieToken = Cookies.get(storageConstant.TOKEN);
      console.log(
        JSON.stringify({ stk: storageToken, ck: cookieToken, ckraw: rawCookie, rmb: remember, tk: localToken }),
      );
      Sentry.captureMessage("Auth Debug");

      setTimeout(() => {
        window.location = "#/login";
      }, 100);
      return Promise.reject(error);
    }
    if (status === 403) {
      //
      !silientError && showError("You don't have permission to perform this action");
      return Promise.reject(error);
    }
    if (status === 429) {
      const timeReset = +error.response.headers["x-chartdesk-ratelimit-reset"];
      if (timeReset) {
        const duration = Math.floor((new Date(timeReset) - new Date()) / 1000);
        let action = "";

        switch (true) {
          case /comment/i.test(error.config.url):
            action = "comment";
            break;
          default:
            action = "view emails";
            break;
        }

        !silientError && showError(`Too many request. You can continue to ${action} in ${duration} seconds`);
      }
      return Promise.reject(error);
    }

    !silientError && showError(message);
    return Promise.reject(error);
  },
);

function showError(error) {
  if (/printMail/.test(window.location.hash)) {
    return;
  }
  return Message.error(error);
}

export async function getBaseUrl(param = "", isSSODomain = false) {
  try {
    const res = await window.fetch(
      `https://prod-public.chartdesk.net/login/${isSSODomain ? "0" : ""}${md5(param.toLowerCase())}`,
    ); // {"base_url":"staging-api.chartdesk.net","region":"ap-northeast-1"}

    const data = await res.json();

    if (!data) {
      return Message.error("Cannot login");
    }

    const { base_url, region } = data.body;

    localStorage.setItem(storageConstant.BASE_URL, base_url);
    localStorage.setItem(storageConstant.REGION, region);

    handleMultiTenant();
    handleExternalEndpoint();
  } catch (error) {
    Message.error(error);
  }
}

function abortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 60000);

  return abortController.signal;
}

export function fetch(url, params, method, headers, customTimeout, baseURL, authToken, customSignal) {
  const totpTokenMD5 = util.getTotpToken();
  let token = authToken || util.getToken(storageConstant.TOKEN);
  let auth_header = {};
  localToken = token;

  if (typeof token == "string") {
    auth_header = {
      "Auth-Token": token,
    };
    totpTokenMD5 && totpTokenMD5 != "" && (auth_header["Mfa-Token"] = totpTokenMD5);
  }
  if (method === "get") {
    url =
      url +
      "?" +
      qs.stringify(params, {
        arrayFormat: "brackets",
      });
  }

  if (url === "") {
    return new Promise((resolve, reject) => {
      sources.splice(
        sources.findIndex((item) => item.source === null),
        1,
      );
      reject("Frequent repeat request, has been interrupted");
    });
  }

  return new Promise((resolve, reject) => {
    const emailRegex = /^\/emails\?per_page.*$/;
    let defaultHeader = JSON.parse(JSON.stringify(axios.defaults.headers));
    if (headers) {
      Object.assign(defaultHeader, headers);
    }
    Object.assign(defaultHeader, auth_header);
    emailRegex.test(url) && Object.assign(defaultHeader, { "Request-Time": new Date().getTime() });
    axios({
      baseURL: baseURL || axios.defaults.baseURL,
      url: url,
      data: params || {},
      method: method || "get",
      headers: defaultHeader,
      timeout: customTimeout || 30000,
      withCredentials: /^\/app_api\/session.json$/.test(url) ? false : true,
      signal: customSignal ? abortSignal(customSignal) : abortSignal(60000),
    })
      .then((response) => {
        if (!response) reject("Network error.");
        if (response.data.code === 0) {
          const responseData = emailRegex.test(response.config.url)
            ? { ...response.data.body, request_time: response.config.headers["Request-Time"] }
            : response.data.body;
          resolve(responseData);
        } else if (response.status == 204) {
          resolve(response.data);
        }

        if (url === "/auth/logout") {
          clearInterval(OutTimeInterval);
        }

        sources.splice(
          sources.findIndex((item) => item.source === null),
          1,
        );
      })
      .catch((error) => {
        sources.splice(
          sources.findIndex((item) => item.source === null),
          1,
        );
        reject(error);
      });
  });
}
export function HandleLongApiCalls() {
  let reloadTime = parseInt(localStorage.getItem(storageConstant.QUERY_HANDLING))
    ? localStorage.getItem(storageConstant.QUERY_HANDLING)
    : 0;
  reloadTime = parseInt(reloadTime);
  localStorage.setItem(storageConstant.QUERY_HANDLING, ++reloadTime);

  return setTimeout(() => {
    window.location.reload();
  }, 1000 * 60);
}

export default {
  baseURL: () => axios.defaults.baseURL,
  getBaseUrl,
  //-------------------------------------- login
  login(params) {
    // return fetch('/auth/login', params, 'post')
    return fetch("/auth/login/captcha", params, "post", null, null, externalInterface);
  },
  loginWithoutCapcha(params) {
    return fetch("/auth/login/", params, "post", null, null, externalInterface);
  },
  request_login_sso({ tenant_id, provider }) {
    tenant_id && handleMultiTenant(tenant_id);
    return fetch("/saml/login", { provider }, "get", null, null, headUrl);
  },
  login_with_sso(sso_token, tenant_id) {
    tenant_id && handleMultiTenant(tenant_id);
    return fetch("/users/swap", null, "post", null, null, null, sso_token);
  },
  verify_company_domain(data) {
    return fetch("/auth/verify", data, "post", null, null, externalInterface);
  },
  get_company_domains() {
    return fetch("/company_domains", null, "get");
  },
  add_company_domain(params) {
    return fetch("/company_domains", params, "post");
  },
  remove_company_domain(id) {
    return fetch(`/company_domains/${id}`, null, "delete");
  },
  login_session_cookie(params) {
    return fetch("/app_api/session.json", params, "post", null, null, externalHeadUrl);
  },
  logout() {
    return fetch("/auth/logout", {}, "post");
  },
  logout_session_cookie() {
    return fetch("/app_api/session.json", null, "delete", null, null, externalHeadUrl);
  },
  changePassword(params) {
    return fetch("/users/password", params, "post");
  },
  // get basic select data
  get_basic() {
    return fetch("/users/basic_data", "get");
  },
  //---------------------------------send reset password email
  send_reset_email(email) {
    return fetch("/external/users/send_reset_password_mail", { email: email }, "post", null, null, externalInterface);
  },
  //--------------------------------verify token
  verify_token(token) {
    return fetch(
      "/external/users/verify_reset_password_token",
      { token: token },
      "post",
      null,
      null,
      externalInterface,
    );
  },
  //---------------------------------reset password
  reset_password(params) {
    return fetch("/external/users/reset_password", params, "post", null, null, externalInterface);
  },
  //--------------------------------------mail
  // get request recommanded
  get_email(params, headers) {
    const timeout = params.key ? 60000 : 30000;
    return fetch("/emails", params, "get", headers || null, timeout);
  },
  get_bodies(params) {
    return fetch("/email_bodies", params, "get", { "Silent-Error": true });
  },
  get_email_count(params) {
    return fetch("/emails/count", params, "get");
  },
  // Obsolete
  // get_email_by_page(params) {
  //   return fetch('/emails', params, 'get');
  // },
  // Obsolete
  // get_email_by_line_id(params) {
  //   return fetch('/emails', params, 'get');
  // },
  // // Obsolete
  // get_email_by_box(params){
  //   return fetch('/emails', params, 'get');
  // },
  get_lines() {
    return fetch("/lines");
  },
  get_email_dynamic(params) {
    let { email_id, ...para } = params;
    para.via = "web";
    return fetch("/emails/email_details_dynamic/" + email_id, para, "get", null);
  },
  get_email_details(params) {
    let { email_id, ...para } = params;
    para.via = "web";
    return fetch("/emails/detail/" + email_id, para, "get", null);
  },
  get_email_details_actions(params) {
    const id = params.email_id;
    const para = { only_action: true };
    return fetch("/emails/detail/" + id, para, "get");
  },
  get_eml(id) {
    return fetch("/emails/eml_viewer/" + id, "get");
  },
  get_email_emls(params) {
    // GET /api/emails/emls
    // let params = {
    //   'email_ids': ids
    // };
    return fetch("/emails/emls", params, "get");
  },
  get_invitation(email_id) {
    return fetch(`invites/${email_id}/invite`, null, "get");
  },
  answer_invitation(params) {
    return fetch(`invites/${params.email_id}/answer_invite`, { answer: params.answer }, "post");
  },
  // abort email
  abort_emails(ids) {
    return fetch("/emails/unschedule", ids, "post");
  },
  get_comments(params) {
    return fetch("/comments", params, "get");
  },
  get_notifications(params) {
    return fetch("/notifications", params, "get");
  },
  delete_all_notifications() {
    return fetch("/notifications", null, "delete");
  },
  getHelpCenterVersion() {
    return fetch("/about/web_version/latest", null, "get");
  },
  get_history_emails(params) {
    const { target_id } = params;
    const url = `/emails/histories/${target_id}`;
    return fetch(url);
  },
  get_contact_emails(params) {
    const { target_id, per_page, page } = params;
    const url = `/address_books/history/${target_id}?per_page=${per_page}&page=${page}`;
    return fetch(url);
  },
  read_email(id) {
    return fetch(
      "/emails/mark_as_read",
      {
        email_id: id,
      },
      "post",
    );
  },
  // unread_email(params) {
  //   return fetch('/emails/unread_email', {email_id: params.email_id}, 'post')
  // },
  mark_as_reads(params) {
    return fetch("/emails/mark_as_reads", { email_ids: params.email_ids }, "post");
  },
  mark_as_unread({ email_id }) {
    return fetch("/emails/mark_as_unread", { email_id }, "post");
  },
  read_notification(ids) {
    return fetch(
      "/notifications/read",
      {
        ids: ids,
      },
      "post",
    );
  },
  // hide_notification(ids) {
  //   return fetch('/notifications/hide', {ids: ids}, 'post')
  // },
  //  mail report
  get_mail_report(id) {
    return fetch("/email_events/" + id, "get");
  },
  get_failed_report(id) {
    return fetch("failure_emails/" + id, "get");
  },
  star_mail(mailIds) {
    if (!mailIds || mailIds.length == 0) return;

    if (mailIds.length == 1) return fetch("/stars", { starable_id: mailIds[0], starable_type: "Email" }, "post");

    if (mailIds.length > 1)
      return fetch("/stars/star_multi", { starable_ids: mailIds, starable_type: "Email" }, "post");
  },
  unstar_mail(mailIds) {
    if (!mailIds || mailIds.length == 0) return;

    if (mailIds.length == 1) return fetch("/stars", { starable_id: mailIds[0], starable_type: "Email" }, "delete");

    if (mailIds.length > 1)
      return fetch("/stars/unstar_multi", { starable_ids: mailIds, starable_type: "Email" }, "post");
  },
  //----------------------------Folder
  get_folders(parentId, no_cache) {
    let url = `/folders?parent_id=${parentId || 0}${no_cache ? "&no_cache=true" : ""}`;
    return fetch(url);
  },
  create_folder(params) {
    let url = "/folders";
    let method = "post";
    return fetch(url, params, method);
  },
  copy_folder_structure(params) {
    let url = "/folders/copy_structure";
    let method = "post";
    return fetch(url, params, method);
  },
  search_folders(key, parent_id, page = 1, pageSize = 20, searchType, folderType) {
    let url = `/folders/search?page=${page}&per_page=${pageSize}`;

    if (key && key != "") {
      url = `${url}&key=${encodeURIComponent(key)}`;
    }

    if (!util.isNullOrUndefined(parent_id) && parent_id >= 0) url = `${url}&parent_id=${parent_id}`;

    if (folderType) url = `${url}&folder_type=${folderType}`;

    if (searchType) url = `${url}&search_type=${searchType}`;

    return fetch(url);
  },
  get_parent_structures(folderId) {
    return fetch("/folders/parent_structures/" + folderId);
  },
  get_email_folders_data(emailId) {
    return fetch("/emails/" + emailId + "/folders_data");
  },
  //---------folder Detail
  get_folder_detail(id) {
    // GET /api/folders/{id}
    return fetch("/folders/" + id);
  },
  //---------edit folder
  //   PUT /api/folders/{id}
  edit_folder(id, params) {
    return fetch("/folders/" + id, params, "put");
  },
  get_folder_permission(id) {
    return fetch(`/folders/${id}/permission`);
  },

  // get_directory(params){
  //   let url = '/address_books/';
  //   return fetch(url, params);
  // },
  // get_contact_by_addressbookid(id){
  //   let url = `/address_books/`;
  //   let param = {
  //     'address_book_id': id,
  //   }
  //   return fetch(url, param);
  // },

  get_companies(params) {
    return fetch("/address_books/company", params, "get");
  },
  // post request

  send_comment(params) {
    let url = "/comments";
    let method = "post";
    return fetch(url, params, method);
  },
  //----------------------------------send email
  send_email(params) {
    let url = "emails/send";
    let method = "post";
    return fetch(url, params, method);
  },
  save_draft_mail(params) {
    // POST /api/emails/draft
    let url = "/emails/draft";
    let method = "post";
    return fetch(url, params, method);
  },
  // DELETE /api/emails/{email_id}
  delete_draft_mail(id, params) {
    return fetch("/emails/" + id, params, "delete");
  },
  get_signatures(params) {
    return fetch("signatures", params, "get");
  },
  async send_signature(params) {
    const res = await fetch("signatures", params, "post");
    this.get_signatures();
    return res;
  },
  delete_signature(id) {
    return fetch(`signatures/${id}`, null, "delete");
  },
  get_templates(params) {
    return fetch("templates", params, "get");
  },
  get_template(id) {
    return fetch(`templates/${id}`, null, "get");
  },
  create_template(params) {
    return fetch("templates", params, "post");
  },
  update_template(params) {
    const id = params.template.id;
    return fetch(`templates/${id}`, params, "put");
  },
  delete_template(id) {
    return fetch(`templates/${id}`, null, "delete");
  },

  //---------------------------------------email
  add_email_to_folder(params) {
    let url = "/email_folders";
    let method = "post";
    return fetch(url, params, method);
  },
  delete_email_from_folder(params) {
    let url = "/email_folders";
    let method = "delete";
    return fetch(url, params, method);
  },

  delete_emails(params, timeout = null) {
    return fetch("emails/delete", params, "post", null, timeout, null, null, timeout);
  },
  unspam_emails(params) {
    return fetch(`spam/unspams/`, params, "post");
  },
  unspam_email(params) {
    return fetch(`spam/unspam/${params.id}`, params, "post");
  },
  //show original message
  get_original_msg(params) {
    let id = params.id;
    const para = {
      via: params.via,
    };
    return fetch(`/emails/original/${id}`, para, "get");
    // return fetch(`/ships/${id}`, para, 'get');
  },
  // delete request
  // delete_email(params){
  //   let method = 'delete';
  //   let {id} = params;
  //   let url = 'attachments/' + id;
  //   return fetch(url, {}, method);
  // },
  rename_file(params) {
    return fetch("attachments/rename", params, "post");
  },
  delete_file(id) {
    let method = "delete";
    // let {id} = params;
    let url = "attachments/" + id;
    return fetch(url, {}, method);
  },
  delete_all_files(ids) {
    return fetch("attachments/delete_ids", { ids }, "post");
  },
  get_file_link(ids, download = false) {
    if (download) {
      return fetch(`/attachments/${ids}?download=true`);
    } else {
      return fetch(`/attachments/${ids}`);
    }
  },
  archive_email(params) {
    return fetch("emails/archive", params, "post");
  },

  // upload_attachments(params, uploadFunction){
  //   let url = '/attachments';
  //   return upload(url, params, uploadFunction);
  // },
  // -----------------------------ships
  get_shipping(params) {
    return fetch("/ships", params, "get");
  },
  // add ships
  add_ship(params) {
    let url = "/ships";
    let method = "post";
    return fetch(url, params, method);
  },
  //  ship detail data
  get_ship_detail(params) {
    let id = params.id;
    const para = {
      via: params.via,
    };
    return fetch(`/ships/${id}`, para, "get");
  },
  //  ship AIS Information
  get_ais_informations(id) {
    // GET /api/ais_informations/{ship_id}
    return fetch(`/ais_informations/${id}`, "get");
  },
  //  edit ships
  edit_ships(id) {
    return fetch("/ships/" + id, "get");
  },
  //  update ship
  update_ships(id, params) {
    return fetch("/ships/" + id, params, "put");
  },
  // delete ship
  delete_ship(id) {
    return fetch(`/ships/${id}`, null, "delete");
  },
  //  ship suggest
  suggest_ship(name) {
    return fetch(
      "/ships/suggest",
      {
        name: name,
      },
      "get",
    );
  },
  //  save ship advance
  get_line_users() {
    return fetch(`/lines/users`, "get");
  },
  //--------------------ships Groups
  //get ship group
  get_ship_groups() {
    let url = "/ship_groups";
    return fetch(url);
  },
  //add ship group
  create_ship_group(params) {
    let url = "/ship_groups";
    let method = "post";
    return fetch(url, params, method);
  },
  //delete ship group
  delete_ship_group(id) {
    return fetch(`/ship_groups/${id}`, null, "delete");
  },
  //edit ship group(fleet)
  edit_ship_group(id, params) {
    // PUT /api/ship_groups/{id}
    return fetch("/ship_groups/" + id, params, "put");
  },
  //get ship_group detail
  get_fleet_detail(id) {
    return fetch(`/ship_groups/${id}`, "get");
  },
  //拖拽 添加到某个group
  add_ship_to_group(params) {
    // POST /api/ship_group_items
    let url = "/ship_group_items";
    let method = "post";
    return fetch(url, params, method);
  },
  delete_ship_from_group(params) {
    // DELETE /api/ship_group_items
    let url = "/ship_group_items";
    let method = "delete";
    return fetch(url, params, method);
  },
  //------------------------------------------contact
  //get contact detail
  get_contact_detail(params) {
    // GET /api/address_books/{id}
    let id = params.id;
    const para = {
      via: params.via,
    };
    return fetch(`/address_books/${id}`, para, "get");
    // let url = '/address_books/' + id;
    // return fetch(url);
  },
  get_company_meta() {
    let url = "/address_books/companies";
    return fetch(url);
  },
  get_contact_countries() {
    let url = "/countries";
    return fetch(url);
  },
  //get contact group
  get_contact_groups() {
    let url = "/address_groups";
    return fetch(url);
  },
  create_group(params) {
    let url = "/address_groups";
    let method = "post";
    return fetch(url, params, method);
  },
  edit_group(id, params) {
    let url = `/address_groups/${id}`;
    let method = "put";
    return fetch(url, params, method);
  },
  get_group_detail(id) {
    return fetch(`/address_groups/${id}`);
  },
  delete_group(id) {
    return fetch(`/address_groups/${id}`, null, "delete");
  },
  //add contact group
  create_contact_group(params) {
    let url = "/address_groups";
    let method = "post";
    return fetch(url, params, method);
  },
  //get contact List
  get_contact_list(param) {
    return fetch("/address_books", param, "get");
    // return new Promise((_, reject) => {
    //   reject('error');
    // })
  },
  get_company_info(params) {
    return fetch("/contacts/overview/company", params, "get");
  },
  get_address_groups(id) {
    return fetch(`address_groups/address_id/${id}`, null, "get");
  },
  //------------------------------------add contact(1)
  create_address_book(params) {
    let url = "/address_books";
    let method = "post";
    return fetch(url, params, method);
  },
  //读取Contact 详情（->edit）
  edit_contact(id) {
    return fetch("/address_books/" + id, "get");
  },
  //update contact
  update_contact(id, params) {
    return fetch("/address_books/" + id, params, "put");
  },
  //delete_contact
  delete_contact(id) {
    return fetch(`/address_books/${id}`, null, "delete");
  },
  delete_multiple_contacts(ids) {
    return fetch(`/address_books`, { address_book_ids: ids }, "delete");
  },
  add_contact_to_group(params) {
    let url = "/address_group_items";
    let method = "post";
    return fetch(url, params, method);
  },
  delete_contact_from_group(params) {
    let url = "/address_group_items";
    let method = "delete";
    return fetch(url, params, method);
  },
  //获取contact group下email(send mail模块)
  get_contact_group_drop(id) {
    // GET /api/address_groups/{id}/items
    return fetch("/address_groups/" + id + "/items", "get");
  },
  //-----------------------------------send mail
  get_search_list(params) {
    return fetch("/address_books/search", params, "get");
  },
  // ----------------------------------delete recent search
  delete_recent_search_email(params) {
    return fetch("/recent_email_address/ignore_recent_email_address", params, "delete", { "Silent-Error": true });
  },
  //-----------------------------------contact suggest
  get_contact_suggest(params) {
    return fetch("/address_books/suggest", params, "get");
  },
  // -------------------------------------------------------setting
  get_user_settings() {
    return fetch("/user_settings", "get");
  },
  set_user_settings(params) {
    return fetch("/user_settings", params, "put");
  },
  //---------------------------------Mail Accounts
  get_mailAccounts(params) {
    return fetch("/accounts", params, "get");
  },
  // add Mail Accounts
  add_mailAccounts(params) {
    let url = "/accounts";
    let method = "post";
    return fetch(url, params, method);
  },
  add_gmailAccounts(params) {
    let url = "/accounts/google";
    let method = "post";
    return fetch(url, params, method);
  },
  update_gmailAccounts(params) {
    let url = "/accounts/google";
    let method = "put";
    return fetch(url, params, method);
  },
  //get Mail Account Settings by id(edit)
  edit_mailAccounts(id) {
    return fetch("/accounts/" + id, "get");
  },
  //update Mail Account Settings
  update_mailAccounts(id, params) {
    return fetch("/accounts/" + id, params, "put");
  },
  // delete Mail Account
  delete_mailAccounts(id, params) {
    return fetch("/accounts/" + id, params, "delete");
  },
  // 验证已有邮箱
  //   POST /api/accounts/verify(废弃)
  //   validation_mailAccounts(params){
  //     return fetch('/accounts/verify', params, 'post');
  //   },
  //验证新添加的邮箱
  validation_addAccounts(params) {
    return fetch("/accounts/validate", params, "post");
  },

  // impersonation Accounts
  get_impersonationAccounts() {
    return fetch("/impersonation_accounts", null, "get");
  },
  add_impersonationAccount(params) {
    return fetch("/impersonation_accounts", params, "post");
  },
  get_impersonationAccount(id) {
    return fetch("/impersonation_accounts/" + id, "get");
  },
  update_impersonationAccount(id, params) {
    return fetch("/impersonation_accounts/" + id, params, "put");
  },
  delete_impersonationAccount(id) {
    return fetch("/impersonation_accounts/" + id, null, "delete");
  },
  validate_impersonationAccount(params) {
    return fetch("/impersonation_accounts/validate", params, "post");
  },

  // Google service accounts
  get_google_accounts() {
    return fetch("/google_service_accounts", null, "get");
  },
  add_google_account(params) {
    return fetch("/google_service_accounts", params, "post", { "Content-Type": "multipart/form-data" });
  },
  update_google_account(id, params) {
    return fetch("/google_service_accounts/" + id, params, "put");
  },
  delete_google_account(id) {
    return fetch("/google_service_accounts/" + id, null, "delete");
  },

  // Microsoft accounts
  get_microsoft_accounts(params) {
    return fetch("/microsoft_share_accounts", params, "get");
  },
  add_microsoft_account(params) {
    return fetch("/microsoft_share_accounts", params, "post");
  },
  delete_microsoft_accounts(id) {
    return fetch("/microsoft_share_accounts/" + id, null, "delete");
  },

  // role based permissions
  get_roles() {
    return fetch("/roles", null, "get");
  },
  add_role(params) {
    return fetch("/roles", params, "post");
  },
  get_role(id) {
    return fetch("/roles/" + id, "get");
  },
  update_role(id, params) {
    return fetch("/roles/" + id, params, "put");
  },
  delete_role(id) {
    return fetch("/roles/" + id, null, "delete");
  },

  //获取userlist
  get_team_members(params) {
    return fetch("/users", params, "get");
  },
  //  提交access usrList
  post_access_members(params) {
    return fetch("/user_lines", params, "post");
  },
  //  获取user list
  get_users(params) {
    return fetch("/users", params, "get");
  },
  // 删除usr
  delete_user(id, params) {
    return fetch("/users/" + id, params, "delete");
  },
  put_user(id, params) {
    return fetch(`/users/${id}`, params, "put");
  },
  add_user(params) {
    return fetch("/users", params, "post");
  },
  // invite members
  //  POST /api/users/invite
  invite_members(params) {
    return fetch("/users/invite", params, "post");
  },
  //--------------------------------------------------hidden
  hidden_folder(params) {
    // POST /api/hiddens
    return fetch("/hiddens", params, "post");
  },
  show_folder(params) {
    // DELETE /api/hiddens/{id}
    return fetch("/hiddens", params, "delete");
  },
  //--------------------------------------------------Star
  star_folder(params) {
    // POST /api/stars
    return fetch("/stars", params, "post");
  },
  unStar_folder(params) {
    // DELETE /api/stars
    return fetch("/stars", params, "delete");
  },
  //----------------------------------------------delete
  delete_Folder(id, params) {
    // DELETE /api/folders/{id}
    return fetch(`/folders/${id}`, params, "delete");
  },
  //-------------------------------------------------Profile
  set_profile(params) {
    return fetch("/profile", params, "post", { "Content-Type": "multipart/form-data" });
  },
  //--------------------------------------------------search
  get_compose_email_histories(params) {
    return fetch("/recent_email_address", params, "get");
  },
  get_search_histories() {
    return fetch("/search_histories", "get");
  },
  delete_search_histories(id) {
    return fetch(`/search_histories/${id}`, null, "delete");
  },
  get_search_ships(params) {
    return fetch("/search/ships", params, "get");
  },
  get_search_contact(params) {
    return fetch("/search/address_books", params, "get");
  },
  // -----------------------------------------------permissions
  get_company_settings() {
    return fetch("/company_settings");
  },
  set_company_settings(data) {
    return fetch("/company_settings", data, "put");
  },
  //---------------------------------------------folder sort(拖拽排序)
  post_folder_sort(params) {
    //mails
    return fetch("/folders/sort", params, "post");
  },
  post_ship_group_sort(params) {
    //ships
    // POST /api/ship_group_items/sort
    return fetch("/ship_group_items/sort", params, "post");
  },
  post_address_group_sort(params) {
    //contact
    // POST /api/address_group_items/sort
    return fetch("/address_group_items/sort", params, "post");
  },
  // report a problem
  report_error(params) {
    return fetch("/reports", params, "post");
  },
  //----------------------------------------------external(外部链接)
  //-----------获取用户的uuid
  get_share_id(params) {
    return fetch("/shares", params, "get");
  },
  //----------根据uuid获取用户详情
  get_share_contact(id, baseUrl) {
    baseUrl && (axios.defaults.baseURL = atob(baseUrl));
    return fetch(`/external/shares/${id}`, null, "get");
  },
  //------------验证邀请链接
  post_invitations_verified(params) {
    return fetch("/external/invitations/verified", params, "post", null, null, externalInterface);
  },
  //保存邀请页密码 跳转登录页
  post_invitations_accept(params) {
    return fetch("/external/invitations/accept", params, "post", null, null, externalInterface);
  },
  //----------------------------------------ship positions
  search_ship_by_name(name) {
    return fetch(
      "/ship_positions/ships",
      {
        name: name,
      },
      "get",
    );
  },
  // search ports
  search_port(name) {
    return fetch(
      "/ports",
      {
        name: name,
      },
      "get",
    );
  },
  //update ship position
  update_ship_position(params) {
    // return fetch('/ship_positions', obj, 'post')
    return fetch("/ship_positions/v2", params, "post");
  },
  //get ship position
  get_ship_position(obj) {
    return fetch("/ship_positions", obj, "get");
  },
  get_position_by_mail(id) {
    return fetch(`/temp_positions/${id}`, "get");
  },
  get_position_by_detail(id) {
    return fetch(`/ship_positions/${id}`, "get");
  },
  //edit ship position
  edit_ship_position(obj) {
    return fetch(`/ship_positions/${obj.id}`, obj.data, "put");
  },
  //delete ship position
  delete_ship_position(id) {
    return fetch(`/ship_positions/${id}`, null, "delete");
  },
  //----------------------------------------------------tags
  get_tags() {
    return fetch("/company_settings/hashtags", null, "get");
  },
  post_tags(params) {
    return fetch("/tags", params, "post");
  },
  delete_tag(params) {
    return fetch("/tags", params, "delete");
  },
  //-------------------------------suggest tag
  suggest_tags(params) {
    return fetch("/tags", params, "get");
  },
  get_all_ship({ id, source }) {
    const params = source ? { source } : null;
    return fetch(`/ais_informations/ship_latest/${id}`, params, "get");
  },
  ship_detail_change(params) {
    let id = params.id;
    const para = {
      via: params.via,
    };
    params.source && (para.source = params.source);
    return fetch(`/v2/ship/detail/${id}`, para, "get");
  },
  load_qr_image(userId, authToken) {
    return fetch("/qr_code/" + userId, null, "get", null, null, headUrl, authToken);
  },
  verify_2fa_code(params, authToken) {
    return fetch("/mfa/mfa_code/", params, "post", null, null, null, authToken);
  },
  turn_on_2fa(params) {
    return fetch("/users/mfa/", params, "post");
  },
  turn_off_2fa(params) {
    return fetch("/users/mfa/", params, "delete");
  },
  fetch_announcement() {
    const savedBaseUrl = localStorage.getItem(storageConstant.BASE_URL) || "";

    let url = "https://s3-ap-northeast-1.amazonaws.com/static.chartdesk.pro/Announcements/announcement_staging.txt";
    switch (savedBaseUrl) {
      case "qdapi.chartdesk.cn":
        url = "https://qdstatic.oss-cn-qingdao.aliyuncs.com/Announcements/announcement.txt";
        break;
      case "prod-api.chartdesk.net":
        url = "https://s3-ap-northeast-1.amazonaws.com/static.chartdesk.pro/Announcements/announcement.txt";
        break;
      case "prod-api.chartdesk.de":
        url = "https://s3-ap-northeast-1.amazonaws.com/static.chartdesk.pro/Announcements/announcement_de.txt";
        break;
    }
    const fetchAxios = axios.create();
    return fetchAxios({
      url: url,
      method: "get",
    });
  },
  get_ship_position_histories({ id, duration, source }) {
    const para = {
      track_history_time: duration,
    };

    source && (para.source = source);
    return fetch(`/ais_informations/histories/${id}`, para, "get");
  },
  get_line_notification_setting() {
    return fetch("/user_settings/notification", { type: "mobile" }, "get");
  },
  get_mentions_notification_setting() {
    return fetch("/user_settings/notification", { type: "web" }, "get");
  },
  update_line_notification_setting(lineId, status) {
    return fetch("/user_settings/notification/line", { line_id: lineId, status: status }, "put");
  },
  update_mention_user_setting(user) {
    return fetch("/user_settings/notification/user", user, "put");
  },
  update_mention_role_setting(roleId, status) {
    return fetch("/user_settings/notification/role", { role_id: roleId, status: status }, "put");
  },
  assign_to(email_id, assignable_type, assignable_id) {
    return fetch("/assignment", { email_id, assignable_type, assignable_id }, "post");
  },
  unassign(assignment_id) {
    return fetch(`/assignment/${assignment_id}`, null, "delete");
  },
  markAsClosed(emailId) {
    return fetch(`/assignment/close/${emailId}`, null, "post");
  },
  reopen(emailId) {
    return fetch(`/assignment/re_open/${emailId}`, null, "post");
  },
  assign_to_batch(email_ids, assignable_type, assignable_id) {
    return fetch("/assignments", { email_ids, assignable_type, assignable_id }, "post");
  },
  markAsClosedBatch(emailIds) {
    return fetch(`/assignment/closes`, { email_ids: emailIds }, "post");
  },
  reopenBatch(emailIds) {
    return fetch(`/assignment/re_opens`, { email_ids: emailIds }, "post");
  },
  removeMentions(userIds, emailId) {
    return fetch(`/notifications/mentions/${emailId}`, { user_ids: userIds }, "delete");
  },
  get_openedTabs() {
    return fetch("/tabs", null, "get");
  },
  add_tab(tab) {
    return fetch("/tabs", { tab }, "post");
  },
  remove_tab(id) {
    return fetch(`/tabs/${id}`, null, "delete");
  },
  remove_all_tab() {
    return fetch("tabs/delete_tabs", null, "delete");
  },
  update_active_tab(tab) {
    return fetch(`/tabs/${tab.id}`, { tab }, "put");
  },
  get_bookmark() {
    return fetch("/bookmarks", null, "get");
  },
  add_bookmark(bookmark) {
    const { email_id, params, bookmark_type } = bookmark;
    return fetch("/bookmarks", { email_id, params: JSON.stringify(params), bookmark_type }, "post");
  },
  remove_bookmark(emailId) {
    return fetch(`/bookmarks/${emailId}`, null, "delete");
  },
  get_rule(ruleId, folderId) {
    return fetch(`/rule`, { rule_id: ruleId, folder_id: folderId }, "get");
  },
  get_calendars() {
    return fetch("/calendars", null, "get");
  },
  add_calendar(params) {
    return fetch("/calendars", params, "post");
  },
  update_calendar(params) {
    let id = params.calendar.id;
    return fetch(`/calendars/${id}`, params, "put");
  },
  delete_calendar(params) {
    return fetch(`/calendars/${params.id}`, params, "delete");
  },
  get_events_by_date(params) {
    return fetch("/events", params, "get");
  },
  get_events_by_calendar_id(calendarId) {
    return fetch(`/events?calendar_id=${calendarId}`, null, "get");
  },
  get_event_detail(params) {
    return fetch("/events/detail", params, "get");
  },
  add_calendar_event(params) {
    return fetch("/events", params, "post");
  },
  update_calendar_event(params) {
    return fetch(`/events/${encodeURIComponent(params.event.id)}`, params, "put");
  },
  delete_calendar_event(params) {
    return fetch(`/events/${encodeURIComponent(params.id)}`, params, "delete");
  },
  add_emails_to_folders(email_ids, folder_ids) {
    return fetch(`/folders/add_email_to_folders`, { email_ids: email_ids, folder_ids: folder_ids }, "post");
  },
  mark_as_spam(emailIds) {
    return fetch("/spam", { ids: emailIds }, "post");
  },
  get_rules(field) {
    return fetch("/rules", { field: field }, "get");
  },
  add_rules(params) {
    return fetch("/rules", params, "post");
  },
  update_rule(params) {
    return fetch("/rules", params, "put");
  },
  delete_rules(params) {
    return fetch("/rules", params, "delete");
  },
  remove_comment(params) {
    return fetch("/comments", params, "delete");
  },
  get_action_log(params) {
    return fetch("/actions", params, "get");
  },
  get_user_defined_rules() {
    return fetch("/rules/lines", null, "get");
  },
  get_user_defined_rule_detail(id) {
    return fetch(`/rules/rule_line/${id}`, null, "get");
  },
  add_user_defined_rule(params) {
    return fetch("/rules/lines", params, "post");
  },
  update_user_defined_rule(id, params) {
    return fetch(`/rules/lines/${id}`, params, "put");
  },
  delete_user_defined_rule(ids) {
    return fetch("/rules/lines", { ids: ids }, "delete");
  },
  sort_rule(id) {
    return fetch(`/rules/sort/${id}`, null, "put");
  },
  get_pending_invites() {
    return fetch(`/users/pending_invites`, null, "get");
  },
  revoke_invite(invitedMemberId) {
    return fetch(`/users/revoke/${invitedMemberId}`, null, "put");
  },
  delete_invite(inviteId) {
    return fetch(`/users/invite/${inviteId}`, null, "delete");
  },
  reinvite(inviteId, invitedMemberId) {
    return fetch(`/users/invite/${inviteId}/re_invite/${invitedMemberId}`, null, "post");
  },
  force_logout(userId) {
    return fetch(`/users/force_logout/${userId}`, null, "post");
  },
  get_tasks(params) {
    return fetch("/tasks", params, "get");
  },
  get_task_detail(id) {
    return fetch(`/tasks/detail/${id}`, null, "get");
  },
  create_task(params) {
    return fetch("/tasks", params, "post");
  },
  update_task(params) {
    return fetch(`/tasks/${params.id}`, params, "put");
  },
  delete_task(id) {
    return fetch(`/tasks/${id}`, null, "delete");
  },
  delete_tasks(ids) {
    return fetch(`/tasks/deletes`, { task_ids: ids }, "post");
  },
  task_assign_to(task_id, task_assignable_type, task_assignable_id) {
    return fetch("/task_assignments", { task_id, task_assignable_type, task_assignable_id }, "post");
  },
  task_unassign(assignment_id) {
    return fetch(`/task_assignments/${assignment_id}`, null, "delete");
  },
  task_mark_as_closed(task_id) {
    return fetch(`/task_assignments/close/${task_id}`, null, "post");
  },
  task_reopen(task_id) {
    return fetch(`/task_assignments/re_open/${task_id}`, null, "post");
  },
  task_mark_as_closed_batch(task_ids) {
    return fetch(`/task_assignments/closes`, { task_ids: task_ids }, "post");
  },
  task_reopen_batch(task_ids) {
    return fetch(`/task_assignments/re_opens`, { task_ids: task_ids }, "post");
  },
  get_task_file_link(ids, download = false) {
    if (download) {
      return fetch(`/task_attachments/${ids}?download=true`);
    } else {
      return fetch(`/task_attachments/${ids}`);
    }
  },
  delete_task_file(id) {
    return fetch(`task_attachments/${id}`, null, "delete");
  },
  lock_draft(emailId, status) {
    return fetch(`lock/email/${emailId}`, { status: status }, "post");
  },
  hidden_batch(params) {
    return fetch("/hiddens/batch", params, "post");
  },
  unhide_batch(params) {
    return fetch("/hiddens/batch", params, "delete");
  },
  add_contact_openai(text) {
    return fetch("ai/openai_api/", { txt: text }, "post");
  },
  get_trigonal_capabilities() {
    return fetch("v1/trigonal/capabilities", null, "get");
  },
  trigonal_filing({ email_ids, capability_type }) {
    return fetch(
      "v1/trigonal/action/filing",
      {
        email_ids,
        capability_type,
      },
      "post",
    );
  },
  executeOpenAICommand(email_id, vessel_type) {
    return fetch(`/ship_positions/parser/${email_id}`, { vessel_type }, "get", { "Silent-Error": true });
  },
};

onlineCheck();

function onlineCheck() {
  OutTimeInterval = setInterval(function () {
    const is2FAEnabled = util.is2FAEnabled();
    const totpTokenMD5 = util.getTotpToken();
    const token = util.getToken(storageConstant.TOKEN);

    if (typeof token !== "string" || token == "" || (is2FAEnabled === true && !totpTokenMD5)) return;

    fetch("/users/online_check", {}, "get");
  }, 60000 * 5);
}

export function handleExternalEndpoint() {
  const savedBaseUrl = localStorage.getItem(storageConstant.BASE_URL) || "";
  if (!isEmpty(savedBaseUrl)) {
    externalInterface = `https://endpoint.${savedBaseUrl}/api/`;
    externalHeadUrl = `https://endpoint.${savedBaseUrl}/`;
  } else {
    externalInterface = "https://endpoint.staging-api.chartdesk.net/api/";
    externalHeadUrl = "https://endpoint.staging-api.chartdesk.net/";
  }
  return;
  switch (savedBaseUrl) {
    case "qdapi.chartdesk.cn":
      externalInterface = "https://endpoint.qdapi.chartdesk.cn/api/";
      externalHeadUrl = "https://endpoint.qdapi.chartdesk.cn/";
      break;
    case "prod-api.chartdesk.net":
      externalInterface = "https://endpoint.prod-api.chartdesk.net/api/";
      externalHeadUrl = "https://endpoint.prod-api.chartdesk.net/";
      break;
    case "admin.chartdesk.net":
      externalInterface = "https://endpoint.admin.chartdesk.net/api/";
      externalHeadUrl = "https://endpoint.admin.chartdesk.net/";
      break;
    case "prod-api.chartdesk.de":
      externalInterface = "https://endpoint.prod-api.chartdesk.de/api/";
      externalHeadUrl = "https://endpoint.prod-api.chartdesk.de/";
      break;
    case "admin.chartdesk.de":
      externalInterface = "https://endpoint.admin.chartdesk.de/api/";
      externalHeadUrl = "https://endpoint.admin.chartdesk.de/";
      break;
    default:
      externalInterface = "https://endpoint.staging-api.chartdesk.net/api/";
      externalHeadUrl = "https://endpoint.staging-api.chartdesk.net/";

      if (location.host.includes("chartdesk.pro") && !location.host.includes("staging")) {
        externalInterface = "https://endpoint.prod-api.chartdesk.net/api/";
        externalHeadUrl = "https://endpoint.prod-api.chartdesk.net/";
      }

      if (location.host.includes("chartdesk.de")) {
        externalInterface = "https://endpoint.prod-api.chartdesk.de/api/";
        externalHeadUrl = "https://endpoint.prod-api.chartdesk.de/";
      }

      break;
  }
}

export function handleMultiTenant(tenant_id) {
  const token = util.getToken(storageConstant.TOKEN);
  const tenantId = tenant_id || util.getTenantID();
  if (typeof token == "string" && !tenantId) {
    window.location = "#/login";
    return;
  }
  if (!tenantId) return;

  const savedBaseUrl = localStorage.getItem(storageConstant.BASE_URL) || "";

  if (!isEmpty(savedBaseUrl)) {
    Interface = `https://${savedBaseUrl}/api/`;
    headUrl = `https://${savedBaseUrl}/`;
  } else {
    Interface = "https://staging-api.chartdesk.net/api/";
    headUrl = "https://staging-api.chartdesk.net/";
  }

  // Using another endpoint for multple tenant
  // switch (savedBaseUrl) {
  //   case "qdapi.chartdesk.cn":
  //     Interface = "https://qdapi.chartdesk.cn/api/";
  //     headUrl = "https://qdapi.chartdesk.cn/";
  //     break;
  //   case "admin.chartdesk.net":
  //     Interface = "https://admin.chartdesk.net/api/";
  //     headUrl = "https://admin.chartdesk.net/";
  //     break;
  //   case "prod-api.chartdesk.net":
  //     Interface = "https://prod-api.chartdesk.net/api/";
  //     headUrl = "https://prod-api.chartdesk.net/";
  //     break;
  //   case "admin.chartdesk.de":
  //     Interface = "https://admin.chartdesk.de/api/";
  //     headUrl = "https://admin.chartdesk.de/";
  //     break;
  //   case "prod-api.chartdesk.de":
  //     Interface = "https://prod-api.chartdesk.de/api/";
  //     headUrl = "https://prod-api.chartdesk.de/";
  //     break;
  //   default:
  //     Interface = "https://staging-api.chartdesk.net/api/";
  //     headUrl = "https://staging-api.chartdesk.net/";

  //     if (location.host.includes('chartdesk.pro') && !location.host.includes('staging')) {
  //       Interface = "https://prod-api.chartdesk.net/api/";
  //       headUrl = "https://prod-api.chartdesk.net/";
  //     }

  //     if (location.host.includes('chartdesk.de')) {
  //       Interface = "https://prod-api.chartdesk.de/api/";
  //       headUrl = "https://prod-api.chartdesk.de/";
  //     }

  //     break;
  // }

  Interface = util.appendTenantID(Interface, tenantId);
  headUrl = util.appendTenantID(headUrl, tenantId);
  axios.defaults.baseURL = Interface;
}
