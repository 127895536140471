import api from "api";
import { SET_TEMPLATES, SET_CURRENT_TEMPLATE, RESET_CURRENT_TEMPLATE } from "../../mutation-type";
const TEMPLATE_START_LOADING = "TEMPLATE_START_LOADING";
const TEMPLATE_FINISH_LOADING = "TEMPLATE_FINISH_LOADING";

const state = {
  loading: false,
  templates: [],
  currentTemplate: {
    personal: {},
    team: {},
  },
};

const getters = {
  templateLoading: (state) => state.loading,
  templates: (state) => state.templates,
  currentTemplate: (state) => state.currentTemplate,
};
const mutations = {
  [TEMPLATE_START_LOADING]: (state) => {
    state.loading = true;
  },
  [TEMPLATE_FINISH_LOADING]: (state) => {
    state.loading = false;
  },
  [SET_TEMPLATES]: (state, templates) => {
    state.templates = templates;
  },
  [SET_CURRENT_TEMPLATE]: (state, template) => {
    template.personal ? (state.currentTemplate.personal = template) : (state.currentTemplate.team = template);
  },
  [RESET_CURRENT_TEMPLATE]: (state, templateType) => {
    state.currentTemplate[templateType] = {
      id: null,
      name: "",
      subject: "",
      attachments: [],
      body: "",
      body_type: "html",
      personal: templateType === "personal",
    };
  },
};
const actions = {
  resetCurrentTemplate({ commit, dispatch }, templateType) {
    commit(RESET_CURRENT_TEMPLATE, templateType);
    dispatch("setAttachments", { source: `${templateType}Template`, attachments: [] });
  },
  async createTemplate({ commit, dispatch }, params) {
    commit(TEMPLATE_START_LOADING);
    try {
      await api.create_template(params);
      dispatch("getTemplates");
    } catch (error) {
      console.log(error);
    } finally {
      commit(TEMPLATE_FINISH_LOADING);
    }
  },
  async getTemplates({ commit }) {
    commit(TEMPLATE_START_LOADING);
    try {
      let res = await api.get_templates();
      res = res.email_templates;
      commit(SET_TEMPLATES, res);
    } catch (error) {
      console.log(error);
    } finally {
      commit(TEMPLATE_FINISH_LOADING);
    }
  },
  async getTemplate({ commit, dispatch }, id) {
    commit(TEMPLATE_START_LOADING);
    try {
      let res = await api.get_template(id);
      res = res.email_template;
      commit(SET_CURRENT_TEMPLATE, res);

      const store = res.personal ? "personalTemplate" : "teamTemplate";
      dispatch("setAttachments", {
        source: store,
        attachments: res.attachments,
      });
    } catch (error) {
      console.log(error);
    } finally {
      commit(TEMPLATE_FINISH_LOADING);
    }
  },
  async updateTemplate({ commit, dispatch }, params) {
    commit(TEMPLATE_START_LOADING);
    try {
      await api.update_template(params);
      await dispatch("getTemplates");
      dispatch("getTemplate", params.template.id);
    } catch (error) {
      console.log(error);
    } finally {
      commit(TEMPLATE_FINISH_LOADING);
    }
  },
  async deleteTemplate({ commit, dispatch }, id) {
    commit(TEMPLATE_START_LOADING);
    try {
      await api.delete_template(id);
      dispatch("getTemplates");
    } catch (error) {
      console.log(error);
    } finally {
      commit(TEMPLATE_FINISH_LOADING);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
