<template>
  <div class="search-wrapper">
    <Input
      v-model.trim="searchKey"
      clearable
      @on-enter="searchAddress"
      size="large"
      :placeholder="placeholder"
      :style="inputSize"
    ></Input>
    <div class="search-button" :style="buttonSize" @click="searchAddress">
      <i class="iconfont ch-icon-search"></i>
      <span class="search-text">Search</span>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default{
    props: {
      inputSize: {
        type: String,
      },
      buttonSize: {
        type: String,
      },
      placeholder:{
        type: String,
      }
    },
    data() {
      return {
        searchKey: ''
      }
    },
    created () {
    },
    computed: {},
    methods: {
      searchAddress(){
        this.$emit('searchAddress', this.searchKey);
        this.$emit('openSearch', this.searchKey);
      }
    },
    filters: {},
    watch: {
//    检测路由变化(/id)
      ''(){
      }
    },
    components: {}
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.search-wrapper {
  display: flex;
  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-left: -1px;
    text-align: center;
    background: #f6f8fa;
    border: 1px solid var(--border-color);
    color: #828d9d;
    &:hover {
      cursor: pointer;
      color: var(--primary-color);
    }
    .ch-icon-search {
      font-size: 24px;
      transition: all 0.2s ease-in-out;
    }
    .search-text {
      font-size: 16px;
      font-weight: bold;
      margin-left: 8px;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
