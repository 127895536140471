<template>
  <div
    :id="folderItem.id"
    :folderId="folderItem.id"
    class="folder-contain"
    :class="{ 'is-hidden': folderItem.hidden }"
    ref="folder"
  >
    <div
      :id="folderItem.id"
      :folderId="folderItem.id"
      class="folder-name"
      :class="{ active: typeof active == 'boolean' ? active : isActive(folderItem), disabled: !folderItem.id }"
      :draggable="true"
      @dragstart="onDrag($event, folderItem)"
      @dragover="folderItem.id && dragMailOver($event, folderItem.folder_type)"
      @dragenter="folderItem.id && dragMailEnter($event, folderItem.folder_type)"
      @dragleave="folderItem.id && dragMailLeave($event)"
      @drop="folderItem.id && dragMailOnDrop($event, folderItem.folder_type)"
      @click.stop="folderItem.id && (onFolderClick ? onFolderClick(folderItem) : selectFolder())"
      @mousedown.right.prevent="folderItem.id && showContextMenu && showRightMenu(folderItem, $event)"
      @contextmenu.prevent
    >
      <template v-if="showFolderIcon">
        <svg-sprite
          v-if="folderItem.folder_type === folderTypes.VIEW"
          name="ic_view_folder"
          :width="16"
          :height="16"
          viewBox="0 0 24 24"
        />
        <i
          v-else
          class="iconfont"
          :ref="'folderIcon' + folderItem.id"
          :class="{
            'iconfont-folder': folderItem.folder_type === folderTypes.NORMAL,
            'iconfont-folder-search': folderItem.folder_type === folderTypes.DYNAMIC,
          }"
        />
      </template>
      <span
        class="folder-name-text"
        :ref="'folderNameText' + folderItem.id"
        :title="folderItem.id ? folderItem.name : `Copying ${folderItem.name} ...`"
      >
        {{ folderItem.name }}
      </span>
      <span class="unread_count" v-show="folderItem.unread_count && folderItem.unread_count != 0">
        ({{ folderItem.unread_count }})
      </span>
      <Poptip v-model="folderInputVisible" :transfer="true" placement="right">
        <div slot="content">
          <input
            @keyup.enter="handleInputFolderName"
            v-model="folderInputName"
            class="folder-name-input"
            type="text"
            ref="folderInput"
            placeholder="New folder name"
          />
        </div>
      </Poptip>
    </div>
    <!------------Context menu-------------->
    <div
      v-if="contextmenuShow"
      class="rightMenu"
      ref="folderRightMenu"
      :style="{ top: rightMouseMenuY + 'px', left: rightMouseMenuX + 'px' }"
    >
      <ul>
        <li
          class="selectMenu"
          v-if="p_edit_share"
          @click.stop="addSubfolder(folderItem)"
          @mouseenter="onMouseEnterRightMenu"
        >
          <span class="checkType">New</span>
        </li>
        <li
          class="selectMenu"
          v-if="p_edit_share"
          @click.stop="editFolder(folderItem)"
          @mouseenter="onMouseEnterRightMenu"
        >
          <span class="checkType">Edit</span>
        </li>
        <li
          class="selectMenu"
          v-if="p_edit_share"
          @click.stop="showFolderInfo(folderItem)"
          @mouseenter="onMouseEnterRightMenu"
        >
          <span class="checkType">Details</span>
        </li>
        <li
          class="selectMenu"
          v-if="p_edit_share && folderItem.has_children"
          @click.stop="copyFolder(folderItem)"
          @mouseenter="onMouseEnterRightMenu"
        >
          <span class="checkType">Copy</span>
        </li>
        <li
          class="selectMenu"
          v-if="p_edit_share && folderToCopy && category !== folderCategory.STAR"
          @click.stop="pasteFolder(folderItem)"
          @mouseenter="onMouseEnterRightMenu"
        >
          <span class="checkType">Paste</span>
        </li>
        <li class="selectMenu" v-if="p_star" @click.stop="starFolder(folderItem)" @mouseenter="onMouseEnterRightMenu">
          <span class="checkType">{{ !folderItem.star ? "Star" : "Unstar" }}</span>
        </li>
        <li
          class="selectMenu"
          v-if="p_delete_share"
          @click.stop="deleteFolder(folderItem)"
          @mouseenter="onMouseEnterRightMenu"
        >
          <span class="checkType">Delete</span>
        </li>
        <section v-if="p_hide" v-show="showHideCondition">
          <li
            v-if="!folderItem.hidden"
            class="selectMenu"
            @click.stop="hideFolder(folderItem.id, folderItem.hidden)"
            @mouseenter="onMouseEnterRightMenu"
          >
            <span class="checkType">Hide</span>
          </li>
          <li
            v-else
            class="selectMenu"
            @click.stop="showFolder(folderItem.id, folderItem.hidden)"
            @mouseenter="onMouseEnterRightMenu"
          >
            <span class="checkType">Show</span>
          </li>
        </section>
        <li v-if="canShowMoveToMenu" class="selectMenu" @mouseenter="onMouseEnterRightMenu($event, 'moveTo')">
          <span class="checkType">Move to</span>
          <ul v-if="submenuMoveToShow" class="sub-menu">
            <li :class="{ disabled: atTop }" @click="moveTo('top')">Top</li>
            <li :class="{ disabled: atBottom }" @click="moveTo('bottom')">Bottom</li>
          </ul>
        </li>
        <li class="selectMenu" @click.stop="selectFolder(true)" @mouseenter="onMouseEnterRightMenu">
          <span class="checkType">Open in new tab</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';
  import api from 'api';
  import draggable from 'vuedraggable'
  import appConstant from "@/common/constants/app.constant";
  import { tabUtil, searchFolderMixin, undoActionMixin, openNewTabMixin } from '@/mixins';
  import util from "util";
  import { actionTypes } from '@/vuex/modules/undo'

  export default {
    name: 'item',
    props: {
      folderItem: {
        type: Object,
      },
      isStar: {
        type: Boolean,
      },
      isShare: {
        type: String,
      },
      isChild: Boolean,
      atTop: Boolean,
      atBottom: Boolean,
      category: {
        type: String,
      },
      onFolderClick: {
        type: Function
      },
      showContextMenu: {
        type: Boolean,
        required: false,
        default: true
      },
      active: {
        type: Boolean,
        default: undefined
      },
      showFolderIcon: {
        type: Boolean,
        default: true
      },
      collapsed: {
        type: Boolean
      }
    },
    data() {
      return {
        contextmenuShow: false,
        rightMouseMenuX: 0,
        rightMouseMenuY: 0,
        submenuMoveToShow: false,

        isDragging: true,
        originalOrder: [],
        currentParrentId: null,
        openTimeout: null,
        folderInputVisible: false,
        folderInputName: "",
        folderToPaste: null,

        folderCategory: appConstant.folderCategory,
        folderTypes: appConstant.folderType,

        folderPermission: {
          can_create: false,
          can_edit: false,
          can_view_detail: true,
          can_copy: true,
          can_star: true,
          can_delete: false,
          can_hide: true,
          can_move_to: true,
          can_open_in_new_tab: true
        }
      }
    },
    methods: {
      ...mapActions({
        setNestedFolder: 'setNestedFolder',
        FolderIdInit: 'FolderIdInit',
        handleRightClickFolder: 'handleRightClickFolder',
        hide_folder: 'hide_folder',
        star_folder: 'star_folder',
        addUndoAction: 'undo/addAction',
        undo: 'undo/undo'
      }),
      ...mapMutations([
        'SET_SELECTED_FOLDER',
        'ADD_OPEN_TAB',
        'ADD_OPEN_FOLDER',
        'REMOVE_OPEN_FOLDER',
        'SET_DRAGGING_FOLDER'
      ]),
      isActive(folder) {
        return this.$route.query.folder_id == folder.id;
      },
      selectFolder(openInTab) {
        const tabName = `${appConstant.tabPrefixes.FOLDER}${this.folderItem.id}`;
        const nameType = appConstant.tabTypes.FOLDER;
        const existingTab = this.openingTabs.find(t => t.name === tabName && t.name_type === nameType);
        if (existingTab) {
          this.setActiveAndGotoTab(existingTab);
          return;
        }
        this.closeContextmenu();
        const query = {
          folder_id: this.folderItem.id,
          isShare: this.isShare || '0',
          star: this.folderItem.star === true,
          type: this.folderItem.folder_type, //dynamic folder cannot remove emails from folder
          folder_name: this.folderItem.name,
        };
        if(openInTab && this.$route.query && this.$route.query.folder_id != this.folderItem.id) {
          const tab = {
            name: tabName,
            label: this.folderItem.name,
            icon: this.folderItem.folder_type == appConstant.folderType.NORMAL ? 'iconfont-folder'
              : this.folderItem.folder_type == appConstant.folderType.DYNAMIC ? 'iconfont-folder-search'
              : 'ic_view_folder',
            closable: true,
            name_type: nameType,
            data: JSON.stringify(this.folderItem),
            show: true,
            active: true
          };

          this.onOpenInTab(tab);
          return;
        }
        this.SET_SELECTED_FOLDER(this.folderItem);
        this.$router.replace({path: '/mails', query: query});
      },

      dragMailOver(e, folder_type){
        if (this.mailDragged.length === 0) {
          return
        }
        if (folder_type === appConstant.folderType.DYNAMIC || folder_type === appConstant.folderType.VIEW) {
          return
        }
        e.preventDefault();
      },
      dragMailEnter(e, folder_type){
        if (this.mailDragged.length === 0) {
          return
        }
        if (folder_type === appConstant.folderType.DYNAMIC || folder_type === appConstant.folderType.VIEW) {
          return
        }
        this.$refs.folder.classList.add('dragOver');
        this.openTimeout = setTimeout(() => {
          this.$refs.folder.classList.contains('dragOver')
            && this.folderItem.has_children
            && this.$emit('dragEmailEnter', this.folderItem, this.category)
        }, 1000)
      },
      dragMailOnDrop(e, folder_type){
        clearTimeout(this.openTimeout);
        if (this.mailDragged.length === 0) {
          return
        }
        if (folder_type === appConstant.folderType.DYNAMIC || folder_type === appConstant.folderType.VIEW) {
          return
        }
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        this.$refs.folder.classList.remove('dragOver');

        let mails = this.mailDragged;
        const emailCount = mails.length;

        const param = {
          folder_id: this.folderItem.id,
          email_ids: [...mails],
        };
        util.resetCachedEmails(`folder_id=${param.folder_id}`);

        this.$Message.destroy()
        const loadingRemoval = this.$Message.loading({
          content: 'Loading...',
          duration: 0
        });

        api.add_email_to_folder(param)
          .then(() => {
            loadingRemoval()
            this.addUndoAction({
              type: actionTypes.FILE_EMAIL,
              params: param
            })
            const undoMessageRemoval = this.showUndoMessage(
              (h) => [`${emailCount} email${emailCount > 1 ? 's' : ''} filed to `, h('b', this.folderItem.name)],
              () => {
                undoMessageRemoval()
                this.$Message.info('Action undone')
                this.undo(actionTypes.FILE_EMAIL)
              }
            )
            util.functionBus.clearCheck && util.functionBus.clearCheck();
          })
          .catch(() => {
            loadingRemoval()
          })
        this.$emit('addEmailDone', mails);
      },
      dragMailLeave(e){
        clearTimeout(this.openTimeout);
        if (this.$refs.folder && this.$refs.folder.contains(e.relatedTarget)) {
          return
        }
        this.$refs.folder.classList.remove('dragOver');
      },

      async showRightMenu(folder, e) {
        if (!this.folderItem.personal) {
          const permissionStr = await api.get_folder_permission(folder.id);
          if (!permissionStr || permissionStr.length === 0) return;

          this.mapPermission(permissionStr);
        }

        document.documentElement.click();
        this.contextmenuShow = true
        if(!this.contextmenuShow)
          return
        this.$nextTick(() => {
          const rightMenuHeight = this.$refs.folderRightMenu && this.$refs.folderRightMenu.clientHeight || 0;
          const documentHeight = document.documentElement.clientHeight || 0;
          this.rightMouseMenuX = (e.clientX + 5) <= 126 ? e.clientX + 5 : 126;
          this.rightMouseMenuY = (e.clientY + rightMenuHeight) > documentHeight ? e.clientY - rightMenuHeight : e.clientY + 5;
          document.addEventListener('click', this.closeContextmenu);
        });
      },
      mapPermission(permissionStr) {
        const permissions = permissionStr.split("");
        Object.keys(this.folderPermission).forEach((key, index) => {
          this.folderPermission[key] = (permissions[index] === "1");
        })
      },

      closeContextmenu(){
        this.contextmenuShow = false
        this.submenuMoveToShow = false
      },

      addSubfolder(folder) {
        this.closeContextmenu()
        this.handleRightClickFolder({ action: 'addSubfolder', folder})
      },

      editFolder(folder) {
        this.closeContextmenu()
        this.handleRightClickFolder({ action: 'editFolder', folder})
      },

      showFolderInfo(folder) {
        this.closeContextmenu()
        util.functionBus.showFolderDetail && util.functionBus.showFolderDetail(folder.id)
      },

      async copyFolder(folder) {
        this.closeContextmenu()
        const clonedFolder = {...folder}
        clonedFolder.copyiedId = clonedFolder.id;
        delete clonedFolder.id;
        delete clonedFolder.parent_id;
        clonedFolder.has_children = false;
        clonedFolder.children = [];
        this.handleRightClickFolder({ action: 'copyFolder', folder: clonedFolder})
      },

      async pasteFolder(folder) {
        this.closeContextmenu()
        if (this.category === this.folderCategory.STAR) {
          this.$Message.error("This action is not allowed.");
        }
        if (folder.folder_type !== this.folderToCopy.folder_type) {
          this.$Message.error("Two folders need to have the same type.");
          return;
        }
        this.folderInputVisible = true;
        this.folderInputName = this.folderToCopy.name + " Copy";
        this.folderToPaste = folder;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.folderInput.focus();
          }, 200);
        })
      },
      async handleInputFolderName() {
        const folder = {...this.folderToCopy};
        folder.name = this.folderInputName;
        this.folderInputVisible = false;
        try {
          await this.handleRightClickFolder({
            action: 'pasteFolder',
            folder: {...this.folderToPaste, newCoppiedName: this.folderInputName},
            target_folder_type: this.category
          });
          this.$store.dispatch('prepend_child_folders', {
            parentId: this.folderToPaste.id,
            folders: {[this.folderToPaste.id]: [folder]},
            category: this.category
          })
          this.$emit('copyFolderDone', this.folderToPaste, this.category)
        } catch (error) {
          console.log(error);
        }
      },

      starFolder(folder) {
        this.closeContextmenu()
        const params = {
          starable_id: parseInt(folder.id),
          starable_type: "Folder"
        };
        this.star_folder({ params, isStar: !folder.star, folder: folder });
      },

      deleteFolder(folder) {
        this.closeContextmenu()
        this.handleRightClickFolder({ action: 'deleteFolder', folder})
      },
//---------------------------------hide Floder
      async hideFolder(id, hidden) {
        this.closeContextmenu()
        if (hidden)
          return;
        const params = {
          'hiddenable_id': id,
          'hiddenable_type': 'Folder'
        };
        await this.hide_folder({folder: this.folderItem, isHide: true, params});
        this.folderItem.hidden = true;
      },
      async showFolder(id, hidden){
        this.closeContextmenu()
        if (!hidden)
          return

        const params = {
          'hiddenable_id': id,
          'hiddenable_type': 'Folder'
        };
        await this.hide_folder({folder: this.folderItem, isHide: false, params});
        this.folderItem.hidden = false;
      },
      moveTo(direction) {
        this.closeContextmenu()
        this.handleRightClickFolder({
          action: direction == 'top' ? 'moveTop' : 'moveBottom',
          folder: this.folderItem,
          category: this.category
        })
      },
      highlightFolder() {
        const nameEl = this.$refs[`folderNameText${this.folderItem.id}`];
        const iconEl = this.$refs[`folderIcon${this.folderItem.id}`];
        nameEl && nameEl.classList.add('highlight');
        iconEl && iconEl.classList.add('highlight');
      },
      onDrag(event, folder) {
        const data = {
          fromFolder: folder,
          fromCategory: this.category
        }
        this.SET_DRAGGING_FOLDER(data)

        let dragEl = document.createElement('div')
        dragEl.classList.add('ghost', 'text-ellipsis')
        dragEl.style.top = event.clientY + 4
        dragEl.style.left = event.clientX + 4
        dragEl.innerHTML = folder.name
        document.body.appendChild(dragEl)

        let fromEl = event.target.closest('.recursive-item')
        fromEl.classList.add('dragging-item')
        event.dataTransfer.setDragImage(dragEl, 10, 10);
        event.dataTransfer.effectAllowed = 'move'
      },
      onMouseEnterRightMenu(e, menu) {
        this.submenuMoveToShow = false
        if(menu == 'moveTo') {
          this.submenuMoveToShow = true
        }
      }
    },
    mixins: [ tabUtil, searchFolderMixin, undoActionMixin, openNewTabMixin ],
    computed: {
      ...mapState([
        'mailQuery',
        'mailDragged',
        'folder',
        'folderToCopy'
      ]),
      ...mapGetters([
        'rightMenu',
        'folders',
        'openingTabs'
      ]),

      nextPadding(){
        return 18;
      },

      showHideCondition(){
        return this.category !== 'personal'
      },

      dragOptions () {
        if (this.category === 'star') {
          this.isDragging = false
        }
        return {
          animation: 0,
          group: 'description',
          ghostClass: 'ghost',
          disabled: !this.isDragging,
        };
      },
      // permission for edit share group
      p_edit_share() {
        if(!this.folderItem.personal) return this.folderPermission.can_edit;
        return true;
      },
      // permission for delete share group
      p_delete_share() {
        if(!this.folderItem.personal) return this.folderPermission.can_delete;
        return true;
      },
      p_star() {
        if(!this.folderItem.personal) return this.folderPermission.can_star;
        return true;
      },
      p_hide() {
        if(!this.folderItem.personal) return this.folderPermission.can_hide;
        return true;
      },
      canShowMoveToMenu() {
        const subSharedInStars = this.category == this.folderCategory.STAR
                               && this.isChild
                               && !this.folderItem.personal
        return this.category != this.folderCategory.SHARE
            && !subSharedInStars
            && (!this.atTop || !this.atBottom)
      }
    },

    destroyed() {
      document.removeEventListener('click', this.closeContextmenu);
    },
    components: {
      draggable
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.folder-contain {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 13px;
  height: 30px;
  &.is-hidden {
    .folder-name {
      opacity: 0.6;
    }
  }
  .folder-name:not(.disabled) {
    &.active {
      color: var(--blue-main);
      font-weight: bold;
      svg {
        fill: var(--blue-main);
      }
    }
    &:hover {
      color: var(--blue-main);
      font-weight: 600;
      svg {
        fill: var(--blue-main);
      }
    }
    .highlight {
      color: green;
      font-weight: bold;
    }
    .unread_count {
      opacity: 0.6;
    }
  }
  .folder-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    min-width: 0;
    i,
    svg {
      font-size: 12px;
      margin-right: 8px;
      fill: var(--on-side-menu-color);
    }
    .folder-name-text {
      flex: 1;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .disabled {
    cursor: progress;
    opacity: 0.4;
  }
  .children {
    padding-left: 20px;
  }
}

.dragOver {
  color: #fff;
  width: 100%;
  background-color: rgba(0, 116, 175, 0.3);
  .arrowRight {
    opacity: 1;
  }
}

::selection {
  background-color: transparent;
  color: inherit;
}

.flip-list-move {
  transition: transform 0.5s;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
.ghost {
  position: absolute;
  font-size: 13px;
  width: 200px;
  padding: 8px 16px;
  border-radius: 3px;
  background-color: var(--component-color);
  border: solid 1px var(--border-color);
}
</style>
