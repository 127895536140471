<template>
  <div class="attachment-preview-tab" :class="{ 'full-width-preview-tab': $route.name === 'mailDetailWindow' }">
    <div v-show="loading" class="loading-attachment-preview-tab">Loading...</div>
    <iframe
      @load="handleOnloadIframe"
      :key="attachment.inapp_preview_file_url"
      frameborder="0"
      :style="{ 'clip-path': isEml ? 'inset(0px 2px 0px 0px)' : 'inset(2px 2px 2px 2px)' }"
      :src="attachment.inapp_preview_file_url"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      attachmentPreviewExpanded: false,
    };
  },
  props: {
    attachment: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleOnloadIframe() {
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["inappAttachmentPreview"]),
    isEml() {
      return ["eml", "p7m"].includes(this.attachment.file_type.toLowerCase());
    },
  },
  watch: {
    attachment: {
      deep: true,
      immediate: true,
      handler() {
        this.loading = true;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.attachment-preview-tab {
  height: 100%;
  flex: 1;
  min-width: 0;
  min-height: 0;
  position: relative;
  background-color: var(--surface-color);
  display: flex;
  flex-direction: column;

  .loading-attachment-preview-tab {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--surface-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    color: var(--text-color);
  }
  iframe {
    flex: 1;
    min-width: 0;
    min-height: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 100;
  }
}
.full-width-preview-tab {
  width: 100%;
}
</style>
