import {
  ADD_INAPP_COMPOSE_WINDOW,
  REMOVE_INAPP_WINDOW,
  REMOVE_ALL_INAPP_WINDOW,
  TOGGLE_MINIMIZE,
  INAPP_WINDOW_MINIMIZE_ALL,
  UPDATE_INAPP_COMPOSE_DATA,
} from "../../mutation-type";

const state = {
  inappMailState: [],
};

const getters = {
  inappMailState: (state) => state.inappMailState,
  isOpeningInappWindow: (state) => {
    return state.inappMailState.some((s) => s.visible);
  },
  openingInappWindow: (state) => {
    return state.inappMailState.find((s) => !!s.visible);
  },
};
const mutations = {
  [ADD_INAPP_COMPOSE_WINDOW]: (state, param) => {
    mutations[INAPP_WINDOW_MINIMIZE_ALL](state);
    state.inappMailState = [param, ...state.inappMailState];
  },
  [REMOVE_INAPP_WINDOW]: (state, key) => {
    state.inappMailState = state.inappMailState.filter((s) => s.key !== key);
  },
  [REMOVE_ALL_INAPP_WINDOW]: (state) => {
    state.inappMailState = [];
  },
  [TOGGLE_MINIMIZE]: (state, key) => {
    const index = state.inappMailState.findIndex((s) => s.key === key);
    if (index < 0) return;

    mutations[INAPP_WINDOW_MINIMIZE_ALL](state);

    const updated = { ...state.inappMailState[index], visible: true };
    state.inappMailState.splice(index, 1, updated);
  },
  [INAPP_WINDOW_MINIMIZE_ALL]: (state) => {
    state.inappMailState.forEach((s, index) => {
      const updated = { ...s, visible: false };
      state.inappMailState.splice(index, 1, updated);
    });
  },
  [UPDATE_INAPP_COMPOSE_DATA]: (state, { key, ...data }) => {
    const index = state.inappMailState.findIndex((s) => s.key === key);
    if (index < 0) return;

    const updated = { ...state.inappMailState[index], ...data };
    state.inappMailState.splice(index, 1, updated);
  },
};
const actions = {
  closeInappWindow({ state }, key) {
    const inapp = state.inappMailState.find((s) => s.key === key);
    if (!inapp || !inapp.close) return;

    inapp.close();
    mutations[REMOVE_INAPP_WINDOW](state, key);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
