<template>
  <div class="ship-detail-item">
    <Tabs :animated="false" v-model="activeTab" class="ship_detail_tabs">
      <TabPane label="Specifications" name="spec" class="tab-wrapper">
        <Specifications @shipDeleted="$emit('shipDeleted')" :specData="specData" />
      </TabPane>
      <TabPane label="AIS" name="ais">
        <AisInformation
          v-if="activeTab == 'ais' || aisLoaded"
          :shipId="+specData.id"
          :source="source"
          :loading="loading"
          @loaded="aisLoaded = true"
        />
      </TabPane>
      <TabPane label="Position History" v-if="!specData.overview.public" name="hist">
        <PositionHistory
          v-if="activeTab == 'hist' || positionLoaded"
          :shipId="+specData.id"
          @loaded="positionLoaded = true"
        />
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import util from "util";
import api from "@/fetch/api.js";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  props: {
    specData: Object,
    source: String,
  },
  data() {
    return {
      activeTab: "spec",
      loading: false,
      aisLoaded: false,
      positionLoaded: false,
      subscription: undefined,
    };
  },
  computed: {
    ...mapState(["shipBasic"]),
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.handleSocket();
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },
  methods: {
    updateTags(tags) {
      this.specData.tags = tags;
    },
    deleteTag(name, index) {
      const params = {
        tagable_type: "Ship",
        tagable_id: this.specData.id,
        names: [name],
      };
      api.delete_tag(params);
      this.specData.tags.splice(index, 1);
      this.$Message.success("Tag removed");
    },
    clickGroup(i) {
      util.openWindow(`ships/shipping?name=${encodeURIComponent(i.name)}&id=${i.id}`);
    },
    handleSocket() {
      this.subscription = this.$ActionCable.subscriptions.create(
        { channel: "NotificationsChannel" },
        {
          connected: () => {
            console.log("Ship detail WS connected");
          },
          received: (data) => {
            switch (data.type) {
              case "Comment":
                let number = 0;
                if (data.message.length > 0) {
                  data.message.forEach((item) => {
                    if (
                      item.commentable_id == this.specData.id &&
                      item.commentable_type == "Ship" &&
                      item.user_id != this.userInfo.user.id
                    ) {
                      number += 1;
                      this.specData.comments.push(item);
                    }
                  });
                }
                number > 0 &&
                  this.$nextTick((_) => {
                    this.$refs.chChat && this.$refs.chChat.scrollToBottom();
                  });
                break;
              case "Tag":
                if (
                  data.message[0].tagable_type == "Ship" &&
                  data.message[0].tagable_id == this.specData.id &&
                  !data.message[0].deleted_at
                ) {
                  this.specData.tags.push(data.message[0].name);
                }
                break;
            }
          },
        },
      );
    },
  },
  watch: {
    "specData.id"() {
      this.aisLoaded = this.positionLoaded = false;
    },
  },
  components: {
    Specifications: () => import("./tab/Specifications.vue"),
    PositionHistory: () => import("./tab/PositionHistory.vue"),
    AisInformation: () => import("./tab/AisInformation.vue"),
    EmailTabPanel: () => import("components/EmailTabPanel.vue"),
    Chat: () => import("pages/components/comments/Cchat.vue"),
  },
};
</script>

<style lang="scss" scoped>
.ship-detail-item {
  height: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--border-color);
  .ship_detail_tabs {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    .tab-wrapper {
      height: calc(100% - 31px);
    }
    .ivu-tabs-tabpane {
      height: 100%;
    }
  }
  .right-container {
    width: 300px;
    min-width: 300px;
    border: 1px solid var(--border-color);
    margin-left: -1px;
    .details-contain {
      height: 100%;
      overflow: auto;
      overflow: overlay;
      padding: 30px 16px 0 16px;
    }
  }
}
</style>
<style lang="scss">
.ship_detail_tabs {
  .ivu-tabs-tab {
    padding: 10px 16px !important;
  }
  .ivu-tabs-content {
    height: 100%;
    overflow: auto;
    overflow: overlay;
  }
}
</style>
