import {
  SET_TASK_LOADING,
  SET_TASKS,
  ADD_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  SET_CALENDAR_EVENTS,
  UPDATE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT,
  SET_TASKLIST_STATUS,
} from "@/vuex/mutation-type";
import api from "@/fetch/api";
import appConstant from "@/common/constants/app.constant";
import dayjs from "dayjs";

const REMIND_UNITS = { DAY: 1440, HOUR: 60, MINUTE: 1 }; // minute

const state = {
  status: appConstant.taskStatus.OPEN,
  tasks: [],
  openTaskCount: 0,
  assignedTaskCount: 0,
  closedTaskCount: 0,
  taskLoading: false,
};

const getters = {};

const actions = {
  getTasks({ commit, getters, rootState }, params) {
    return new Promise(async (resolve, reject) => {
      try {
        params.all && params.page == 1 && commit(SET_TASK_LOADING, true);
        let res = await api.get_tasks(params);
        if (!res) {
          resolve(res);
          return;
        }
        res = { ...res, tasks: res.tasks.map((task) => buildTask(task, getters)) };
        params.all && commit(SET_TASKS, res);
        !params.all && commit(SET_CALENDAR_EVENTS, [...rootState.calendar.calendarEvents, ...res.tasks]);
        params.all && params.page == 1 && commit(SET_TASK_LOADING, false);
        resolve(res);
      } catch (error) {
        commit(SET_TASK_LOADING, false);
        reject(error);
      }
    });
  },
  getTaskDetail({ commit, dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await api.get_task_detail(id);
        if (!res) {
          resolve(res);
          return;
        }
        const task = buildTask(res.task, getters);
        dispatch("setAttachments", { attachments: task.attachments, source: "task" });
        resolve(task);
      } catch (error) {
        reject(error);
      }
    });
  },
  async createTask({ commit, rootState, getters }, params) {
    const res = await api.create_task(params);
    const task = buildTask(res, getters);
    commit(ADD_TASK, task);
    commit(SET_CALENDAR_EVENTS, [...rootState.calendar.calendarEvents, task]);
  },
  updateTask({ state, commit, getters }, params) {
    return new Promise(async (resolve, reject) => {
      const res = await api.update_task(params);
      if (!res) {
        reject(res);
        return;
      }
      const task = buildTask(res.task, getters);
      commit(UPDATE_TASK, task);
      commit(UPDATE_CALENDAR_EVENT, task);
      resolve(res);
    });
  },
  async deleteTask({ state, commit }, id) {
    await api.delete_task(id);
    const task = state.tasks.find((t) => t.id == id);
    if (!task) return;
    commit(DELETE_TASK, task);
    commit(DELETE_CALENDAR_EVENT, task);
  },
  async deleteTasks({ state, commit }, ids) {
    await api.delete_tasks(ids);
    const deletedTasks = state.tasks.filter((t) => ids.findIndex((id) => id == t.id) > -1);
    if (!deletedTasks || deletedTasks.length == 0) return;
    deletedTasks.forEach((task) => {
      commit(DELETE_TASK, task);
      commit(DELETE_CALENDAR_EVENT, task);
    });
  },
  async markAsClosed({ state, commit }, ids) {
    ids.length == 1 ? await api.task_mark_as_closed(ids[0]) : await api.task_mark_as_closed_batch(ids);

    let tasks = state.tasks.filter((t) => ids.findIndex((id) => t.id == id) > -1);
    tasks &&
      tasks.forEach((task) => {
        task.closed = true;
        task.className = "fc-closed-event";
        commit(UPDATE_TASK, task);
        commit(UPDATE_CALENDAR_EVENT, task);
      });
  },
  async reopen({ state, commit }, ids) {
    ids.length == 1 ? await api.task_reopen(ids[0]) : await api.task_reopen_batch(ids);

    let tasks = state.tasks.filter((t) => ids.findIndex((id) => t.id == id) > -1);
    tasks &&
      tasks.forEach((task) => {
        task.closed = false;
        task.className = "";
        commit(UPDATE_TASK, task);
        commit(UPDATE_CALENDAR_EVENT, task);
      });
  },
};

const mutations = {
  [SET_TASKS]: (state, res) => {
    res.page == 1 && (state.tasks = res.tasks);
    res.page > 1 && state.tasks.push(...res.tasks);
    state.openTaskCount = res.open_count;
    state.assignedTaskCount = res.assigned_count;
    state.closedTaskCount = res.closed_count;
  },
  [ADD_TASK]: (state, task) => {
    isValidStatus(task, state.status) && state.tasks.push(task);
    !task.closed && state.openTaskCount++;
    !task.closed && task.assigned_to_me && state.assignedTaskCount++;
    task.closed && state.closedTaskCount++;
  },
  [UPDATE_TASK]: (state, task) => {
    if (!state.tasks || state.tasks.length == 0) return;
    let index = state.tasks.findIndex((t) => t.id == task.id);
    if (index > -1 && isValidStatus(task, state.status)) {
      state.tasks.splice(index, 1, task);
      return;
    }
    index == -1 && isValidStatus(task, state.status) && state.tasks.push(task);
    index > -1 && !isValidStatus(task, state.status) && state.tasks.splice(index, 1);
    if (task.closed) {
      state.closedTaskCount++;
      state.openTaskCount--;
      task.assigned_to_me && state.assignedTaskCount--;
    } else {
      state.openTaskCount++;
      task.assigned_to_me && state.assignedTaskCount++;
      state.closedTaskCount--;
    }
  },
  [DELETE_TASK]: (state, task) => {
    state.tasks = state.tasks.filter((t) => t.id != task.id);
    !task.closed && state.openTaskCount--;
    !task.closed && task.assigned_to_me && state.assignedTaskCount--;
    task.closed && state.closedTaskCount--;
  },
  [SET_TASKLIST_STATUS]: (state, status) => {
    state.status = status;
  },
  [SET_TASK_LOADING]: (state, status) => {
    state.taskLoading = status;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

/**
 * Helper functions
 */
function buildTask(task, getters) {
  const endDate = new Date(task.due_date);
  let duration = 30;
  if (endDate.getHours() == 23 && 60 - endDate.getMinutes() < 30)
    // larger than 23:30
    duration = 60 - endDate.getMinutes();
  endDate.setMinutes(endDate.getMinutes() + duration);
  const overDue = new Date(task.due_date) - new Date() < 0;
  return {
    ...task,
    status: task.closed ? appConstant.taskStatus.CLOSED : appConstant.taskStatus.OPEN,
    dueTime: dayjs(task.due_date).format("HH:mm"),
    dueDate: dayjs(task.due_date).format("YYYY-MM-DD"),
    reminderEnable: !!task.remind,
    reminderUnit:
      task.remind / 1440 >= 1 ? REMIND_UNITS.DAY : task.remind / 60 >= 1 ? REMIND_UNITS.HOUR : REMIND_UNITS.MINUTE,
    reminderTime: task.remind >= 1440 ? task.remind / 1440 : task.remind >= 60 ? task.remind / 60 : task.remind,
    groupId: appConstant.calendarEventTypes.TASK,
    type: appConstant.calendarEventTypes.TASK,
    start: task.due_date,
    end: endDate.toISOString(),
    editable: true,
    allDay: false,
    className: task.closed ? "fc-closed-event" : "",
    isReminderIconShown: !!task.remind && !task.closed && !overDue,
  };
}

function isValidStatus(task, currentStatus) {
  if (task.closed) return currentStatus == appConstant.taskStatus.CLOSED;

  return currentStatus == appConstant.taskStatus.OPEN || currentStatus == appConstant.taskStatus.ASSIGNED_TO_ME;
}
