<template>
  <div class="avatar-container">
    <div
      class="avatar"
      :class="{ disabled: disabled, 'no-background': !errorImageLoad }"
      :style="{
        width: `${width}px`,
        height: `${width}px`,
        lineHeight: `${width}px`,
      }"
    >
      <div
        v-show="errorImageLoad || !imageSrc"
        class="avatar-string"
        :title="showTooltip && fullname"
        :style="{ transform: `scale(${width / 40})` }"
      >
        {{ initalName }}
      </div>
      <img
        v-show="!errorImageLoad"
        :src="imageSrc"
        class="avatar-image"
        :class="{ fitWidth: imageFitWidth, disabled: disabled }"
        @load="errorImageLoad = false"
        @error="errorImageLoad = true"
      />
    </div>
  </div>
</template>

<script>
import utils from "../../utils";
export default {
  props: {
    fullname: {
      type: String,
      require: true,
    },
    avatarURL: {
      type: String,
      required: false,
    },
    width: {
      type: [String, Number],
      required: false,
      default: 40,
    },
    imageFitWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    characterLimit: {
      type: Number,
      required: false,
      default: 2,
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    email: {
      type: String,
    },
  },

  data() {
    return {
      initalName: "",
      errorImageLoad: true,
    };
  },

  created() {
    this.initalName = utils.getInitialName(this.fullname, this.characterLimit);
  },

  watch: {
    fullname(newVal, oldVal) {
      if (newVal == oldVal) return;

      this.initalName = utils.getInitialName(newVal, this.characterLimit);
    },
  },

  computed: {
    imageSrc() {
      if (!!this.avatarURL) return this.avatarURL;
      if (!this.email) return "";

      const [_, domain] = this.email.split("@");
      return `https://logo.clearbit.com/${domain}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: var(--text-color);
    background-color: var(--background-color);
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;

    &.disabled {
      .avatar-string {
        color: var(--text-secondary);
      }
      .avatar-image {
        opacity: 0.6;
        mix-blend-mode: luminosity;
      }
    }
    &.no-background {
      background-color: transparent;
    }
  }

  .avatar-string {
    color: var(--text-color);
  }

  .avatar-image {
    background-color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.fitWidth {
      width: 100%;
      height: auto;
    }
  }
}
</style>
