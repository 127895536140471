import {
  SET_USER_DETAIL,
  SET_PENDING_INVITES,
  SET_INVITE_SEARCH_TEXT,
  SET_MAXIMUM_LINCENSES,
  SET_ACTIVE_USERS_COUNT,
} from "@/vuex/mutation-type";
const state = {
  userDetail: {},
  pendingInvites: [],
  searchText: "",
  maximumLicenses: 0,
  activatedUserCount: 0,
};

const getters = {
  userDetail: (state) => state.userDetail,
  maximumLicenses: (state) => state.maximumLicenses,
  pendingInvites: (state) => state.pendingInvites,
  filteredInvites: (state) => state.pendingInvites.filter((i) => i.email.includes(state.searchText)),
  currentLicenses: (state) => state.activatedUserCount + state.pendingInvites.length,
  pendingInvitePagingText: (state, getters) => `${getters.filteredInvites.length} of ${state.pendingInvites.length}`,
};
const mutations = {
  [SET_USER_DETAIL]: (state, data) => {
    state.userDetail = Object.assign({}, data);
  },
  [SET_PENDING_INVITES]: (state, data) => {
    state.pendingInvites = data;
  },
  [SET_INVITE_SEARCH_TEXT]: (state, data) => {
    state.searchText = data;
  },
  [SET_MAXIMUM_LINCENSES]: (state, data) => {
    state.maximumLicenses = data;
  },
  [SET_ACTIVE_USERS_COUNT]: (state, data) => {
    state.activatedUserCount = data;
  },
};
const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
