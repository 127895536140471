<template>
  <div v-if="showMenu" class="right-menu__table" :style="{ left: leftPos, top: topPos }">
    <div v-for="(item, index) in columns" @click.prevent.stop="handleClick(item)" :key="index" class="item">
      <span class="check-icon">
        <Icon type="md-checkmark" v-if="columnObj[item]" />
      </span>
      {{ item }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showMenu: false,
      columns: [],
    };
  },
  props: {
    leftPos: {
      type: String,
      default: "0px",
    },
    topPos: {
      type: String,
      default: "0px",
    },
    columnObj: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["setting_company"]),
    p_enabled_assignment() {
      return this.setting_company.assignment_module == "1";
    },
  },
  mounted() {
    document.body.addEventListener("click", () => {
      this.showMenu = false;
    });
  },
  watch: {
    p_enabled_assignment: {
      immediate: true,
      handler() {
        this.addAssignmentColumn();
      },
    },
    columnObj: {
      immediate: true,
      handler() {
        this.columns = Object.keys(this.columnObj);
      },
    },
  },
  methods: {
    handleClick(item) {
      this.$emit("changeColumn", item);
    },
    addAssignmentColumn() {
      const columnName = "Assignment";
      const index = this.columns.findIndex((c) => c == columnName);
      this.p_enabled_assignment && index == -1 && this.columns.splice(6, 0, columnName);
      !this.p_enabled_assignment && index > -1 && this.columns.splice(index, 1);
    },
  },
  beforeDestroy() {
    document.body.removeEventListener("click", () => {
      this.showMenu = false;
    });
  },
};
</script>
<style lang="scss" scoped>
.right-menu__table {
  position: fixed;
  top: 100px;
  left: 500px;
  display: block;
  background-color: var(--component-color);
  border-radius: 5px;
  padding: 10px 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}
.item {
  font-size: 13px;
  font-weight: 700;
  padding: 5px 15px;
  cursor: pointer;
  .check-icon {
    display: inline-block;
    width: 20px;
  }
  i {
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
