<template>
  <div class="vue-input-tag-wrapper">
    <div ref="textboxWrapper" class="content" v-bind:class="{ 'read-only': readOnly }" @click="focusNewTag()">
      <span v-for="(tag, index) in tags" :key="index" class="input-tag">
        <span class="tag">{{ tag }}</span>
        <a v-if="!readOnly" @click.prevent.stop="remove(index)" class="remove"></a>
      </span>
      <input
        v-if="!readOnly"
        ref="textbox"
        :placeholder="getPlaceholder()"
        type="text"
        v-model="newTag"
        @keydown.delete.stop="removeLastTag()"
        @keydown.enter.prevent.stop="canAddNew && currentIndex == -1 ? addNew(newTag) : null"
        @keydown.space.prevent.stop="canAddNew ? addNew(newTag) : null"
        @blur.prevent.stop="canAddNew && !dropdownShown ? addNew(newTag) : null"
        @keydown.self.down.prevent="onKeyDown"
        @keydown.self.up.prevent="onKeyUp"
        @keydown.self.enter.prevent="onSelect"
        @keydown.27.self.prevent="dropdownShown = false"
        class="new-tag"
      />
    </div>
    <div
      class="suggestion"
      v-if="dropdownShown && !loading"
      ref="suggestion"
      :style="{
        top: dropdownPosition.top + 'px',
        left: dropdownPosition.left + 'px',
        right: dropdownPosition.right + 'px',
      }"
      tabindex="0"
      @keydown.self.down.prevent="onKeyDown"
      @keydown.self.up.prevent="onKeyUp"
      @keydown.self.enter.prevent="onSelect"
      @keydown.27.self.prevent="dropdownShown = false"
    >
      <span class="desc">
        <Icon type="md-close" @click="dropdownShown = false" />
        <div class="right">
          <i class="iconfont ch-icon-updown"></i> to navigate <i class="iconfont ch-icon-enter_sign"></i> to select
        </div>
      </span>
      <ul class="list">
        <li
          class="item"
          v-for="(item, index) in suggestList"
          :key="index"
          :class="{ active: currentIndex == index }"
          :ref="`suggestItem${index}`"
          @click="onSelect($event, item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { fromEvent, throwError, of, merge } from "rxjs";
import { map, tap, debounceTime, distinctUntilChanged, switchMap, share, filter, catchError } from "rxjs/operators";
import { popupPosition } from "@/mixins/index";

const validators = {
  email: new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ),
  url: new RegExp(/^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i),
  text: new RegExp(/^[a-zA-Z]+$/),
  digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
  isodate: new RegExp(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/),
  tag: new RegExp(/\w+/),
};

export default {
  name: "InputTag",
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    onChange: {
      type: Function,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: String,
      default: "",
    },
    canAddNew: {
      type: Boolean,
      default: true,
    },
    getSuggestList: {
      type: Function,
    },
  },
  data() {
    return {
      newTag: "",
      searchObservable: undefined,
      searchSubscription: undefined,
      dropdownShown: false,
      dropdownPosition: {},
      suggestList: [],
      currentIndex: -1,
      loading: false,
    };
  },
  mixins: [popupPosition],
  mounted() {
    if (this.getSuggestList) this.setupSearch();
  },
  destroyed() {
    this.searchSubscription && this.searchSubscription.unsubscribe();
  },
  methods: {
    focusNewTag() {
      if (this.readOnly) {
        return;
      }
      this.$el.querySelector(".new-tag").focus();
    },
    addNew(tag) {
      if (!tag) return;
      if (tag.startsWith("#")) tag = tag.substring(1);

      const index = this.tags.findIndex((s) => s == tag);
      if (index == -1 && this.validateIfNeeded(tag)) {
        this.tags.push(tag);
        this.tagChange();
      }
      index > -1 && this.$Message.info("This already existed");
      this.newTag = "";
      this.resetSearch();
      this.focusNewTag();
    },
    validateIfNeeded(tagValue) {
      if (this.validate === "" || this.validate === undefined) {
        return true;
      } else if (Object.keys(validators).indexOf(this.validate) > -1) {
        return validators[this.validate].test(tagValue);
      }
      return true;
    },
    remove(index) {
      this.tags.splice(index, 1);
      this.tagChange();
    },
    removeLastTag() {
      if (this.newTag) {
        return;
      }
      this.tags.pop();
      this.tagChange();
    },
    getPlaceholder() {
      if (!this.tags.length) {
        return this.placeholder;
      }
      return "";
    },
    tagChange() {
      if (this.onChange) {
        // avoid passing the observer
        this.onChange(JSON.parse(JSON.stringify(this.tags)));
      }
    },
    setupSearch() {
      const searchboxEl = this.$refs.textbox;
      if (!searchboxEl) return;
      this.searchObservable = fromEvent(searchboxEl, "input").pipe(
        debounceTime(400),
        map((event) => event.target.value),
        tap((_) => this.resetSearch()),
        filter((searchText) => searchText && searchText.trim() != ""),
        map((textSearch) => {
          if (textSearch.startsWith("#")) textSearch = textSearch.substring(1);
          return textSearch;
        }),
        tap((_) => (this.loading = this.dropdownShown = true)),
        switchMap((searchText) => this.getSuggestList(searchText)),
        filter((res) => !!res),
        tap((_) => (this.loading = false)),
        catchError((error) => {
          this.resetSearch();
          this.searchObservable && this.subscribeSearch();
          return of(null);
        }),
      );
      this.subscribeSearch();
    },
    subscribeSearch() {
      this.searchSubscription = this.searchObservable.subscribe((res) => {
        if (!res) return;
        this.suggestList = res.tags ? res.tags : res;
        this.dropdownShown = this.suggestList && this.suggestList.length > 0;
        this.$nextTick((_) => {
          this.dropdownPosition =
            this.dropdownShown && this.computePosition(this.$refs.textboxWrapper, this.$refs.suggestion, "bottom");
        });
      });
    },
    resetSearch() {
      this.currentIndex = -1;
      this.loading = false;
      this.dropdownShown = false;
      this.suggestList = [];
    },
    onKeyDown() {
      this.currentIndex < this.suggestList.length - 1 && this.currentIndex++;
      this.scrollIntoItem(this.currentIndex);
    },
    onKeyUp() {
      this.currentIndex > -1 && this.currentIndex--;
      this.currentIndex > -1 && this.scrollIntoItem(this.currentIndex);
    },
    onSelect(e, val) {
      const item = val || this.suggestList[this.currentIndex];
      if (!item) return;
      this.addNew(item);
    },
    clearText() {
      this.newTag = "";
    },
    scrollIntoItem(index) {
      const el = this.$refs[`suggestItem${index}`];
      const listEl = this.$refs.suggestion;
      if (!el || el.length == 0 || !listEl) return;
      if (
        listEl.getBoundingClientRect().bottom - el[0].getBoundingClientRect().bottom <= 40 ||
        el[0].getBoundingClientRect().top - listEl.getBoundingClientRect().top <= 40
      )
        el[0].scrollIntoView({ behavior: "smooth", block: "center" });
    },
  },
};
</script>
<style scoped lang="scss">
.vue-input-tag-wrapper {
  display: flex;
  overflow: hidden;
  cursor: text;
  text-align: left;
  .content {
    width: 100%;
    color: var(--on-component-color);
    background-color: var(--component-color);
    border: solid 1px var(--border-color);
  }
  .suggestion {
    position: fixed;
    z-index: 1;
    background: var(--component-color);
    font-size: 13px;
    border: 1px solid var(--border-color);
    line-height: normal;
    .desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      background: var(--primary-color);
      color: var(--on-primary-color);
      .ivu-icon-md-close {
        font-size: 18px;
        cursor: pointer;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .ch-icon-enter_sign {
          font-size: 20px;
        }
        .iconfont {
          font-weight: bold;
          margin-right: 4px;
        }
      }
    }
    .list {
      max-height: 300px;
      overflow: auto;
      overflow: overlay;
      .item {
        list-style: none;
        line-height: normal;
        padding: 10px 16px;
        &:hover,
        &.active {
          background: var(--highlight-color);
          cursor: pointer;
        }
      }
    }
  }
}

.vue-input-tag-wrapper .input-tag {
  display: inline-flex;
  height: 30px;
  line-height: 30px;
  margin: 4px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid var(--border-color);
  text-align: center;
  .tag {
    display: block;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.vue-input-tag-wrapper .input-tag .remove {
  cursor: pointer;
  font-weight: bold;
  color: var(--primary-color);
  margin-left: 3px;
}

.vue-input-tag-wrapper .input-tag .remove:hover {
  text-decoration: none;
  color: var(--primary-color-hover);
}

.vue-input-tag-wrapper .input-tag .remove::before {
  content: " x";
}

.vue-input-tag-wrapper .new-tag {
  border: 0;
  font-size: 14px;
  line-height: 1.36;
  font-weight: 400;
  outline: none;
  padding: 4px;
  width: 150px;
}
.vue-input-tag-wrapper .new-tag::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: lighter;
  color: #c4c4c4;
}

.vue-input-tag-wrapper.read-only {
  cursor: default;
}
</style>
–
