<template>
  <div class="dropdown-button highlight-btn" :class="{ disabled: disabledButton }">
    <div class="button" @click="!disabledButton ? onButtonClick() : null">
      {{ loading ? "Loading..." : selectedItem?.label || "Select" }}
    </div>
    <Dropdown trigger="click" class="dropdown" placement="bottom-end">
      <Icon type="ios-arrow-down"></Icon>
      <DropdownMenu slot="list">
        <DropdownItem
          v-for="(item, index) in items"
          :key="index"
          :disabled="disabledButton"
          @click.native="!disabledButton ? onDropdownClick(item) : null"
        >
          {{ item.label }}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import LocalStorageConstant from "@/common/constants/local-storage.constant";

export default {
  props: {
    items: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedItem: undefined,
    };
  },
  methods: {
    onButtonClick() {
      this.$emit("buttonClick", this.selectedItem);
    },

    onDropdownClick(item) {
      this.selectedItem = item;
      this.$emit("dropdownClick", this.selectedItem);
      localStorage.setItem(LocalStorageConstant.TRIGONAL_SELECTED_ACTION, JSON.stringify(this.selectedItem));
    },

    initSelectedItem() {
      if (!this.items || this.items.length == 0) return;

      const itemJson = localStorage.getItem(LocalStorageConstant.TRIGONAL_SELECTED_ACTION);
      const item = itemJson && JSON.parse(itemJson);

      this.selectedItem = item && this.items.findIndex((i) => i.value === item.value) > -1 ? item : this.items[0];
    },
  },
  computed: {
    disabledButton() {
      return this.disabled || this.loading;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.initSelectedItem();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  min-width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background: var(--component-color);
  border-radius: 4px;
  cursor: pointer;
  font-weight: normal;

  &.disabled {
    background-color: var(--component-color-disable);
    color: var(--text-color-disable);
    user-select: none;
    cursor: not-allowed;
  }

  .button {
    padding: 4px 8px;
    flex: 1;
    &:hover {
      background-color: var(--hover-on-background-color);
    }
  }
  .dropdown {
    padding: 2px;
    &:hover {
      background-color: var(--hover-on-background-color);
    }
    .ivu-dropdown-rel {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss">
.dropdown-button {
  .ivu-dropdown-rel {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
