<template>
  <div class="dynamic_wrapper auto-select-wrapper" v-clickoutside="handleClose">
    <Dropdown trigger="custom" :visible="visible" placement="bottom-start" :transfer="transfer" style="width: 100%">
      <Input
        v-model.trim="searchKey"
        @on-focus="searchByKey"
        @on-blur="handleBlur"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="254"
        @on-keydown="pointer($event)"
        icon="arrow-down-b"
        @on-enter="addPointerElement()"
        style="width: 100%"
      >
      </Input>
      <DropdownMenu slot="list">
        <ul class="down_wrapper" v-show="searchList.length > 0" :style="speStyle">
          <!--class="row_item"ivu-select-item-->
          <div class="search_list">
            <li
              v-for="(item, index) in searchList"
              :class="{ 'row-highlight': listSelect === index } && liStyle"
              @click.prevent.stop="selectCompany(index, item, $event)"
              :key="item[pass]"
            >
              {{ item[label] }}
            </li>
          </div>
        </ul>
        <!--No matching data-->
        <ul class="down_wrapper" v-show="searchList.length == 0" :style="speStyle">
          <li class="no_data" style="text-align: center; color: #bbbec4">No matching data</li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script type="text/ecmascript-6">
  import {mapGetters} from 'vuex'
  import clickoutside from '../../utils/clickoutside';

  export default{
    directives: {clickoutside},
    props: {
      // 用于显示的字段
      label: {
        type: String
      },
      // 用于提交的字段
      pass: {
        type: String
      },
      value: {
        default: '',
      },
      dataList: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
      },
//      modal(不受父级样式影响)
      transfer: {
        type: Boolean,
        default: false,
      },
      liStyle: {
        type: String,
        default: 'row_item',
      },
      speStyle: {
        type: String,
        default: 'width:100%;max-height: 250px',
      },
      disabled: {
        type: Boolean,
        default: false,
      }
//      width: {
//        type: String,
//        default: 'width:100%',
//      },
//      maxHeight: {
//        type: String,
//        default: 'max-height: 250px',
//      }
    },
    data() {
      return {
        visible: false,
        searchKey: '',
        selectId: '',
        listSelect: -1,
      }
    },
    created () {
      this.selectId = this.value
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
      //-------------------------------------动态过滤
      searchList: function () {
        if (this.searchKey !== '') {
          if (this.dataList.length !== 0) {
            var tableData = this.dataList;
            var searchQuery = this.searchKey && this.searchKey.toLowerCase();
            var dataArr = tableData.filter(item => {
              return (item[this.label].toLowerCase().indexOf(searchQuery.toLowerCase()) != -1);
            });
            return dataArr
          } else {
            return [];
          }
        } else {
          return this.dataList;
        }
      },
      //    选中框位置
      pointerPosition(){
        let pointer = this.listSelect + 1;
        return pointer * 44;
      },
    },
    methods: {
//------------------------focus
      searchByKey(){
        this.visible = true;
      },
      handleBlur(){
//        转到v-clickoutside="handleClose"处理
//        this.visible = false;//清空输入框
//        if (this.searchList.length === 0) {//直接清空
//          this.searchKey = '';
//          this.selectId = '';
//        }
      },
//----------------------------点击选中
      selectCompany(index, item, e){
        console.log(index, item, e)
        this.visible = false;
        this.listSelect = index;
        this.searchKey = item[this.label];
        this.selectId = item[this.pass];
      },
//----------------------------键盘事件 up/down
      pointer(e){
        if (e.keyCode === 40) {//down
          this.pointerForward()
        }
        if (e.keyCode === 38) {//up
          this.pointerBackward()
        }
      },
//-----------------------向下 down
      pointerForward(){
        let s = this.searchList.length;
        let obj = this.$el.querySelector('.down_wrapper');
        let visibleElements = 250 / 44;
        if (s > 0) {
          if (this.listSelect < s - 1) {
            this.listSelect++;
          } else {
            this.listSelect = s - 1;
          }
        }
        if (obj.scrollTop <= this.pointerPosition - visibleElements * 44) {
          obj.scrollTop = this.pointerPosition - 44;
        }
      },
//-----------------------向上 UP
      pointerBackward() {
        let s = this.searchList.length;
        let obj = this.$el.querySelector('.down_wrapper');
        if (s > 0) {
          if (this.listSelect > 0) {
            this.listSelect--;
          } else {
            this.listSelect = 0;
          }
        }
        if (obj.scrollTop >= (this.pointerPosition - 44)) {
          obj.scrollTop = this.pointerPosition - 250;
        }
      },
//----------------------------up/down 回车选中
      addPointerElement(){
        const s = this.searchList.length;
        if (s === 0) {
          return
        }
        if (this.listSelect < s && this.listSelect > -1) {
          let id = this.searchList[this.listSelect][this.pass];
          let name = this.searchList[this.listSelect][this.label];
          this.searchKey = name;
          this.selectId = id;
          this.$el.querySelector('.ivu-input').blur();
          this.visible = false
        }
      },
//--------------------------点击其余地方 隐藏dropDown
      handleClose(){
        this.visible = false;
        //直接清空
        if (this.searchList.length === 0) {
          this.searchKey = '';
          this.selectId = '';
        }
        //比对(searchKey和数据的label) 相同则继续对比id 不符则清空
        if (this.searchList.length > 0) {
          let index = this.dataList.findIndex((item) => {
            return item[this.label] == this.searchKey;
          });
          if (index !== -1) {
            if (this.dataList[index][this.pass] != this.selectId) {
//              console.log('searchKey的id和已选中的id不符')
              this.searchKey = '';
              this.selectId = '';
            }
          } else {//label不一致直接清空
            this.searchKey = '';
            this.selectId = '';
          }
        }
      },
    },
    filters: {},
    watch: {
//    检测输入框变化
      searchKey: function () {
//          参数初始化
        this.listSelect = 0;
        this.$el.querySelector('.down_wrapper').scrollTop = 0;
      },
      'selectId': function () {
        // if (this.searchKey == '') {
          let index = this.dataList.findIndex((item) => {
            return item[this.pass] == this.selectId;
          });
          if (index !== -1) {
            this.searchKey = this.dataList[index][this.label]
          }
        // }
        this.$emit('input', this.selectId)
      },
      value () {
        this.selectId = this.value
      }
    },
    components: {}
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.dynamic_wrapper {
  width: 100%;
  .down_wrapper {
    /*width: 100%;*/
    /*max-height: 250px;*/
    overflow: auto;
    overflow: overlay;
    text-align: left;
    .search_list {
      .row_item {
        cursor: pointer;
        font-size: 14px;
        line-height: 1.26;
        padding: 13px 16px 14px;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: var(--hover-color);
          color: var(--text-color);
        }
        &.row-highlight {
          background-color: var(--highlight-color);
          color: var(--text-color);
        }
      }
    }
    .no_data {
      width: 100%;
      font-size: 14px;
      line-height: 45px;
      color: #bbbec4;
      text-align: center;
    }
  }
}
</style>
