<template>
  <div class="tabs">
    <div class="tabs-header">
      <div
        class="header-item"
        :class="{ active: activeTab === item.id }"
        v-for="item in tabs"
        :key="item.id"
        @click="handleClickTab(item)"
      >
        <Tooltip :content="item.name" placement="bottom">
          <div class="header-item__icon">
            <svg-sprite :width="18" :height="18" :name="item.icon" />
          </div>
        </Tooltip>
      </div>
    </div>
    <div class="tabs-body">
      <slot :name="activeTab" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: "comments",
          icon: "comment",
          name: "Comments",
        },
        {
          id: "details",
          icon: "detail",
          name: "Details",
        },
        {
          id: "positions",
          icon: "position",
          name: "Positions",
        },
        {
          id: "threads",
          icon: "thread",
          name: /contact/.test(location.href) ? "Recent Mails" : "Threads",
        },
      ],
      activeTab: "comments",
    };
  },
  created() {
    if (/contact/.test(location.href)) {
      this.tabs = this.tabs.filter((t) => t.id !== "positions");
    }
    if (/ship/.test(location.href)) {
      this.tabs = this.tabs.filter((t) => t.id !== "positions" && t.id !== "threads");
    }
    if (this.$store.getters.setting_company.shipping_module_turn_off == "1") {
      this.tabs = this.tabs.filter((t) => t.id !== "positions");
    }
  },
  methods: {
    handleClickTab(item) {
      this.activeTab = item.id;
      this.$emit("tab-selected", item.id);
    },
  },
};
</script>
<style scoped lang="scss">
.tabs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--surface-color);
  &-header {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
  }
  &-body {
    flex: 1;
    min-height: 0;
    overflow: hidden;
  }
}
.header-item {
  flex: 1;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid var(--border-color);
  }
  &__icon {
    padding: 5px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    fill: var(--text-color);
  }
}
.active {
  background-color: var(--blue-main);
  svg {
    fill: #fff;
  }
}
</style>
